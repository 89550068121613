import React, { useEffect, useState } from 'react'
import NavBar from '../../components/Nav/NavBar'
import LogInModal from '../../components/LogIn/LogInModal'
import SignUpModal from '../../components/SignUp/SignUpModal'
import { useSelector } from 'react-redux'
import heroImage from './assets/countries.png'
import goodifly from './assets/goodifly.png'
import phone_1 from './assets/phone_1.png'
import phone_2 from './assets/phone_2.png'
import wall_goodifly from './assets/wall_goodifly.png'
import wall from './assets/wall.png'
import Footer from '../../components/Footer/Footer'
import CustomerNav from '../../components/Nav/CustomerNav'
import JoinCommunityModal from './JoinCommunityModal'

const About = () => {
  const [validUser, setValidUser] = useState(false)
  const [userID, setUserID] = useState()
  const [username, setUsername] = useState("")
  const [access_token, setAccessToken] = useState()
  const [refresh_token, setRefreshToken] = useState()
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [swithStatus, setSwitchStatus] = useState(false)

  const [communityModal, setCommunityModal] = useState(false)
  const logInModal = useSelector((state) => state.logInModal.value)
  const signUpModal = useSelector((state) => state.signUpModal.value)


   // Decode the data after retrieving it
   function decodeData(encodedData) {
    return atob(encodedData); // Base64 decoding
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Smooth scrolling animation
    });
  };

  useEffect(()=>{
    scrollToTop()
    const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
    if (some_data) {
      setValidUser(true)
      const current_Data = decodeData(some_data)
      const parsedData = JSON.parse(current_Data)
      setUsername(parsedData?.first_name)
      setAccessToken(parsedData?.access_token)
      setRefreshToken(parsedData?.refresh_token)
      setUserID(parsedData?.id)
    }
  }, [])
  return (
    <div>
      {validUser?<CustomerNav component='about' userID={userID} refresh_token={refresh_token} access_token={access_token} swithStatus={swithStatus} setSwitchStatus={setSwitchStatus} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} />:<NavBar />}

      {logInModal ?
        <LogInModal /> : null}
      {signUpModal ? <SignUpModal /> : null}
      {communityModal?<JoinCommunityModal setCommunityModal={setCommunityModal}/>:null}
      <div className=' pt-[147px]'>
        {/**Hero Section */}
        <div className=' px-[2rem] sm:px-[4.375rem] md:px-[5rem]  pb-[100px] flex flex-col md:flex-row items-center justify-center lg:gap-6'>
          <div className=' items-center text-center sm:text-start sm:items-start sm:w-[521px] flex flex-col'>
            <h1 className=' font-bold text-[25px] lg:text-[35px] xl:text-[50px] lg:leading-[60.65px]'>Turn flights into global deliveries</h1>
            <h1 className=' text-[16px] lg:text-[18px] mt-[14px]'>Send and receive items across borders with ease. No more shipping headaches. We’re a community dedicated to making shipping personal, affordable, and safe.</h1>
            <button onClick={()=>setCommunityModal(true)} className=' mt-8 text-[16px] sm:text-[18px] leading-[21.83px] flex justify-center items-center text-center w-[180px] sm:w-[210px] h-[45px] bg-[#EB0728] text-white rounded-[40px]'>Join our community</button>
          </div>
          {/**Image */}
          <img src={heroImage} alt="" className=' mt-[70px] md:mt-0 w-full md:w-[400px] md:h-[400px] lg:w-[450px] lg:h-[450px] xl:w-[500px] xl:h-[500px]' />
        </div>

        {/**Change the way you do international deliveries */}
        <div className=' bg-[#FFDFE4]  flex flex-col w-full items-center justify-center text-center gap-[19px] py-[83px] sm:py-[99px] px-[2rem] sm:px-[4.375rem] md:px-[5rem] '>
          <img src={goodifly} alt="" className='  w-[98px] h-[30px] xs:w-[132.31px] xs:h-[40px]' />
          <h1 className=' lg:w-[837px]  text-[25px] lg:text-[35px] xl:text-[50px] font-bold lg:leading-[60.65px]'>Change the way you do international deliveries</h1>
          <h1 className=' lg:w-[779px] text-[16px] sm:text-[18px] font-normal leading-[21.83px]'>We’re reimagining international shipping at Goodifly with our peer-to-peer freight delivery platform. Connect with trusted travellers, share extra carriage space, and enjoy swift, simplified, and affordable deliveries.</h1>
        </div>

        {/**How it works */}
        <div className=' pb-[160px] md:pb-[215px] pt-[75px] sm:pt-[142px] px-[2rem] sm:px-[4.375rem] md:px-[5rem] '>
          {/**Part 1 */}
          <div className='w-full justify-center text-center md:text-left items-center md:items-end flex flex-col md:flex-row gap-[50px] md:gap-[84px]'>
            <div className=' md:w-[665px] flex flex-col'>
              <h1 className='text-[25px] lg:text-[35px] xl:text-[50px] font-bold lg:leading-[60.65px]'>How it works</h1>
              <h1 className=' mt-[23px] text-[#EB0728] text-[16px] sm:text-[18px] font-bold leading-[21.83px]'>Discover Flight, Secure Space</h1>
              <h1 className=' mt-[12px] text-[16px] sm:text-[18px] leading-[21.83px]'>Find your ideal destination and book extra space on a traveler's flight effortlessly. Kickstart the process with Goodifly, where your international deliveries take flight.</h1>
              <h1 className=' mt-[50px] md:mt-[67px] text-[#EB0728] text-[16px] sm:text-[18px] font-bold leading-[21.83px]'>Precise Logistics</h1>
              <h1 className=' mt-[12px] text-[16px] sm:text-[18px] leading-[21.83px]'>Witness flawless logistics in action. Goodifly ensures a seamless journey for your item – from sender to traveler before takeoff and a secure handoff at the destination. Your package is guaranteed safe every step of the way.</h1>
            </div>
            <img src={phone_1} alt="" className=' w-[239px] h-[489px]' />
          </div>
          {/**Part 2 */}
          <div className=' mt-[54px] md:mt-[101px] w-full justify-center items-center md:items-start flex flex-col-reverse md:flex-row gap-[50px] md:gap-[84px]'>
            <img src={phone_2} alt="" className=' w-[239px] h-[489px]' />
            <div className=' mt-3 md:w-[607px] flex flex-col text-center md:text-right'>
              <h1 className=' text-[#EB0728] text-[16px] sm:text-[18px] font-bold leading-[21.83px]'>Swift, Secure Delivery to your doorstep</h1>
              <h1 className=' mt-[13px] text-[16px] sm:text-[18px] leading-[21.83px]'>Goodifly promises swift and secure delivery straight to your doorstep without delays or worries.</h1>
              <h1 className=' mt-[50px] md:mt-[66px] text-[#EB0728] text-[16px] sm:text-[18px] leading-[21.83px] font-bold'>Relax and track in real time</h1>
              <h1 className=' mt-3 leading-[21.83px] text-[16px] sm:text-[18px]'>Enjoy peace of mind with full transparency. Sit back, relax, and track your item at every stage of its journey. Goodifly keeps you in the loop, ensuring you're always in control.</h1>
            </div>
          </div>
        </div>

        {/**Join our traveller’s community */}
        <div className=' relative w-full flex flex-col-reverse sm:flex-row'>
          <img src={wall_goodifly} alt="" className='w-full mt-16 sm:mt-0 hidden sm:flex' />
          <img src={wall} alt="" className=' sm:hidden' />
          <div className='  sm:absolute top-0 left-0 w-full h-full flex items-center justify-end'>
            <div className=' pl-[2rem] sm:pl-[0] pr-[2rem] sm:pr-[4.375rem] md:pr-[5rem] xl:pr-[6.375rem] sm:w-[640px] md:w-[798px] items-center sm:items-end text-center sm:text-right flex flex-col'>
              <img src={goodifly} alt="" className=' w-[98px] h-[30px]  xs:w-[190px] xs:h-[58.29px]' />
              <h1 className=' mt-[18.17px] font-bold text-[25px] lg:text-[35px] xl:text-[50px] md:leading-[60.65px]'>Join our traveller’s community</h1>
              <h1 className=' sm:w-[369px] md:w-[469px] lg:w-[569px] xl:w-[669px] mt-[26px] text-[16px] sm:text-[18px] leading-[21.83px]'>Earn while delivering with Goodifly. Join our community for exciting opportunities and rewarding experiences! Goodifly prioritizes your safety. Deliver with confidence, knowing you're protected from security risks</h1>
              <button  onClick={()=>setCommunityModal(true)} className=' cursor-pointer mt-[43px] text-[16px] sm:text-[18px] leading-[21.83px] flex justify-center items-center text-center w-[180px] sm:w-[210px] h-[45px] bg-[#EB0728] text-white rounded-[40px]'>Join our community</button>
            </div>
          </div>
        </div>

        {/**Benefits */}
        <div className=' pb-[118px] sm:pb-[150px] w-full flex flex-col pt-[80px] md:mt-[153px] items-center text-center justify-center px-[2rem] sm:px-[4.375rem] md:px-[5rem] '>
          <h1 className='  font-bold  text-[25px] lg:text-[35px] xl:text-[50px] leading-[60.65px]'>Benefits</h1>

          <div className=' gap-[48px] w-full flex flex-wrap md:flex-nowrap items-baseline justify-center text-center mt-14'>
            <div className=' w-[300px] items-center justify-center text-center flex flex-col'>
              <div className=' bg-[#EB0728] w-[50px] h-[50px] rounded-[50px] flex font-bold leading-[36.39px] text-white text-[30px] items-center justify-center text-center'>1</div>
              <h1 className=' font-bold text-[18px] leading-[21.83px] mt-[22px]'>Swift Delivery</h1>
              <h1 className=' mt-2 text-[16px] sm:text-[18px] leading-[21.83px]'>Cut your wait time in half with Goodifly, slashing industry-standard delivery times. Your time matters!</h1>
            </div>

            <div className=' w-[330px] items-center justify-center text-center flex flex-col'>
              <div className=' bg-[#EB0728] w-[50px] h-[50px] rounded-[50px] flex font-bold leading-[36.39px] text-white text-[30px] items-center justify-center text-center'>2</div>
              <h1 className=' font-bold text-[18px] leading-[21.83px] mt-[22px]'>Save BIG on global shipments</h1>
              <h1 className=' mt-2 text-[16px] sm:text-[18px] leading-[21.83px]'>Save up to 80% off shipments worldwide and enjoy unmatched cost efficiency with Goodifly</h1>
            </div>

            <div className=' w-[300px] items-center justify-center text-center flex flex-col'>
              <div className=' bg-[#EB0728] w-[50px] h-[50px] rounded-[50px] flex font-bold leading-[36.39px] text-white text-[30px] items-center justify-center text-center'>3</div>
              <h1 className=' font-bold text-[18px] leading-[21.83px] mt-[22px]'>Safety Guarantee</h1>
              <h1 className=' mt-2 text-[16px] sm:text-[18px] leading-[21.83px]'>Goodifly's ironclad guarantee ensures secure deliveries every time</h1>
            </div>
          </div>
        </div>

        {/**Join the Goodifly Community */}
        <div className=' bg-[#FFDFE4]  flex flex-col w-full items-center justify-center text-center py-[83px] sm:py-[102px] px-[2rem] sm:px-[4.375rem] md:px-[5rem] '>
        <img src={goodifly} alt="" className='  w-[98px] h-[30px] xs:w-[132.31px] xs:h-[40px]' />
        <h1 className=' mt-3 sm:mt-[28px] lg:w-[837px]  text-[25px] lg:text-[35px] xl:text-[50px] font-bold leading-[30.33px] lg:leading-[60.65px]'>Join the Goodifly Community</h1>
        <h1 className=' mt-3 sm:mt-[9px] text-[16px] sm:text-[18px] font-normal leading-[19.41px] lg:leading-[21.83px]'>Be Among the First to Experience a New Era in Shipping!</h1>
        <h1 className='  lg:w-[1032px] mt-9 sm:mt-[44px] text-[16px] sm:text-[18px] font-normal leading-[19.41px] lg:leading-[21.83px]'>Ready to redefine the way you ship? Join our early access community and become part of a global movement that's making shipping personal, efficient, and cost-effective. Don't miss out – be one of the first to elevate your shipping experience with Goodifly!</h1>
        <button  onClick={()=>setCommunityModal(true)} className=' mt-[36px] sm:mt-[47px] text-[16px] sm:text-[18px] leading-[21.83px] flex justify-center items-center text-center w-[180px] sm:w-[210px] h-[45px] bg-[#EB0728] text-white rounded-[40px]'>Join our community</button>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default About
