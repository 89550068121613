import React, { useState } from "react";
import baseUrl from "../../components/baseUrl.js";
import axios from "axios";
import { useDispatch } from "react-redux";
import { sessionTrue } from "../../features/pages/sessionSlice.js";
import PriceCalculator from "../../components/priceCalculator";
import CarrierPriceCalculator from "../../components/carrierPriceCalculator.js";

const MarketOrderSummary = ({
  access_token,
  flight,
  marketLinks,
  totalWeight,
  totalPrice,
  goBack,
  userID,
  username,
  phoneNumber,
}) => {
  const dispatch = useDispatch();
  //disable
  const [disabled, setDisabled] = useState(false);
  //products id
  let productIds = []

  const weight = parseFloat(flight?.minimum_space) < 1 ? flight?.maximum_space : parseFloat(totalWeight).toFixed(1) === '0.0' ? 0.1 : parseFloat(totalWeight).toFixed(1)
  const luggageSpaceAmount = PriceCalculator(weight).senderCost;
  const serviceCharge = (0.24 * (PriceCalculator(weight).senderCost - 25))

  const totalCharge =
    parseFloat(luggageSpaceAmount) + parseFloat(serviceCharge) + totalPrice;

  const initiatePaymentConfig = async (id) => {
    const data = JSON.stringify({
      booking_id: id,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/initiate-payment/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      if (response?.data) {
        window.localStorage.setItem(
          "sdkjuiagiufdwyifdyawefuvwevyd7fuvyaewvtdfvyauewdfewthsuuysdfgduy",
          JSON.stringify(response.data)
        );
        window.location.href = response?.data?.data?.authorization_url;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    setDisabled(true);
    const data = JSON.stringify({
      flight_book: flight.id,
      item_description: "Market Place",
      item_weight: parseFloat(totalWeight).toFixed(1) === '0.0' ? 0.1 : parseFloat(totalWeight).toFixed(1),
      item_fee: parseFloat(totalCharge).toFixed(2),
      sender: userID,
      carrier_earnings: CarrierPriceCalculator(
        totalWeight,
        PriceCalculator(totalWeight).senderCost
      ),
      item_sender_name: username,
      item_sender_number: phoneNumber,
      request_type: 3,
      scraped_product_ids: productIds
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/booking/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      data: data,
    };
    try {
      await axios
        .request(config)
        .then(async (response) => {
          if (response?.data) {
            await initiatePaymentConfig(response.data?.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      if (error?.response?.data?.messages) {
        if (
          error?.response?.data?.messages[0]?.message ===
          "Token is invalid or expired"
        ) {
          dispatch(sessionTrue());
        }
      }
    }
  };
  return (
    <div className=" w-full flex flex-col lg:flex-row gap-[53px] lg:gap-5 justify-between pt-[119px] sm:pt-[176px] px-[2rem] md:px-[5rem]">
      {/**Share product link */}
      <div className=" w-full lg:w-1/2 lg:max-w-[602px] flex flex-col">
        <div className="font-bold flex flex-col gap-[9px] ">
          <h1 className=" text-[25px] sm:text-[35px] leading-[30.33px] sm:leading-[42.46px]">
            Order summary
          </h1>
          <h1 className=" text-[14px] sm:text-[16px] leading-[16.98px] sm:leading-[19.41px]">
            Almost there!
          </h1>
          <h1 className=" font-normal text-[14px] text-[#AAAAAA] leading-[16.98px]">
            Here's your order summary. Give it a quick look before you confirm
            to make sure everything's spot on
          </h1>
        </div>

        {/**Weight Range */}
        <div className=" w-full flex gap-[7px] mt-[33px]">
          <h1 className="text-[#AAAAAA] text-[16px] leading-[19.41px]">
            Minimum and maximum space required
          </h1>
          <div className="flex font-bold text-[#EB0728] text-[16px] leading-[19.41px]">
            {flight?.minimum_space < 1 ? (
              <h1>{flight?.maximum_space}kg</h1>
            ) : (
              <h1>
                {flight?.minimum_space}kg - {flight.maximum_space}kg
              </h1>
            )}
          </div>
        </div>

        {/**Image */}
        <img
          src={`https://${flight?.airport_img}`}
          alt=""
          className=" w-full h-[300px] sm:h-[506px] sm:mt-[37px]  rounded-[30px] overflow-scroll"
        />
      </div>

      {/**Order Summary */}
      <div className=" w-full flex flex-col justify-between lg:w-1/2 lg:max-w-[568px] h-[607px]  sm:h-[709px] max-h-[709px] py-[42px] rounded-[30px] border-solid border-[1px] border-[#C1C1C180] overflow-scroll">
        <div className="w-full flex flex-col px-[20px] sm:px-[40px]">
          {/*Second step */}
          <div className=" w-full flex items-center justify-center">
            {/**Number 1 */}
            <div className=" w-[25px] h-[25px] flex items-center justify-center text-center bg-[#D9D9D9] font-bold text-[#707070] text-[12px] leading-[14.56px] rounded-full">
              1
            </div>
            {/**Line */}
            <div className=" w-full max-w-[180px] border-dashed border-t-[1px] border-t-[#AAAAAA]" />
            {/**Number 2 */}
            <div className=" w-[25px] h-[25px] flex items-center justify-center text-center bg-[#EB0728] font-bold text-white text-[12px] leading-[14.56px] rounded-full">
              2
            </div>
          </div>

          {/**Market item details */}
          <div className=" w-full mt-[50px] flex flex-col gap-[15px]">
            {marketLinks.map((marketLink, index) => {
              productIds.push(marketLink?.productId)
              return (
                <div key={index} className="w-full flex justify-between">
                  {/**Name and Weight */}
                  <div className="flex flex-col gap-[6px]">
                    <div
                      className="tooltip"
                      title={marketLink?.product_name}
                    >
                      <h1 className=" cursor-pointer font-bold text-[16px] leading-[19.41px]">
                        {marketLink?.product_name?.length > 20
                          ? marketLink?.product_name?.slice(0, 20) + "..."
                          : marketLink?.product_name}
                      </h1>
                    </div>
                    <p className="text-[14px] text-[#AAAAAA] leading-[16.98px]">
                      Item weight - {parseFloat(marketLink?.weight).toFixed(1) === '0.0' ? 0.1 : parseFloat(marketLink?.weight).toFixed(1)}kg
                    </p>
                  </div>


                  {/**Price and Quantity */}
                  <div className="flex flex-col gap-[6px] items-end justify-end">
                    <h1 className=" font-bold text-[16px] leading-[19.41px]">
                      ${marketLink?.price}
                    </h1>
                    <p className=" text-[14px] text-[#AAAAAA] leading-[16.98px]">
                      Qty {marketLink?.quantity}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/**Price break down and buttons */}
        <div className=" w-full">
          {/**Total Weight */}
          <div className=" px-[20px] sm:px-[40px] w-full mt-[25px] flex justify-between">
            <p className=" text-[14px] text-[#AAAAAA] leading-[16.98px]">
              Total weight
            </p>
            <h1 className=" font-bold text-[16px] leading-[19.41px]">
              {parseFloat(totalWeight).toFixed(1) === '0.0' ? 0.1 : parseFloat(totalWeight).toFixed(1)}kg
            </h1>
          </div>

          {/**Line */}
          <div className="w-full mt-[29px] mb-[25px] border-solid border-b-[1px] border-b-[#C1C1C180]" />

          <div className="w-full mt-[25px] flex flex-col gap-[10px] px-[20px] sm:px-[40px]">
            {/**Subtotal */}
            <div className=" w-full flex justify-between">
              <p className=" text-[14px] text-[#AAAAAA] leading-[16.98px]">
                Subtotal
              </p>
              <h1 className=" font-bold text-[16px] leading-[19.41px]">
                ${parseFloat(totalPrice).toFixed(2)}
              </h1>
            </div>

            {/**Luggage space*/}
            <div className=" w-full flex justify-between">
              <p className=" text-[14px] text-[#AAAAAA] leading-[16.98px]">
                Luggage space
              </p>
              <h1 className=" font-bold text-[16px] leading-[19.41px]">
                ${parseFloat(luggageSpaceAmount).toFixed(2)}
              </h1>
            </div>

            {/**Goodifly service charges*/}
            <div className=" w-full flex justify-between">
              <p className=" text-[14px] text-[#AAAAAA] leading-[16.98px]">
                Goodifly service charges
              </p>
              <h1 className=" font-bold text-[16px] leading-[19.41px]">
                ${parseFloat(serviceCharge).toFixed(2)}
              </h1>
            </div>
          </div>

          <div className="px-[20px] sm:px-[40px] w-full mt-[28px] flex flex-col gap-[30px]">
            {/**Total*/}
            <div className=" w-full flex justify-between">
              <p className=" text-[14px] text-[#AAAAAA] leading-[16.98px]">
                Total
              </p>
              <h1 className=" font-bold text-[16px] leading-[19.41px]">
                ${parseFloat(totalCharge).toFixed(2)}
              </h1>
            </div>

            {/**Buttons */}
            <div className="w-full flex gap-4 justify-between">
              {/**Back button */}
              <button
                onClick={() => goBack()}
                className=" mt-auto w-full h-[40px] min-h-[40px] rounded-[80px] border-solid border-[1px] border-[#AAAAAA] flex items-center justify-center text-center text-[#AAAAAA] text-[16px] leading-[19.41px]"
              >
                Back
              </button>
              {/**Pay button */}
              <button
                disabled={disabled}
                onClick={() => handleSubmit()}
                className=" disabled:bg-[#F4C5CC] disabled:cursor-not-allowed mt-auto w-full h-[40px] min-h-[40px] rounded-[80px] bg-[#EB0728] flex items-center justify-center text-center text-[#FFFFFF] text-[16px] leading-[19.41px]"
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketOrderSummary;
