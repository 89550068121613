import React from 'react'
import { useDispatch } from 'react-redux'
import { BookFlightTrue } from '../../features/flight/bookFlightSlice'
import { UnableFlightTrue } from '../../features/flight/unableFlightSlice'
import './Flight.css'
import { convertTo12HourFormat, formatDate } from '../ConvertDateAndTime'
import planeIcon from './assets/planeIcon.svg'
import MarketplaceBadge from './MarketplaceBadge'


const Flight = ({ flight, setIsMarketPlace }) => {
  // Decode the data after retrieving it
  function decodeData(encodedData) {
    return atob(encodedData); // Base64 decoding
  }
  const some_data = window.localStorage.getItem(
    "jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy"
  );
  const current_Data = some_data ? decodeData(some_data) : null;
  const parsedData = some_data ? JSON?.parse(current_Data) : null;
  const dispatch = useDispatch();
  const currentDateTime = new Date();
  const departureDateTime = new Date(
    `${flight?.departure_date}T${flight?.depart_time}`
  );
  // Calculate the difference in days
  const differenceInTime = departureDateTime - currentDateTime;
  const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

  const handleOnClick = () => {

    if (some_data && parsedData?.id === flight?.carrier_id) {
      dispatch(UnableFlightTrue())
    }

    else {
      dispatch(BookFlightTrue(flight))
    }

    if (differenceInDays >= 7) {
      setIsMarketPlace(true)
    }
  }
  return (
    <>
      <div
        onClick={handleOnClick}
        className=" cursor-pointer  flex flex-col rounded-[30px] w-[330px] h-fit bg-[#FFFFFF] border-solid border-[1px] border-[#C1C1C180] shadow-adminLogIn"
      >
        <img
          src={`https://${flight?.airport_img}`}
          alt=""
          className=" airportImage  rounded-tl-[30px] rounded-tr-[30px] w-full h-full object-cover  z-0 relative"
        />


        {flight?.from_city==="Accra"? null : differenceInDays >= 7 && <MarketplaceBadge />}

        <div className="  w-full px-[28px] pb-[30px] mt-[23px]">
          <div className=" w-full flex items-center justify-between">
            {/**Departure Info */}
            <div className=" text-[12px] leading-[14.56px] flex flex-col gap-[2px] items-center">
              <h1 className=" text-[#AAAAAA]">{flight?.from_city}</h1>
              <h1 className=" font-bold text-[16px] leading-[19.41px]">
                {flight?.from_city_code}
              </h1>
              <h1 className=" text-[#AAAAAA]">
                {formatDate(flight?.departure_date)}
              </h1>
              <h1 className=" text-[#AAAAAA]">
                {convertTo12HourFormat(flight?.depart_time)}
              </h1>
            </div>

            <img src={planeIcon} alt="" className=" w-[16px] h-[16px]" />

            {/**Arrival Info */}
            <div className=" text-[12px] leading-[14.56px] flex flex-col gap-[2px] items-center">
              <h1 className=" text-[#AAAAAA]">{flight?.to_city}</h1>
              <h1 className=" font-bold text-[16px] leading-[19.41px]">
                {flight?.to_city_code}
              </h1>
              <h1 className=" text-[#AAAAAA]">
                {formatDate(flight?.arrival_date)}
              </h1>
              <h1 className=" text-[#AAAAAA]">
                {convertTo12HourFormat(flight?.arrival_time)}
              </h1>
            </div>
          </div>

          {/**Available Space */}
          <div className=" w-full h-[50px] flex gap-[24px] items-center justify-center rounded-[15px] border-solid border-[1px] border-[#C1C1C180] mt-[32px]">
            <h1 className="text-[#AAAAAA] text-[12px] leading-[14.56px]">
              Available space
            </h1>
            <div className=" leading-[19.41px] flex items-center justify-center text-center text-[16px] md:text-[14px] lg:text-[16px]  text-[#EB0728] font-bold">
              {flight?.minimum_space < 1 ? (
                <h1>{flight?.maximum_space}kg</h1>
              ) : (
                <h1>
                  {flight?.minimum_space}kg - {flight.maximum_space}kg
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Flight
