import React, { useState } from 'react'
import goodifly from '../assets/goodifly.png'
import axios from 'axios'
import baseUrl from '../../baseUrl'
import { useDispatch } from 'react-redux';
import { sessionTrue } from '../../../features/pages/sessionSlice';

const MarketPlaceOutboundViewModal = ({ access_token, setViewMarketPlaceOutboundViewModal, viewMarketPlaceOutboundViewModal, setViewFlaggedOutboundViewModal, setViewSuccessOutboundModal }) => {
  const dispatch = useDispatch()
  const products = viewMarketPlaceOutboundViewModal?.scraped_products
  let totalWeight = 0.0

  const [copiedId, setCopiedId] = useState(null)

  const handleCopy = (item) => {
    navigator.clipboard.writeText(item?.url)
      .then(() => {
        console.log('URL copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
    setCopiedId(item?.id)
    setTimeout(() => {
      setCopiedId(null)
    }, 1000)
  };
  const hideModal = (event) => {
    const modal = document.getElementById('modal');
    // Check if the clicked element is outside of the modal
    if (event.target !== modal && !modal.contains(event.target)) {
      setViewMarketPlaceOutboundViewModal(null)
    }
  }

  const handleReceived = async (item) => {
    const data = JSON.stringify({
      flight_book: item.flight_book,
      item_description: item.item_description,
      item_fee: item.item_fee,
      item_weight: item.item_weight,
      sender: item.sender,
      carrier_acceptance_status: 3,
      agent_approval_outbound: 2
    })
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/booking/${item.id}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
      },
      data: data
    };

    await axios.request(config)
      .then((response) => {
        if (response?.data?.id) {
          setViewMarketPlaceOutboundViewModal(null)
          setViewSuccessOutboundModal("This request is now marked as received.")
        }
      }).catch((error) => {
        if (error?.response?.data?.messages) {
          if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
            dispatch(sessionTrue())

          }
        }
        console.log(error)
      })
  }

  const handleApprove = async (item) => {
    const data = JSON.stringify({
      flight_book: item.flight_book,
      item_description: item.item_description,
      item_fee: item.item_fee,
      item_weight: item.item_weight,
      sender: item.sender,
      carrier_acceptance_status: 3,
      agent_approval_outbound: 3
    })
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/booking/${item.id}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
      },
      data: data
    };

    await axios.request(config)
      .then((response) => {
        if (response?.data?.id) {
          setViewMarketPlaceOutboundViewModal(null)
          setViewSuccessOutboundModal("This request is now approved!.")
        }
      }).catch((error) => {
        if (error?.response?.data?.messages) {
          if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
            dispatch(sessionTrue())

          }
        }
        console.log(error)
      })
  }

  const handleComplete = async (item) => {
    const data = JSON.stringify({
      flight_book: item.flight_book,
      item_description: item.item_description,
      item_fee: item.item_fee,
      item_weight: item.item_weight,
      sender: item.sender,
      carrier_acceptance_status: 3,
      agent_approval_outbound: 4,
    })
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/booking/${item.id}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
      },
      data: data
    };

    await axios.request(config)
      .then((response) => {
        if (response?.data?.id) {
          setViewMarketPlaceOutboundViewModal(null)
          setViewSuccessOutboundModal("This request is now completed!.")
        }
      }).catch((error) => {
        if (error?.response?.data?.messages) {
          if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
            dispatch(sessionTrue())

          }
        }
        console.log(error)
      })
  }

  const handleRemoveFlag = async (item) => {
    const data = JSON.stringify({
      flight_book: item?.flight_book,
      item_description: item?.item_description,
      item_fee: item?.item_fee,
      item_weight: item?.item_weight,
      sender: item?.sender,
      carrier_acceptance_status: 3,
      agent_approval_outbound: parseInt(item?.agent_approval_outbound) - 4,
      agent_outbound_comment: null
    })
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/booking/${item.id}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
      },
      data: data
    };

    await axios.request(config)
      .then((response) => {
        if (response?.data?.id) {
          setViewMarketPlaceOutboundViewModal(null)
          setViewSuccessOutboundModal("The flag has been removed from the request.")
        }
      }).catch((error) => {
        if (error?.response?.data?.messages) {
          if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
            dispatch(sessionTrue())

          }
        }
        console.log(error)
      })

  }


  const handleFlagged = async (item) => {
    setViewMarketPlaceOutboundViewModal(null)
    setViewFlaggedOutboundViewModal(item)
  }

  return (
    <div>
      <div
        onClick={hideModal}
        className=" cursor-pointer  h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
      >
        <div id='modal' className=" w-full sm:w-auto relative my-6 mx-[2%] sm:mx-0 ">
          {/*content*/}
          <div className=" cursor-default border-0 rounded-[30px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/**Main Item */}
            <div className=' w-full sm:w-[518px] h-full min-h-[413px] rounded-[30px] px-[20px] sm:px-[47px] py-[40px] bg-[#ffffff]'>
              <img src={goodifly} alt="logo" className=' w-[107px] h-[33px]' />
              {/**Title and Market Place */}
              <div className='w-full mt-[2px] flex items-end justify-between'>
                <h1 className=' font-bold text-[18px] text-black leading-[21.83px]'>Order summary</h1>
                <div className='flex flex-col gap-[6px] items-end'>
                  <div className=' w-[100px] h-[25px] rounded-[8px] bg-[#E5E5E5] flex items-center justify-center text-center font-bold text-[14px] leading-[16.98px]'><span>Marketplace</span></div>
                  <p className=' text-[16px] text-[#AAAAAA] leading-[19.41px]'>Amazon</p>
                </div>
              </div>

              <div className=' mt-5 pt-[20px] w-full h-[364px] rounded-[20px] flex flex-col justify-between border-solid border-[1px] border-[#C1C1C180]'>
                {/**Product Links */}
                <div className=' px-[20px] w-full h-full flex flex-col gap-[32px] overflow-scroll'>
                  {
                    products?.map(item => {
                      totalWeight += parseFloat(item?.weight)
                      return (
                        <div className='w-full flex justify-between'>
                          <div className='flex flex-col gap-[9px]'>
                            <div
                              className="tooltip"
                              title={item?.product_name}
                            >
                              <h1 className=" cursor-pointer font-bold text-[16px] text-black leading-[19.41px]">
                                {item?.product_name?.length > 20
                                  ? item?.product_name?.slice(0, 20) + "..."
                                  : item?.product_name}
                              </h1>
                            </div>
                            <div className='flex flex-col gap-[6px]'>
                              <p className=' text-[14px] text-[#AAAAAA] leading-[16.98px]'>Quantity {item?.product_quantity}</p>
                              <div className='w-full flex items-center'>
                                <p className=' text-[14px] text-[#AAAAAA] leading-[16.98px]'>Weight {parseFloat(item?.weight).toFixed(1)}</p>
                                {copiedId ? copiedId === item?.id ? <div className=" transform transition-all ease-in-out duration-300 font-semibold  text-[#4FBF26] relative left-full">Copied</div> : null : null}
                              </div>
                              {/**Product Url */}
                              <div className=' w-full flex items-center'>
                                <div
                                  className="tooltip"
                                  title={item?.url}
                                >
                                  <p className=" cursor-pointer text-[14px] text-[#AAAAAA] leading-[16.98px]">
                                    {item?.url?.length > 20
                                      ? item?.url?.slice(0, 20) + "..."
                                      : item?.url}
                                  </p>
                                </div>
                                {/*Copy Button*/}
                                <button onClick={() => handleCopy(item)} className="w-[90px] h-[30px] rounded-[80px] font-semibold bg-[#EB0728] text-[16px] text-white leading-[19.41px] relative left-[60%]">
                                  Copy url
                                </button>
                              </div>
                            </div>
                          </div>
                          <h1 className=' font-bold text-[16px] text-black leading-[19.41px]'>${parseFloat(item?.price)}</h1>
                        </div>
                      )
                    })
                  }
                </div>
                {/**Recipient Info and Total Weight */}
                <div className='px-[20px] pt-[10px] pb-[14px] w-full min-h-[81px] flex items-center justify-between border-solid border-t-[1px] border-t-[#C1C1C180]'>
                  <div className='flex flex-col gap-[1px]'>
                    <p className=' text-[14px] text-[#AAAAAA] leading-[16.98px]'>Recipient</p>
                    <div className='flex flex-col gap-[3px]'>
                      <h1 className=' font-bold text-[16px] text-black leading-[19.41px]'>{viewMarketPlaceOutboundViewModal?.item_sender_name}</h1>
                      <p className=' text-[14px] text-[#AAAAAA] leading-[16.98px]'>{viewMarketPlaceOutboundViewModal?.item_sender_number}</p>
                    </div>
                  </div>

                  <div className='flex gap-2'>
                    <p className=' text-[16px] text-[#AAAAAA] leading-[19.41px]'>Total weight</p>
                    <h1 className=' font-bold text-[16px] text-black leading-[19.41px]'>{parseFloat(totalWeight).toFixed(1)}kg</h1>
                  </div>
                </div>
              </div>
              {/**Buttons */}
              <div className=' w-full mt-[22px]  px-4 sm:px-[21px] flex gap-[14px] '>
                {
                  viewMarketPlaceOutboundViewModal?.agent_approval_outbound === 1 ?
                    <button onClick={() => handleReceived(viewMarketPlaceOutboundViewModal)} type="button" className=' w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Mark as received</button>
                    : viewMarketPlaceOutboundViewModal?.agent_approval_outbound === 2 ?
                      <button onClick={() => handleApprove(viewMarketPlaceOutboundViewModal)} type="button" className=' w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Approve</button>
                      : viewMarketPlaceOutboundViewModal?.agent_approval_outbound === 3 ?
                        <button onClick={() => handleComplete(viewMarketPlaceOutboundViewModal)} type="button" className=' w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Complete</button>
                        : null
                }
                {
                  viewMarketPlaceOutboundViewModal?.agent_approval_outbound < 4 ?
                    <button onClick={() => handleFlagged(viewMarketPlaceOutboundViewModal)} type="button" className=' w-full h-[40px] rounded-[80px] bg-white border-solid border-[1px] boerder-[#C1C1C1] text-[16px] text-black leading-[19.41px]'>Flag</button>
                    : null
                }
                {
                  viewMarketPlaceOutboundViewModal?.agent_approval_outbound >= 5 ?
                    <button onClick={() => handleRemoveFlag(viewMarketPlaceOutboundViewModal)} type="button" className=' w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Remove flag</button>
                    : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
    </div>
  )
}

export default MarketPlaceOutboundViewModal
