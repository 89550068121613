import React, { useState } from 'react'
import PriceCalculator from '../priceCalculator.js'
import moment from 'moment';
import ListFlightSelector from './ListFlightSelector';
import { BiChevronDown } from "react-icons/bi";
import Calendar from './Calender';
import TimePicker from './TimePicker';
import axios from 'axios';
import errorIcon from './assets/error.svg'
import { useDispatch } from 'react-redux';
import { ListFlightFalse } from '../../features/flight/listFlightSlice';
import { useNavigate } from 'react-router-dom';
import baseUrl from '../baseUrl.js'
import { FlightListSuccessTrue } from '../../features/flight/flightListSuccessSlice.js';
import infoBlue from './assets/infoBlue.svg'
import infoGray from './assets/infoGray.svg'
import { sessionTrue } from '../../features/pages/sessionSlice';
import CustomTooltip from '../CustomTooltip/CustomTooltip.jsx';


const ListFlight = ({ access_token, carrierID, airports }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState("")
    const [departureAirport, setDepartureAirport] = useState("")
    const [arrivalAirport, setArrivalAirport] = useState("")

    //Date 
    const [departureDateOpen, setDepartureDateOpen] = useState(false);
    const [departureDate, setDepartureDate] = useState(moment().add(2, 'days'));
    const [departurePlaceholder, setDeparturePlaceholder] = useState(true);

    const [arrivalDateOpen, setArrivalDateOpen] = useState(false);
    const [arrivalDate, setArrivalDate] = useState(moment().add(2, 'days'))
    const [arrivalPlaceholder, setArrivalPlaceholder] = useState(true);


    //Terminal and Space
    const [terminal, setTerminal] = useState("")
    const [space_available, setSpaceAvailable] = useState({
        minimumSpace: "",
        maximumSpace: ""
    })
    const [maxEarning, setMaxEarning] = useState()

    //Time 
    const [departureTime, setDepartureTime] = useState("Departure Time")
    const [arrivalTime, setArrivalTime] = useState("Arrival Time")
    const [departureTimeOpen, setDepartureTimeOpen] = useState(false);
    const [arrivalTimeOpen, setArrivalTimeOpen] = useState(false);

    //disable
    const [disabled, setDisabled] = useState(false)


    const selectDepartureDate = (date) => {
        setDepartureDate(date);
        setDepartureDateOpen(false);
    };

    const selectArrivalDate = (date) => {
        setArrivalDate(date);
        setArrivalDateOpen(false);
    };

    //Date Handlers 
    const handleDepartureDateOpen = () => {
        setDepartureDateOpen(!departureDateOpen)
        setArrivalDateOpen(false)
    }
    const handleArrivalDateOpen = () => {
        setArrivalDateOpen(!arrivalDateOpen)
        setDepartureDateOpen(false)
    }

    //Time Handlers 
    const handleDepartureTimeOpen = () => {
        setDepartureTimeOpen(!departureTimeOpen)
        setArrivalTimeOpen(false)
    }
    const handleArrivalTimeOpen = () => {
        setArrivalTimeOpen(!arrivalTimeOpen)
        setDepartureTimeOpen(false)
    }


    const handleSpaceAvailable = (e) => {
        const value = e.target.value
        // console.log(value)
        // console.log(PriceCalculator(value).senderCost-50)
        if (parseFloat(value) > 0) {
            setSpaceAvailable({ minimumSpace: "", maximumSpace: parseFloat(parseFloat(value).toFixed(1)) })
            setMaxEarning(PriceCalculator(value).senderCost - 25)
        } else {
            setSpaceAvailable({ minimumSpace: "", maximumSpace: "" })
        }
    }

    // Checking time interval
    function isFutureTimeMoreThanTwoDays(selectedMoment, selectedTime) {
        const now = moment(); // Create a moment object for the current time

        // Extract hours, minutes, and seconds from the selectedTime string
        const [hour, minute, second] = selectedTime.split(':').map(Number);

        // Set the time of the selectedMoment object to the specified selectedTime
        selectedMoment.set({
            hour: hour,
            minute: minute,
            second: second
        });

        // Calculate the difference in hours
        const differenceInHours = selectedMoment.diff(now, 'hours', true); // 'true' for fractional result

        // Check if the difference is greater than 24 hours
        return differenceInHours > 48;
    }

    const checkTimeDifference = (startTime, endTime) => {
        // Parse the time strings to moment objects
        const startMoment = moment(startTime, "HH:mm:ss");
        const endMoment = moment(endTime, "HH:mm:ss");

        // Calculate the difference in minutes
        const differenceInMinutes = endMoment.diff(startMoment, 'minutes');

        // Check if the difference is up to 30 minutes
        const isDifferenceUpTo30Minutes = Math.abs(differenceInMinutes) < 30;


        // For demonstration, returning the result
        return isDifferenceUpTo30Minutes;
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (departureAirport.length < 1) {
            setErrorMessage("Departure Airport is Required!")
        }
        else if (arrivalAirport.length < 1) {
            setErrorMessage("Arrival Airport is Required!")
        }
        else if (departurePlaceholder) {
            setErrorMessage("Departure Date is Required!")
        }
        else if (arrivalPlaceholder) {
            setErrorMessage("Arrival Date is Required!")
        }
        // else if (space_available.length < 1) {
        //     setErrorMessage("Space Available is Required!")
        // }
        // else if (+space_available < 1) {
        //     setErrorMessage("Must be greater than 0")
        // }
        else if (departureTime === "Departure Time") {
            setErrorMessage("Departure Time is Required!")
        }
        else if (arrivalTime === "Arrival Time") {
            setErrorMessage("Arrival Time is Required!")
        }
        else if (departureDate?.date() === moment().date()) {
            setErrorMessage("Invalid Date")
        }
        else if (departureDate?.format('YYYY-MM-DD') > arrivalDate?.format('YYYY-MM-DD')) {
            setErrorMessage("Invalid Date Range!")
        }
        else if (departureDate?.format('YYYY-MM-DD') === arrivalDate?.format('YYYY-MM-DD') && (arrivalTime <= departureTime)) {
            setErrorMessage("Invalid Time Range!")
        }
        else if (!isFutureTimeMoreThanTwoDays(departureDate, departureTime)) {
            setErrorMessage("Departure time should be more than 48 hours")
        }
        else if (departureDate?.format('YYYY-MM-DD') === arrivalDate?.format('YYYY-MM-DD') && checkTimeDifference(departureTime, arrivalTime) === true) {
            setErrorMessage("Difference less than 30 minutes")
        }
        else if (terminal.length < 1) {
            setErrorMessage("Terminal is Required!")
        }
        else {
            setErrorMessage("")
            setDisabled(true)
            const data = JSON.stringify({
                departure_date: departureDate.format('YYYY-MM-DD'),
                arrival_date: arrivalDate.format('YYYY-MM-DD'),
                departure_time: departureTime,
                arrival_time: arrivalTime,
                terminal: terminal,
                minimum_space: space_available.minimumSpace === "" ? 0.0 : space_available.minimumSpace,
                maximum_space: space_available.maximumSpace,
                carrier: carrierID,
                departure_airport: departureAirport,
                arrival_airport: arrivalAirport
            })

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${baseUrl}/flight/`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    if (response?.data?.id) {
                        // alert("Flight Listed Successfully!!!")
                        dispatch(ListFlightFalse())
                        dispatch(FlightListSuccessTrue())
                        navigate('/dashboard/carrier')
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.messages) {
                        if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                            dispatch(sessionTrue())

                        }
                    }
                    else {

                    }
                });
        }
    }

    return (
        <div>
            <div className=' smallDevice w-full sm:w-[528px] rounded-[30px] shadow-input bg-white pt-8 pb-[56px] px-[2.875rem] relative'>
                <div className=' mb-[25px]'>
                    <h1 className='text-[18px] font-bold '>List flight</h1>
                    <h1 className=' mt-1 text-[14px] text-[#707070] font-normal'>Flight details</h1>
                </div>
                <div className=''>
                    {/** List Flight Form */}
                    <div className=' box-border flex flex-wrap '>
                        <div className=' box-border m-0 basis-full flex-grow-0 max-w-full '>
                            {/** Flight Form */}
                            <div className='box-border m-0 basis-full flex-grow-0 max-w-full'>
                                <form method='post' onSubmit={handleSubmit}>
                                    <div className=' box-border flex flex-wrap items-center gap-[15px]'>
                                        {/** Departure Airport */}
                                        <div className=' box-border m-0 basis-full flex-grow-0 max-w-full'>
                                            <div className=' box-border flex flex-wrap w-full'>
                                                <div className={` ${errorMessage === "Departure Airport is Required!" ? " rounded-[2.5rem] border-[1px] border-[#EB0728]" : ""} w-full flex items-start`}>
                                                    <ListFlightSelector departureAirport={departureAirport} arrivalAirport={arrivalAirport} alreadySelected={arrivalAirport} placeholder="Departure Airport" setData={setDepartureAirport} options={airports?.filter((item)=>item.name !=="Kotoka International Airport")} />
                                                </div>
                                                {errorMessage === "Departure Airport is Required!" ? <h1 className=' pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left'>{errorMessage}</h1> : null}
                                            </div>
                                        </div>

                                        {/** Arrival Airport */}
                                        <div className=' box-border m-0 basis-full flex-grow-0 max-w-full'>
                                            <div className=' box-border flex flex-wrap w-full'>
                                                <div className={`${errorMessage === "Arrival Airport is Required!" ? "rounded-[2.5rem] border-[1px] border-[#EB0728]" : ""} w-full flex items-start`}>
                                                    <ListFlightSelector departureAirport={departureAirport} arrivalAirport={arrivalAirport} alreadySelected={departureAirport} placeholder="Arrival Airport" setData={setArrivalAirport} options={airports?.filter((item)=>item?.name !== "John F. Kennedy International Airport")} />
                                                </div>
                                                {errorMessage === "Arrival Airport is Required!" ? <h1 className=' pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left'>{errorMessage}</h1> : null}
                                            </div>
                                        </div>

                                        {/** Date */}
                                        <div className=' box-border m-0 basis-full flex-grow-0 max-w-full'>
                                            <div className=' box-border flex flex-wrap w-full'>
                                                <div className='w-full flex flex-col sm:flex-row items-start gap-[13px]'>
                                                    {/** Departure Date */}
                                                    <div className='w-full flex flex-col items-start'>
                                                        <div
                                                            onClick={() => handleDepartureDateOpen()}
                                                            className={` ${errorMessage === "Departure Date is Required!" || errorMessage === "Invalid Date" || errorMessage === "Invalid Date Range!" ? "rounded-[2.5rem] border-[1px] border-[#EB0728]" : ""} w-full pl-[20px] text-[15px] rounded-[2.5rem] h-[40px] bg-[#E5E5E580] p-2 flex items-center justify-between`}
                                                        >
                                                            <button type='button' className={` text-[#707070] pl-0 p-2 outline-none bg-transparent`}>{departurePlaceholder ? "Departure Date" : departureDate.format('YYYY-MM-DD')}</button>
                                                            <BiChevronDown color="#707070" size={25} className={`${departureDateOpen && "rotate-180"}`} />
                                                        </div>
                                                        {errorMessage === "Departure Date is Required!" || errorMessage === "Invalid Date" || errorMessage === "Invalid Date Range!" ? <h1 className=' pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left'>{errorMessage}</h1> : null}
                                                    </div>

                                                    <Calendar type="departure" alreadySelectedDate={arrivalDate} alreadyPlaceholder={arrivalPlaceholder} showCalendar={departureDateOpen} onClose={setDepartureDateOpen} selectDate={selectDepartureDate} selectedDate={departureDate} setSelectedDate={setDepartureDate} setPlaceholder={setDeparturePlaceholder} />

                                                    {/** Arrival Date */}
                                                    <div className='w-full flex flex-col items-start'>
                                                        <div
                                                            onClick={() => handleArrivalDateOpen()}
                                                            className={`  ${errorMessage === "Arrival Date is Required!" || errorMessage === "Invalid Date Range!" ? "rounded-[2.5rem] border-[1px] border-[#EB0728]" : ""} w-full pl-[20px] text-[15px] rounded-[2.5rem] h-[40px] bg-[#E5E5E580] p-2 flex items-center justify-between`}
                                                        >
                                                            <button type='button' className={` text-[#707070] pl-0 p-2 outline-none bg-transparent`}>{arrivalPlaceholder ? "Arrival Date" : arrivalDate.format('YYYY-MM-DD')}</button>
                                                            <BiChevronDown color="#707070" size={25} className={`${arrivalDateOpen && "rotate-180"}`} />
                                                        </div>
                                                        {errorMessage === "Arrival Date is Required!" || errorMessage === "Invalid Date Range!" ? <h1 className=' pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left'>{errorMessage}</h1> : null}
                                                    </div>
                                                    <Calendar type="arrival" alreadySelectedDate={departureDate} alreadyPlaceholder={departurePlaceholder} showCalendar={arrivalDateOpen} onClose={setArrivalDateOpen} selectDate={selectArrivalDate} selectedDate={arrivalDate} setSelectedDate={setArrivalDate} setPlaceholder={setArrivalPlaceholder} />
                                                </div>
                                            </div>
                                        </div>

                                        {/** Time */}
                                        <div className=' box-border m-0 basis-full flex-grow-0 max-w-full'>
                                            <div className=' box-border flex flex-wrap w-full'>
                                                <div className='w-full flex flex-col  sm:flex-row items-start gap-[13px]'>
                                                    {/** Departure Time */}
                                                    <div className='flex flex-col w-full'>
                                                        <div
                                                            onClick={() => handleDepartureTimeOpen()}
                                                            className={` ${errorMessage === "Departure Time is Required!" || errorMessage === "Departure time should be more than 48 hours" || errorMessage === "Invalid Time Range!" || errorMessage === "Difference less than 30 minutes" ? "rounded-[2.5rem] border-[1px] border-[#EB0728]" : ""} w-full pl-[20px] text-[15px] rounded-[2.5rem] h-[40px] bg-[#E5E5E580] p-2 flex items-center justify-between`}
                                                        >
                                                            <button type='button' className={` text-[#707070] pl-0 p-2 outline-none bg-transparent`}>{departureTime}</button>
                                                            <BiChevronDown color="#707070" size={25} className={`${departureTimeOpen && "rotate-180"}`} />
                                                        </div>
                                                        {errorMessage === "Departure Time is Required!" || errorMessage === "Departure time should be more than 48 hours" || errorMessage === "Invalid Time Range!" || errorMessage === "Difference less than 30 minutes" ? <h1 className=' pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left'>{errorMessage}</h1> : null}
                                                    </div>
                                                    <TimePicker type="departure" showPicker={departureTimeOpen} onClose={handleDepartureTimeOpen} selectTime={setDepartureTime} />
                                                    {/** Arrival Time */}
                                                    <div className='flex flex-col w-full'>
                                                        <div
                                                            onClick={() => handleArrivalTimeOpen()}
                                                            className={` ${errorMessage === "Arrival Time is Required!" || errorMessage === "Invalid Time Range!" || errorMessage === "Difference less than 30 minutes" ? "rounded-[2.5rem] border-[1px] border-[#EB0728]" : ""} w-full pl-[20px] text-[15px] rounded-[2.5rem] h-[40px] bg-[#E5E5E580] p-2 flex items-center justify-between`}
                                                        >
                                                            <button type='button' className={` text-[#707070] pl-0 p-2 outline-none bg-transparent`}>{arrivalTime}</button>
                                                            <BiChevronDown color="#707070" size={25} className={`${arrivalTimeOpen && "rotate-180"}`} />
                                                        </div>
                                                        {errorMessage === "Arrival Time is Required!" || errorMessage === "Invalid Time Range!" || errorMessage === "Difference less than 30 minutes" ? <h1 className=' pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left'>{errorMessage}</h1> : null}
                                                    </div>
                                                    <TimePicker type="arrival" showPicker={arrivalTimeOpen} onClose={handleArrivalTimeOpen} selectTime={setArrivalTime} />
                                                </div>
                                            </div>
                                        </div>


                                        {/** Termnal */}
                                        <div className=' box-border m-0 basis-full flex-grow-0 max-w-full'>
                                            <div className=' box-border flex flex-wrap w-full'>
                                                <div className='w-full flex flex-col  sm:flex-row items-start gap-[13px]'>
                                                    {/** Terminal */}
                                                    <div className=' w-full'>
                                                        <input value={terminal} onChange={(e) => setTerminal(e.target.value)} type="text" name="terminal" id="terminal" data-cy="terminal" placeholder="Arrival Terminal" className={` ${errorMessage === "Terminal is Required!" ? " border-[1px] border-[#EB0728]" : ""} pl-[20px] m-0 placeholder-[#707070] text-[15px] text-[#707070] w-full font-normal bg-[#E5E5E580] h-[40px] rounded-[30px] `} />
                                                        {errorMessage === "Terminal is Required!" ? <h1 className=' pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left'>{errorMessage}</h1> : null}
                                                        {errorMessage === "Terminal is Required!" ?
                                                            <div className=' w-full flex flex-row-reverse z-50 pb-[33px] -mt-[3.3rem] pr-4'>
                                                                <img src={errorIcon} alt="" className='' />
                                                            </div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/**Maximum and Minimun Space */}
                                        <div className=' w-full flex items-center gap-[24px]'>
                                            {/**Minimum */}
                                            <CustomTooltip text="Indicate the least kg you’re willing to accept">
                                                <div className="minimum flex flex-col gap-[9px] group relative">
                                                    <div className='flex gap-[5px]'>
                                                        <img src={infoGray} alt="infoGray" className='w-[11.67px] h-[11.67px]' />
                                                        <p className='text-[12px] text-[#707070] leading-[14.56px]'>Minimum space</p>
                                                    </div>
                                                    <input
                                                        value={space_available.minimumSpace}
                                                        onChange={(e) => {
                                                            const value = parseFloat(e.target.value);
                                                            if (value > 0 && value <= space_available.maximumSpace) {
                                                                setSpaceAvailable({
                                                                    minimumSpace: parseFloat(value.toFixed(1)),
                                                                    maximumSpace: space_available.maximumSpace
                                                                });
                                                            } else {
                                                                setSpaceAvailable({
                                                                    minimumSpace: "",
                                                                    maximumSpace: space_available.maximumSpace
                                                                });
                                                            }
                                                        }}
                                                        type="number"
                                                        placeholder='10kg'
                                                        className='w-[90px] h-[40px] flex items-center justify-center text-center border-solid border-[1px] border-[#C1C1C1] rounded-[30px]'
                                                    />
                                                </div>
                                            </CustomTooltip>


                                            {/**Line */}
                                            <div className=' mt-[20px] w-[10px] h-[1px] bg-[#707070]' />

                                            {/**Maximum */}
                                            <CustomTooltip text="Indicate the maximum kg you’re willing to accept">
                                                <div className='flex flex-col gap-[9px] group relative'>
                                                    <div className='maximum flex gap-[5px]'>
                                                        <img src={infoGray} alt="infoGray" className=' w-[11.67px] h-[11.67px]' />
                                                        <p className=' text-[12px] text-[#707070] leading-[14.56px]'>Maximum space</p>
                                                    </div>
                                                    <input value={space_available.maximumSpace} onChange={(e) => handleSpaceAvailable(e)} required type="number" placeholder='50kg' className=' w-[90px] h-[40px]  flex items-center justify-center text-center border-solid border-[1px] border-[#C1C1C1] rounded-[30px]' />
                                                </div>
                                            </CustomTooltip>
                                        </div>

                                        {/** Max Earning and Submit */}
                                        <div className=' box-border m-0 basis-full flex-grow-0 max-w-full'>
                                            <div className=' box-border flex flex-wrap w-full'>
                                                <div className='w-full flex flex-col  sm:flex-row items-start gap-[11px]'>
                                                    {/** Max Earning */}
                                                    <div className=' w-full flex flex-col'>
                                                        <h1 className='text-16px] flex w-full items-center justify-between sm:justify-start text-center'>Max earnings: <span className=' ml-2 text-[30px] font-bold'>${maxEarning ? maxEarning : 0}</span></h1>
                                                        <div className=' max-w-[220px] items-center text-center justify-center px-[5px] py-[3px] flex gap-[3.5px] border-solid border-[1px] bg-[#40A0FF4D] border-[#036FE3] rounded-[20px]'>
                                                            <img src={infoBlue} alt="" className=' w-[11.67px] h-[11.67px]' />
                                                            <h1 className=' font-bold text-[10px] text-[#036FE3] leading-[12.13px]'>Earnings subject to service charge</h1>
                                                        </div>
                                                    </div>
                                                    {/**  Submit Button */}
                                                    <button disabled={disabled} type="submit" className=' disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full sm:w-[400px] rounded-[30px] bg-[#EB0728] h-[40px] flex items-center justify-center text-center text-[16px] text-white'>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListFlight
