import React, { useEffect, useState } from 'react'
import { FaBars } from 'react-icons/fa';
import goodifly from './assets/goodifly.png'
import { LogInTrue } from '../../features/nav/logInSlice'
import { SignUpTrue } from '../../features/nav/signUpSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import closeIcon from './assets/closeIcon.svg'

const NavBar = () => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch()
  const [validUser, setValidUser] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };


  const handleCarrier = () => {
    if (validUser) {
      navigate("/dashboard/carrier")
    }
    else {
      dispatch(LogInTrue())
    }
  }
  useEffect(() => {

    const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
    if (some_data) {
      setValidUser(true)
      // const current_Data = decodeData(some_data)
      // const parsedData = JSON.parse(current_Data)
      if(window.location.href === '/'){
        navigate("/dashboard")
      }
    }
   
  }, []);


  return (
    <>
      <div className={` z-50 bg-white bg-cover fixed flex flex-col w-full  pt-[31px] sm:pt-[2rem] px-[2rem] sm:px-[4.375rem] md:px-[5rem] `}>
        <div className='flex w-full justify-between'>
          <img src={goodifly} alt="" onClick={() => navigate("/")} className=" cursor-pointer w-[130px] h-[39.88px] sm:w-[11.875rem] sm:h-[3.643rem]" />
          <div className='flex gap-5 justify-center items-center'>
            <button onClick={handleCarrier} className=' hidden sm:flex text-[#ffffff] text-center items-center justify-center text-[1.125rem]  bg-[#EB0728] w-[11.25rem] h-[2.813rem] rounded-[40px] flex-shrink-0'>Become a Carrier</button>
            {/**large devices */}
            <div className={`hidden lg:flex gap-5 justify-center items-center `}>
              <button onClick={() => dispatch(LogInTrue())} className={` ${validUser ? "hidden" : ""} text-[1.125rem] font-bold `}>Login</button>
              <button onClick={() => dispatch(SignUpTrue())} className={` ${validUser ? "hidden" : ""} text-[1.125rem] font-bold `}>Sign up</button>
              <button onClick={() => navigate("/contact")} className='text-[1.125rem] font-bold '>Contact support</button>
            </div>
          </div>
          {/**small devices */}
          <div className="lg:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="text-white hover:text-gray-300 focus:outline-none"
            >
              <FaBars color='black' className=' w-[30px] h-[30px] sm:w-[40px] sm:h-[40px]' />
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      {isOpen && (
        <div className=" lg:hidden fixed inset-0 bg-black bg-opacity-50 z-[60] flex items-center justify-center">
          <div className=" px-8 pt-8 bg-white w-full xs:w-4/5 xm:max-w-[393px] h-full absolute left-0 transform z-[70]">
            {/* Close Button */}
            <div className=' w-full flex items-center justify-between'>
              <img src={goodifly} alt="" className=' w-[130px] h-[39.88px]' />

              <button
                className="absolute text-white right-5"
                onClick={closeMenu}
              >
                <img src={closeIcon} alt="" srcSet="" className=' w-[17.06px] h-[17.06px]' />
              </button>
            </div>
            {/* Your navigation links here */}
            <div className=' mt-[66.12px] items-start justify-start font-bold text-[18px] flex flex-col gap-[16px] '>
              <button onClick={() => { dispatch(LogInTrue()); closeMenu() }} >Login</button>
              <button onClick={() => { dispatch(SignUpTrue()); closeMenu() }} >Sign up</button>
              <button onClick={() => navigate("/contact")}>Contact support</button>
            </div>
            {/**Become a carrier */}
            <button onClick={()=>{handleCarrier(); closeMenu()}} className=' sm:hidden mt-[35px] flex text-[#ffffff] text-center items-center justify-center text-[16px]  bg-[#EB0728] w-full h-[40px] rounded-[50px] flex-shrink-0'>Become a Carrier</button>
          
          <div className=' ml-[-32px] absolute flex items-center justify-center w-full gap-[5px] bottom-0'>
            <h1 className=' text-[30px]'>©</h1>
            <h1 className=' text-[12px]'>2023 Goodifly. All rights reserved.</h1>
          </div>
          </div>
        </div>
      )}
    </>

  )
}

export default NavBar
