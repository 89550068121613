import React, { useState, useEffect, useRef } from "react";
import { BiChevronDown } from "react-icons/bi";
import errorIcon from './assets/error.svg'

const MarketDirectorySelector = ({
  options,
  placeholder,
  id,
  setMarketLinks,
  marketLinks,
  market,
}) => {
  const [data, setData] = useState(options);
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);
  const selectorRef = useRef(null);

  const handleQuantityDecreament = (value) => {
    if (value > 1) {
      updateMarketLink(id, "quantity", value - 1);
    }
  };

  const handleQuantityIncreament = (value) => {
      updateMarketLink(id, "quantity", value + 1);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    updateMarketLink(id, "name", value);
    if (value) {
      setOpen(true);
    }
  };

  const hideSelector = (event) => {
    const modal = document.getElementById("modal");
    // Check if the clicked element is outside of the modal
    if (event.target !== modal && !modal.contains(event.target)) {
      setOpen(!open);
    }
  };

  // Function to update a specific field of a specific item
  const updateMarketLink = (id, field, value) => {
    setMarketLinks((prevLinks) =>
      prevLinks.map((link) =>
        link.id === id ? { ...link, [field]: value } : link
      )
    );
  };


  // Function to remove a market link and reset ids
  const removeMarketLink = (id) => {
    console.log(id)
    marketLinks.splice(id, 1);
    console.log(marketLinks)
    setMarketLinks((prevLinks) => {
      // Filter out the link with the specified id
      const updatedLinks = prevLinks.filter((link) => link.id !== id);
      // Reset ids
      return updatedLinks.map((link, index) => ({ ...link, id: index }));
    });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const filteredOptions = options?.filter((option) =>
      option?.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setData(filteredOptions);
  }, [inputValue, options]);

  return (
    <div className="w-full flex flex-col gap-[16px] sm:gap-[23px] relative">
      {/**Selector */}
      <div ref={selectorRef} className={`w-full cursor-pointer`}>
        <div
          onClick={() => {
            setOpen(!open);
          }}
          className={` ${market.nameError !== "" ? "border-solid border-[1px] border-[#EB0728]" : ""} px-5 border-solid border-[1px] border-[#C1C1C180] w-full text-[1.125rem] rounded-[2.5rem] h-[40px] bg-white flex items-center justify-between ${!selected && "text-gray-700"
            }`}
        >
          <input
            type="text"
            id="input"
            value={market.name}
            onChange={handleInputChange}
            placeholder={placeholder}
            className={` placeholder:text-[#AAAAAA] outline-none w-full text-[14px] leading-[16.98px]`}
          />
          <BiChevronDown
            className={` ${open ? "rotate-180" : ""
              } w-[24px] h-[24px] text-[#AAAAAA]`}
          />
        </div>
        {/**nameError Message */}
        {
          market.nameError !== "" ?
            <div className=" w-full mt-2 flex gap-[8px] items-center">
              <img src={errorIcon} alt="" className=" w-[18px] h-[18px]" />
              <p className=" font-normal text-[#EB0728] text-[12px] leading-[14.56px]">{market.nameError}</p>
            </div> : null
        }
        {open || inputValue.length > 0 ? (
          <div className="relative">
            <div
              onClick={hideSelector}
              className="cursor-pointer w-full h-full justify-center flex overflow-x-hidden overflow-y-auto z-[70] outline-none focus:outline-none scrollbar-hide"
            >
              <ul
                id="modal"
                className={`${!open ? "hidden" : ""
                  } absolute rounded-[20px] my-2 w-[90%] left-1/2 transform -translate-x-1/2 shadow-market bg-white overflow-y-auto z-50 ${open
                    ? data?.length < 6
                      ? "max-h-fit"
                      : "h-[190px]"
                    : "max-h-0"
                  }`}
              >
                {data && data.length > 0 ? (
                  data?.map((item) => (
                    <li
                      key={item?.name}
                      className={`p-2 text-sm hover:bg-[#EB0728] hover:text-white ${item?.name.toLowerCase() === selected?.toLowerCase() &&
                        "bg-[#EB0728] text-white"
                        } ${item?.name.toLowerCase().includes(inputValue)
                          ? "block"
                          : !open || inputValue.length === 0
                            ? "hidden"
                            : "block"
                        }`}
                      onClick={() => {
                        if (
                          item?.name.toLowerCase() !== selected?.toLowerCase()
                        ) {
                          setSelected(item?.name);
                          setOpen(false);
                          setInputValue(item?.name);
                          updateMarketLink(id, "name", item?.name);
                        } else {
                          setOpen(false);
                          setSelected(item?.name);
                          setInputValue(item?.name);
                          updateMarketLink(id, "name", item?.name);
                        }
                      }}
                    >
                      {item?.name}
                    </li>
                  ))
                ) : (
                  <li className="p-2 text-sm text-[#EB0728]">
                    no results found
                  </li>
                )}
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {/**Link */}
      <div className="w-full flex flex-col gap-2">
        <input
          type="text"
          id="input"
          value={market.link}
          onChange={(e) => updateMarketLink(id, "link", e.target.value)}
          placeholder="Paste product URL here"
          className={` ${market.linkError !== "" ? "border-solid border-[1px] border-[#EB0728]" : ""} px-5 border-solid border-[1px] border-[#C1C1C180] w-full rounded-[2.5rem] h-[40px] bg-white flex items-center justify-between placeholder:text-[#AAAAAA] outline-none text-[14px] leading-[16.98px]`}
        />
        {/**LinkError Message */}
        {
          market.linkError !== "" ?
            <div className=" w-full flex gap-[8px] items-center">
              <img src={errorIcon} alt="" className=" w-[18px] h-[18px]" />
              <p className=" font-normal text-[#EB0728] text-[12px] leading-[14.56px]">{market.linkError}</p>
            </div> : null
        }
      </div>

      {/**Removal Button and Quantity */}
      <div className="w-full flex gap-4 justify-between">
        {/**Removal button */}
        {marketLinks.length > 1 ?
          <button onClick={() => removeMarketLink(id)} className=" w-[120px] h-[40px] rounded-[30px] border-[2px] bg-[#EB0728] border-[#EB0728] text-white flex items-center justify-center text-center text-[18px]">
            Remove
          </button> : <button disabled className=" outline-none border-none cursor-default w-[120px] h-[40px] rounded-[30px] border-[2px] flex items-center justify-center text-center text-[18px]">
          </button>}

        {/**Quantity */}
        <div className=" flex items-center gap-[10px]">
          <p className=" font-normal text-[#AAAAAA] text-[14px] leading-[16.98px]">
            Qty
          </p>
          <div className="w-[120px] h-[40px] flex items-center justify-center gap-[10px] bg-[#E5E5E580]  rounded-[30px]">
            <button
              type="button"
              disabled={market.quantity <= 1 ? true : false}
              onClick={() => handleQuantityDecreament(market.quantity)}
              className="disabled:opacity-20 disabled:cursor-not-allowed font-normal text-[25px] leading-[30.33px]"
            >
              -
            </button>
            <input
              type="number"
              value={market.quantity}
              onChange={(e) =>
                updateMarketLink(id, "quantity", e.target.value)
              }
              className=" w-full max-w-[45px] h-[30px] flex items-center justify-center text-center bg-[#FFFFFF] rounded-[5px]"
            />
            <button
              type="button"
              // disabled={market.quantity >= 10 ? true : false}
              onClick={() => handleQuantityIncreament(market.quantity)}
              className="disabled:opacity-20 disabled:cursor-not-allowed font-normal text-[25px] leading-[30.33px]"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MarketDirectorySelector;
