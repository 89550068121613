// import React, { useState } from 'react'
// import goodifly from '../assets/goodifly.png'
// import axios from 'axios'
// import baseUrl from '../../baseUrl'
// import { useDispatch } from 'react-redux';
// import { sessionTrue } from '../../../features/pages/sessionSlice';

// const MarketPlaceViewModal = ({ access_token, setViewMarketPlaceViewModal, viewMarketPlaceViewModal, setViewFlaggedViewModal, setViewSuccessModal }) => {
//   const dispatch = useDispatch()
//   const products = viewMarketPlaceViewModal?.scraped_products
//   let totalWeight = 0.0

//   const [copiedId, setCopiedId] = useState(null)

//     //disable states
//     const [disabledReceived, setDisabledReceived] = useState(false)
//     const [disabledApprove, setDisabledApprove] = useState(false)
//     const [disabledComplete, setDisabledComplete] = useState(false)
//     const [disabledRemoveFlag, setDisabledRemoveFlag] = useState(false)


//   const handleCopy = (item) => {
//     navigator.clipboard.writeText(item?.url)
//       .then(() => {
//         console.log('URL copied to clipboard');
//       })
//       .catch(err => {
//         console.error('Failed to copy: ', err);
//       });
//     setCopiedId(item?.id)
//     setTimeout(() => {
//       setCopiedId(null)
//     }, 1000)
//   };


//   const hideModal = (event) => {
//     const modal = document.getElementById('modal');
//     // Check if the clicked element is outside of the modal
//     if (event.target !== modal && !modal.contains(event.target)) {
//       setViewMarketPlaceViewModal(null)
//     }
//   }

//   const handleReceived = async (item) => {
//     setDisabledReceived(true)
//     const data = JSON.stringify({
//       flight_book: item.flight_book,
//       item_description: item.item_description,
//       item_fee: item.item_fee,
//       item_weight: item.item_weight,
//       sender: item.sender,
//       carrier_acceptance_status: 3,
//       agent_approval_inbound: 2
//     })
//     let config = {
//       method: 'patch',
//       maxBodyLength: Infinity,
//       url: `${baseUrl}/booking/${item.id}/`,
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${access_token}`
//       },
//       data: data
//     };

//     await axios.request(config)
//       .then((response) => {
//         if (response?.data?.id) {
//           setViewMarketPlaceViewModal(null)
//           setViewSuccessModal("This request is now marked as received.")
//           setDisabledReceived(false)
//         }
//       }).catch((error) => {
//         setDisabledReceived(false)
//         if (error?.response?.data?.messages) {
//           if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
//             dispatch(sessionTrue())

//           }
//         }
//         console.log(error)
//       })
//   }

//   const handleApprove = async (item) => {
//     setDisabledApprove(true)
//     const data = JSON.stringify({
//       flight_book: item.flight_book,
//       item_description: item.item_description,
//       item_fee: item.item_fee,
//       item_weight: item.item_weight,
//       sender: item.sender,
//       carrier_acceptance_status: 3,
//       agent_approval_inbound: 3
//     })
//     let config = {
//       method: 'patch',
//       maxBodyLength: Infinity,
//       url: `${baseUrl}/booking/${item.id}/`,
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${access_token}`
//       },
//       data: data
//     };

//     await axios.request(config)
//       .then((response) => {
//         if (response?.data?.id) {
//           setViewMarketPlaceViewModal(null)
//           setViewSuccessModal("This request is now approved!.")
//           setDisabledApprove(false)
//         }
//       }).catch((error) => {
//         setDisabledApprove(false)
//         if (error?.response?.data?.messages) {
//           if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
//             dispatch(sessionTrue())

//           }
//         }
//         console.log(error)
//       })
//   }

//   const handleComplete = async (item) => {
//     setDisabledComplete(true)
//     const data = JSON.stringify({
//       flight_book: item.flight_book,
//       item_description: item.item_description,
//       item_fee: item.item_fee,
//       item_weight: item.item_weight,
//       sender: item.sender,
//       carrier_acceptance_status: 3,
//       agent_approval_inbound: 4,
//       agent_approval_outbound: 1

//     })
//     let config = {
//       method: 'patch',
//       maxBodyLength: Infinity,
//       url: `${baseUrl}/booking/${item.id}/`,
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${access_token}`
//       },
//       data: data
//     };

//     await axios.request(config)
//       .then((response) => {
//         if (response?.data?.id) {
//           setViewMarketPlaceViewModal(null)
//           setViewSuccessModal("This request is now completed!.")
//           setDisabledComplete(false)
//         }
//       }).catch((error) => {
//         setDisabledComplete(false)
//         if (error?.response?.data?.messages) {
//           if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
//             dispatch(sessionTrue())

//           }
//         }
//         console.log(error)
//       })
//   }

//   const handleRemoveFlag = async (item) => {
//     setDisabledRemoveFlag(true)
//     const data = JSON.stringify({
//       flight_book: item?.flight_book,
//       item_description: item?.item_description,
//       item_fee: item?.item_fee,
//       item_weight: item?.item_weight,
//       sender: item?.sender,
//       carrier_acceptance_status: 3,
//       agent_approval_inbound: parseInt(item?.agent_approval_inbound) - 4,
//       agent_inbound_comment: null
//     })
//     let config = {
//       method: 'patch',
//       maxBodyLength: Infinity,
//       url: `${baseUrl}/booking/${item.id}/`,
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${access_token}`
//       },
//       data: data
//     };

//     await axios.request(config)
//       .then((response) => {
//         if (response?.data?.id) {
//           setViewMarketPlaceViewModal(null)
//           setViewSuccessModal("The flag has been removed from the request.")
//           setDisabledRemoveFlag(false)
//         }
//       }).catch((error) => {
//         setDisabledRemoveFlag(false)
//         if (error?.response?.data?.messages) {
//           if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
//             dispatch(sessionTrue())

//           }
//         }
//         console.log(error)
//       })

//   }


//   const handleFlagged = async (item) => {
//     setViewMarketPlaceViewModal(null)
//     setViewFlaggedViewModal(item)
//   }

//   return (
//     <div>
//       <div
//         onClick={hideModal}
//         className=" cursor-pointer  h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
//       >
//         <div id='modal' className=" w-full sm:w-auto relative my-6 mx-[2%] sm:mx-0 ">
//           {/*content*/}
//           <div className=" cursor-default border-0 rounded-[30px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
//             {/**Main Item */}
//             <div className=' w-full sm:w-[518px] h-full min-h-[413px] rounded-[30px] px-[20px] sm:px-[47px] py-[40px] bg-[#ffffff]'>
//               <img src={goodifly} alt="logo" className=' w-[107px] h-[33px]' />
//               {/**Title and Market Place */}
//               <div className='w-full mt-[2px] flex items-end justify-between'>
//                 <h1 className=' font-bold text-[18px] text-black leading-[21.83px]'>Order summary</h1>
//                 <div className='flex flex-col gap-[6px] items-end'>
//                   <div className=' w-[100px] h-[25px] rounded-[8px] bg-[#E5E5E5] flex items-center justify-center text-center font-bold text-[14px] leading-[16.98px]'><span>Marketplace</span></div>
//                   <p className=' text-[16px] text-[#AAAAAA] leading-[19.41px]'>Amazon</p>
//                 </div>
//               </div>

//               <div className=' mt-5 pt-[20px] w-full h-[364px] rounded-[20px] flex flex-col justify-between border-solid border-[1px] border-[#C1C1C180]'>
//                 {/**Product Links */}
//                 <div className=' px-[20px] w-full h-full flex flex-col gap-[32px] overflow-scroll'>
//                   {
//                     products?.map(item => {
//                       totalWeight += parseFloat(item?.weight)
//                       return (
//                         <div className='w-full flex justify-between'>
//                           <div className='flex flex-col gap-[9px]'>
//                             <div
//                               className="tooltip"
//                               title={item?.product_name}
//                             >
//                               <h1 className=" cursor-pointer font-bold text-[16px] text-black leading-[19.41px]">
//                                 {item?.product_name?.length > 20
//                                   ? item?.product_name?.slice(0, 20) + "..."
//                                   : item?.product_name}
//                               </h1>
//                             </div>
//                             <div className='flex flex-col gap-[6px]'>
//                               <p className=' text-[14px] text-[#AAAAAA] leading-[16.98px]'>Quantity {item?.product_quantity}</p>
//                               <div className='w-full flex items-center'>
//                                 <p className=' text-[14px] text-[#AAAAAA] leading-[16.98px]'>Weight {parseFloat(item?.weight).toFixed(1)}</p>
//                                 {copiedId ? copiedId === item?.id ? <div className=" transform transition-all ease-in-out duration-300 font-semibold  text-[#4FBF26] relative left-full">Copied</div> : null : null}
//                               </div>
//                               {/**Product Url */}
//                               <div className=' w-full flex items-center'>
//                                 <div
//                                   className="tooltip"
//                                   title={item?.url}
//                                 >
//                                   <p className=" cursor-pointer text-[14px] text-[#AAAAAA] leading-[16.98px]">
//                                     {item?.url?.length > 20
//                                       ? item?.url?.slice(0, 20) + "..."
//                                       : item?.url}
//                                   </p>
//                                 </div>
//                                 {/*Copy Button*/}
//                                 <button onClick={() => handleCopy(item)} className="w-[90px] h-[30px] rounded-[80px] font-semibold bg-[#EB0728] text-[16px] text-white leading-[19.41px] relative left-[60%]">
//                                   Copy url
//                                 </button>
//                               </div>
//                             </div>
//                           </div>
//                           <h1 className=' font-bold text-[16px] text-black leading-[19.41px]'>${parseFloat(item?.price)}</h1>
//                         </div>
//                       )
//                     })
//                   }
//                 </div>
//                 {/**Sender Info and Total Weight */}
//                 <div className='px-[20px] pt-[10px] pb-[14px] w-full min-h-[81px] flex items-center justify-between border-solid border-t-[1px] border-t-[#C1C1C180]'>
//                   <div className='flex flex-col gap-[1px]'>
//                     <p className=' text-[14px] text-[#AAAAAA] leading-[16.98px]'>Sender</p>
//                     <div className='flex flex-col gap-[3px]'>
//                       <h1 className=' font-bold text-[16px] text-black leading-[19.41px]'>{viewMarketPlaceViewModal?.item_sender_name}</h1>
//                       <p className=' text-[14px] text-[#AAAAAA] leading-[16.98px]'>{viewMarketPlaceViewModal?.item_sender_number}</p>
//                     </div>
//                   </div>

//                   <div className='flex gap-2'>
//                     <p className=' text-[16px] text-[#AAAAAA] leading-[19.41px]'>Total weight</p>
//                     <h1 className=' font-bold text-[16px] text-black leading-[19.41px]'>{parseFloat(totalWeight).toFixed(1)}kg</h1>
//                   </div>
//                 </div>
//               </div>
//               {/**Buttons */}
//               <div className=' w-full mt-[22px]  px-4 sm:px-[21px] flex gap-[14px] '>
//                 {
//                   viewMarketPlaceViewModal?.agent_approval_inbound === 1 ?
//                     <button disabled={disabledReceived} onClick={() => handleReceived(viewMarketPlaceViewModal)} type="button" className=' disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Mark as received</button>
//                     : viewMarketPlaceViewModal?.agent_approval_inbound === 2 ?
//                       <button disabled={disabledApprove} onClick={() => handleApprove(viewMarketPlaceViewModal)} type="button" className=' disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Approve</button>
//                       : viewMarketPlaceViewModal?.agent_approval_inbound === 3 ?
//                         <button disabled={disabledComplete} onClick={() => handleComplete(viewMarketPlaceViewModal)} type="button" className=' disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Complete</button>
//                         : null
//                 }
//                 {
//                   viewMarketPlaceViewModal?.agent_approval_inbound < 4 ?
//                     <button onClick={() => handleFlagged(viewMarketPlaceViewModal)} type="button" className=' disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-white border-solid border-[1px] boerder-[#C1C1C1] text-[16px] text-black leading-[19.41px]'>Flag</button>
//                     : null
//                 }
//                 {
//                   viewMarketPlaceViewModal?.agent_approval_inbound >= 5 ?
//                     <button disabled={disabledRemoveFlag} onClick={() => handleRemoveFlag(viewMarketPlaceViewModal)} type="button" className=' disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Remove flag</button>
//                     : null
//                 }
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
//     </div>
//   )
// }

// export default MarketPlaceViewModal

import React, { useState } from "react";
import goodifly from "../assets/goodifly.png";
import axios from "axios";
import baseUrl from "../../baseUrl";
import { useDispatch } from "react-redux";
import { sessionTrue } from "../../../features/pages/sessionSlice";

const MarketPlaceViewModal = ({
  access_token,
  setViewMarketPlaceViewModal,
  viewMarketPlaceViewModal,
  setViewFlaggedViewModal,
  setViewSuccessModal,
}) => {
  const dispatch = useDispatch();
  const products = viewMarketPlaceViewModal?.scraped_products;
  let totalWeight = 0.0;

  const [copiedId, setCopiedId] = useState(null);
  const [disabled, setDisabled] = useState({
    received: false,
    approve: false,
    complete: false,
    removeFlag: false,
  });

  const handleCopy = (item) => {
    navigator.clipboard
      .writeText(item?.url)
      .then(() => console.log("URL copied to clipboard"))
      .catch((err) => console.error("Failed to copy: ", err));
    setCopiedId(item?.id);
    setTimeout(() => setCopiedId(null), 1000);
  };

  const hideModal = (event) => {
    const modal = document.getElementById("modal");
    if (event.target !== modal && !modal.contains(event.target)) {
      setViewMarketPlaceViewModal(null);
    }
  };

  const handleApiRequest = async (item, action, data) => {
    setDisabled((prev) => ({ ...prev, [action]: true }));
    try {
      const response = await axios.patch(
        `${baseUrl}/booking/${item.id}/`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      console.log(`Response from ${action} action:`, response.data);
      if (response?.data?.id) {
        setViewMarketPlaceViewModal(null);
        setViewSuccessModal(
          `This request is now ${action
            .replace(/([A-Z])/g, " $1")
            .toLowerCase()}.`
        );
      }
    } catch (error) {
      if (
        error?.response?.data?.messages?.[0]?.message ===
        "Token is invalid or expired"
      ) {
        dispatch(sessionTrue());
      }
      console.error(error);
    } finally {
      setDisabled((prev) => ({ ...prev, [action]: false }));
    }
  };

  const handleReceived = (item) =>
    handleApiRequest(item, "received", {
      flight_book: item.flight_book,
      item_description: item.item_description,
      item_fee: item.item_fee,
      item_weight: item.item_weight,
      sender: item.sender,
      carrier_acceptance_status: 3,
      agent_approval_inbound: 2,
    });

  const handleApprove = (item) =>
    handleApiRequest(item, "approve", {
      flight_book: item.flight_book,
      item_description: item.item_description,
      item_fee: item.item_fee,
      item_weight: item.item_weight,
      sender: item.sender,
      carrier_acceptance_status: 3,
      agent_approval_inbound: 3,
    });

  const handleComplete = (item) =>
    handleApiRequest(item, "complete", {
      flight_book: item.flight_book,
      item_description: item.item_description,
      item_fee: item.item_fee,
      item_weight: item.item_weight,
      sender: item.sender,
      carrier_acceptance_status: 3,
      agent_approval_inbound: 4,
      agent_approval_outbound: 1,
    });

  const handleRemoveFlag = (item) =>
    handleApiRequest(item, "removeFlag", {
      flight_book: item?.flight_book,
      item_description: item?.item_description,
      item_fee: item?.item_fee,
      item_weight: item?.item_weight,
      sender: item?.sender,
      carrier_acceptance_status: 3,
      agent_approval_inbound: parseInt(item?.agent_approval_inbound) - 4,
      agent_inbound_comment: null,
    });

  const handleFlagged = (item) => {
    setViewMarketPlaceViewModal(null);
    setViewFlaggedViewModal(item);
  };

  return (
    <div>
      <div
        onClick={hideModal}
        className="cursor-pointer h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[70] outline-none focus:outline-none scrollbar-hide"
      >
        <div
          id="modal"
          className="w-full sm:w-auto relative my-6 mx-[2%] sm:mx-0"
        >
          <div className="cursor-default border-0 rounded-[30px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="w-full sm:w-[518px] h-full min-h-[413px] rounded-[30px] px-[20px] sm:px-[47px] py-[40px] bg-[#ffffff]">
              <img src={goodifly} alt="logo" className="w-[107px] h-[33px]" />
              <div className="w-full mt-[2px] flex items-end justify-between">
                <h1 className="font-bold text-[18px] text-black leading-[21.83px]">
                  Order summary
                </h1>
                <div className="flex flex-col gap-[6px] items-end">
                  <div className="w-[100px] h-[25px] rounded-[8px] bg-[#E5E5E5] flex items-center justify-center text-center font-bold text-[14px] leading-[16.98px]">
                    <span>Marketplace</span>
                  </div>
                  <p className="text-[16px] text-[#AAAAAA] leading-[19.41px]">
                    Amazon
                  </p>
                </div>
              </div>
              <div className="mt-5 pt-[20px] w-full h-[364px] rounded-[20px] flex flex-col justify-between border-solid border-[1px] border-[#C1C1C180]">
                <div className="px-[20px] w-full h-full flex flex-col gap-[32px] overflow-scroll">
                  {products?.map((item) => {
                    totalWeight += parseFloat(item?.weight);
           

           // Remove non-numeric characters except for decimal points and convert to float
           const cleanedPrice = (item?.price || "").replace(/[^0-9.]/g, "");
           const parsedPrice = parseFloat(cleanedPrice);

                    return (
                      <div
                        className="w-full flex justify-between"
                        key={item?.id}
                      >
                        <div className="flex flex-col gap-[9px]">
                          <div className="tooltip" title={item?.product_name}>
                            <h1 className="cursor-pointer font-bold text-[16px] text-black leading-[19.41px]">
                              {item?.product_name?.length > 20
                                ? item?.product_name?.slice(0, 20) + "..."
                                : item?.product_name}
                            </h1>
                          </div>
                          <div className="flex flex-col gap-[6px]">
                            <p className="text-[14px] text-[#AAAAAA] leading-[16.98px]">
                              Quantity {item?.product_quantity}
                            </p>
                            <div className="w-full flex items-center">
                              <p className="text-[14px] text-[#AAAAAA] leading-[16.98px]">
                                Weight {parseFloat(item?.weight).toFixed(1)}
                              </p>
                              {copiedId ? (
                                copiedId === item?.id ? (
                                  <div className="transform transition-all ease-in-out duration-300 font-semibold text-[#4FBF26] relative left-full">
                                    Copied
                                  </div>
                                ) : null
                              ) : null}
                            </div>
                            <div className="w-full flex items-center">
                              <div className="tooltip" title={item?.url}>
                                <p className="cursor-pointer text-[14px] text-[#AAAAAA] leading-[16.98px]">
                                  {item?.url?.length > 20
                                    ? item?.url?.slice(0, 20) + "..."
                                    : item?.url}
                                </p>
                              </div>
                              <button
                                onClick={() => handleCopy(item)}
                                className="w-[90px] h-[30px] rounded-[80px] font-semibold bg-[#EB0728] text-[16px] text-white leading-[19.41px] relative left-[60%]"
                              >
                                Copy url
                              </button>
                            </div>
                          </div>
                        </div>
                        <h1 className="font-bold text-[16px] text-black leading-[19.41px]">
                          {/* ${parseFloat(item?.price)} */}
                          ${parsedPrice.toFixed(2)}
                        </h1>
                      </div>
                    );
                  })}
                </div>
                <div className="px-[20px] pt-[10px] pb-[14px] w-full min-h-[81px] flex items-center justify-between border-solid border-t-[1px] border-t-[#C1C1C180]">
                  <div className="flex flex-col gap-[1px]">
                    <p className="text-[14px] text-[#AAAAAA] leading-[16.98px]">
                      Sender
                    </p>
                    <div className="flex flex-col gap-[3px]">
                      <h1 className="font-bold text-[16px] text-black leading-[19.41px]">
                        {viewMarketPlaceViewModal?.item_sender_name}
                      </h1>
                      <p className="text-[14px] text-[#AAAAAA] leading-[16.98px]">
                        {viewMarketPlaceViewModal?.item_sender_number}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <p className="text-[16px] text-[#AAAAAA] leading-[19.41px]">
                      Total weight
                    </p>
                    <h1 className="font-bold text-[16px] text-black leading-[19.41px]">
                      {parseFloat(totalWeight).toFixed(1)}kg
                    </h1>
                  </div>
                </div>
              </div>
              <div className="w-full mt-[22px] px-4 sm:px-[21px] flex gap-[14px]">
                {viewMarketPlaceViewModal?.agent_approval_inbound === 1 ? (
                  <button
                    disabled={disabled.received}
                    onClick={() => handleReceived(viewMarketPlaceViewModal)}
                    type="button"
                    className="disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]"
                  >
                    Mark as received
                  </button>
                ) : viewMarketPlaceViewModal?.agent_approval_inbound === 2 ? (
                  <button
                    disabled={disabled.approve}
                    onClick={() => handleApprove(viewMarketPlaceViewModal)}
                    type="button"
                    className="disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]"
                  >
                    Approve
                  </button>
                ) : viewMarketPlaceViewModal?.agent_approval_inbound === 3 ? (
                  <button
                    disabled={disabled.complete}
                    onClick={() => handleComplete(viewMarketPlaceViewModal)}
                    type="button"
                    className="disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]"
                  >
                    Complete
                  </button>
                ) : null}
                {viewMarketPlaceViewModal?.agent_approval_inbound < 4 ? (
                  <button
                    onClick={() => handleFlagged(viewMarketPlaceViewModal)}
                    type="button"
                    className="disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-white border-solid border-[1px] border-[#C1C1C1] text-[16px] text-black leading-[19.41px]"
                  >
                    Flag
                  </button>
                ) : null}
                {viewMarketPlaceViewModal?.agent_approval_inbound >= 5 ? (
                  <button
                    disabled={disabled.removeFlag}
                    onClick={() => handleRemoveFlag(viewMarketPlaceViewModal)}
                    type="button"
                    className="disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]"
                  >
                    Remove flag
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
    </div>
  );
};

export default MarketPlaceViewModal;


