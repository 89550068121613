import React, { useEffect, useRef } from 'react';
import profile from './assets/profile.jpg'
import arrowDown from './assets/arrowDown.svg'
import callIcon from './assets/call.svg'
import settingsIcon from './assets/settings.svg'
import logoutIcon from './assets/logout.svg'
import earningIcon from './assets/earning.svg'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { ListFlightTrue } from '../../features/flight/listFlightSlice';
import {SwitchTrue} from '../../features/nav/switchStatusSlice'


function ProfileDropdown({ type, access_token, isOpenDropDown, setIsOpenDropDown }) {
    const baseUrl = "https://api.goodifly.com"
    const dropdownRef = useRef(null);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpenDropDown(false);
            }
        }

        // Attach the event listener on component mount
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpenDropDown(!isOpenDropDown);
    };

    const LogOut = async () => {
        const data = {}
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}/signout/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token,
            },
            data: data
        };
        try {
            await axios.request(config)
                .then((response) => {
                    console.log(response)
                }).catch(error => {
                    console.log("Axios Catch: => ", error)
                })
        }
        catch (error) {
            console.log(error)
        }

        window.localStorage.clear()
        navigate("/")
        window.location.reload()
    }

    return (
        <div ref={dropdownRef} className="relative inline-block text-left">
            <div>
                <button onClick={toggleDropdown} className=' w-[90px] h-[45px] bg-[#D9D9D9] rounded-[50px] flex items-center'>
                    <div className=' w-full flex items-center justify-center'>
                        <img src={arrowDown} alt="" className={` ${isOpenDropDown ? "rotate-180" : ""} w-[14.58px] h-[7.29px]`} />
                    </div>
                    <div className=' w-full flex flex-row-reverse'>
                        <img src={profile} alt="" className=' w-[45px] h-[45px] rounded-[45px]' />
                    </div>
                </button>
            </div>

            {isOpenDropDown && (
                <div className=" origin-top-right absolute right-0 mt-2 w-[251px] rounded-[20px] shadow-input bg-white ring-1 ring-black ring-opacity-5">
                    <div
                        className=" flex flex-col pt-[26px] pb-[30px] gap-[10px]"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        {type === "customer" ?
                            <div className=' w-full  flex-col justify-start pl-5 gap-3 pb-2 flex sm:hidden'>
                                <button onClick={() => navigate('/dashboard/carrier')} className=' text-[#ffffff] text-center items-center justify-center text-[16px] lg:text-[1.125rem]  bg-[#EB0728] w-[10rem]  h-[2.5rem] rounded-[40px] flex-shrink-0'>Become a Carrier</button>
                                <button onClick={() => dispatch(SwitchTrue())} className=' flex xm:hidden text-[#ffffff] text-center items-center justify-center text-[16px] bg-[#EB0728] w-[10rem]  h-[2.5rem] rounded-[40px] flex-shrink-0'>Bookings</button>
                            </div>
                            : ""}
                        {type === "carrier" ?
                            <div>
                                <div className=' w-full  flex-col justify-start pl-5 gap-3 pb-2 flex md:hidden'>
                                    <button onClick={() => navigate('/dashboard')} className=' text-[#ffffff] text-center items-center justify-center text-[16px] lg:text-[1.125rem]  bg-[#EB0728] w-[10rem]  h-[2.5rem] rounded-[40px] flex-shrink-0'>Send a package</button>
                                    <button onClick={() => dispatch(ListFlightTrue())} className=' flex xm:hidden text-[#ffffff] text-center items-center justify-center text-[16px] bg-[#EB0728] w-[10rem]  h-[2.5rem] rounded-[40px] flex-shrink-0'>List Flight</button>
                                </div>
                            </div>
                            : ""}
                        <button onClick={()=>navigate('/earnings')} className=" pl-[20px] w-full flex  hover:bg-gray-100 gap-[17px]">
                            <img src={earningIcon} alt="" />
                            <h1 className=' text-[14px]'>Earnings</h1>
                        </button>
                        <button onClick={()=>navigate('/contact')} className=" pl-[20px] w-full flex  hover:bg-gray-100 gap-[17px]">
                            <img src={callIcon} alt="" />
                            <h1 className=' text-[14px]'>Contact support</h1>
                        </button>

                        <button onClick={()=>navigate('/settings')} className=" pl-[20px] w-full flex  hover:bg-gray-100 gap-[17px]">
                            <img src={settingsIcon} alt="" />
                            <h1 className=' text-[14px]'>Settings</h1>
                        </button>

                        <button onClick={LogOut} className=" pl-[20px] w-full flex  hover:bg-gray-100 gap-[17px]">
                            <img src={logoutIcon} alt="" />
                            <h1 className=' text-[14px]'>Logout</h1>
                        </button>

                    </div>
                </div>
            )}
        </div>
    );
}

export default ProfileDropdown;
