import React, { useEffect, useState } from 'react'
import PriceCalculator from '../../components/priceCalculator';
import baseUrl from '../../components/baseUrl';
import HomeNav from '../../components/Nav/HomeNav'
import Selector from '../../components/Selector/Selector';
import search from './assets/search.svg'
import Flight from '../../components/Flight/Flight';
import alex from '../../components/Flight/assets/alex.jpg'
import Footer from '../../components/Footer/Footer';
import { useSelector } from 'react-redux';
import LogInModal from '../../components/LogIn/LogInModal'
import SignUpModal from '../../components/SignUp/SignUpModal'
import axios from 'axios';
import BookFlightModal from '../../components/Flight/BookFlightModal';
import no_flight from './assets/no_flight.svg'
import NoFlight from './NoFlight';
import placesImage from './assets/places.png'
import small_screen_placesImage from './assets/sm_places.png'


// const fligt_data = [
//   { user_img: alex, airport_img: bridge, from_city_code: "New York City", to_city_code: "Accra", departure_airport: "John F Kennedy Airport", departure_code: "JFK", arrival_airport: "Kotoka Airport", arrival_code: "ACC", date: "24th Aug 2023", depart_time: "10am", arrival_time: "9pm", available_space: "50", price: "200", username: "Barbara Antwi" },
//   { user_img: alex, airport_img: ghana, from_city_code: "London", to_city_code: "Kumasi", departure_airport: "John F Kennedy Airport", departure_code: "JFK", arrival_airport: "Kotoka Airport", arrival_code: "ACC", date: "24th Aug 2023", depart_time: "10am", arrival_time: "9pm", available_space: "50", price: "200", username: "Barbara Antwi" },
//   { user_img: alex, airport_img: structure, from_city_code: "Tamale", to_city_code: "Washington", departure_airport: "John F Kennedy Airport", departure_code: "JFK", arrival_airport: "Kotoka Airport", arrival_code: "ACC", date: "24th Aug 2023", depart_time: "10am", arrival_time: "9pm", available_space: "50", price: "200", username: "Barbara Antwi" },
//   { user_img: alex, airport_img: structure, from_city_code: "Paris", to_city_code: "Cape Coast", departure_airport: "John F Kennedy Airport", departure_code: "JFK", arrival_airport: "Kotoka Airport", arrival_code: "ACC", date: "24th Aug 2023", depart_time: "10am", arrival_time: "9pm", available_space: "50", price: "200", username: "Barbara Antwi" },
//   { user_img: alex, airport_img: bridge, from_city_code: "Accra", to_city_code: "Singapore", departure_airport: "John F Kennedy Airport", departure_code: "JFK", arrival_airport: "Kotoka Airport", arrival_code: "ACC", date: "24th Aug 2023", depart_time: "10am", arrival_time: "9pm", available_space: "50", price: "200", username: "Barbara Antwi" },
//   { user_img: alex, airport_img: ghana, from_city_code: "New York City", to_city_code: "Accra", departure_airport: "John F Kennedy Airport", departure_code: "JFK", arrival_airport: "Kotoka Airport", arrival_code: "ACC", date: "24th Aug 2023", depart_time: "10am", arrival_time: "9pm", available_space: "50", price: "200", username: "Barbara Antwi" },
//   { user_img: alex, airport_img: ghana, from_city_code: "New York City", to_city_code: "Accra", departure_airport: "John F Kennedy Airport", departure_code: "JFK", arrival_airport: "Kotoka Airport", arrival_code: "ACC", date: "24th Aug 2023", depart_time: "10am", arrival_time: "9pm", available_space: "50", price: "200", username: "Barbara Antwi" },
//   { user_img: alex, airport_img: bridge, from_city_code: "London", to_city_code: "Kumasi", departure_airport: "John F Kennedy Airport", departure_code: "JFK", arrival_airport: "Kotoka Airport", arrival_code: "ACC", date: "24th Aug 2023", depart_time: "10am", arrival_time: "9pm", available_space: "50", price: "200", username: "Barbara Antwi" },
//   { user_img: alex, airport_img: structure, from_city_code: "Tamale", to_city_code: "Washington", departure_airport: "John F Kennedy Airport", departure_code: "JFK", arrival_airport: "Kotoka Airport", arrival_code: "ACC", date: "24th Aug 2023", depart_time: "10am", arrival_time: "9pm", available_space: "50", price: "200", username: "Barbara Antwi" },
//   { user_img: alex, airport_img: structure, from_city_code: "Paris", to_city_code: "Cape Coast", departure_airport: "John F Kennedy Airport", departure_code: "JFK", arrival_airport: "Kotoka Airport", arrival_code: "ACC", date: "24th Aug 2023", depart_time: "10am", arrival_time: "9pm", available_space: "50", price: "200", username: "Barbara Antwi" },
//   { user_img: alex, airport_img: bridge, from_city_code: "Accra", to_city_code: "Singapore", departure_airport: "John F Kennedy Airport", departure_code: "JFK", arrival_airport: "Kotoka Airport", arrival_code: "ACC", date: "24th Aug 2023", depart_time: "10am", arrival_time: "9pm", available_space: "50", price: "200", username: "Barbara Antwi" },
//   { user_img: alex, airport_img: ghana, from_city_code: "New York City", to_city_code: "Accra", departure_airport: "John F Kennedy Airport", departure_code: "JFK", arrival_airport: "Kotoka Airport", arrival_code: "ACC", date: "24th Aug 2023", depart_time: "10am", arrival_time: "9pm", available_space: "50", price: "200", username: "Barbara Antwi" },
// ]
// const temp_data = []

const Home = ({ airports }) => {
  const logInModal = useSelector((state) => state.logInModal.value)
  const signUpModal = useSelector((state) => state.signUpModal.value)
  const bookFlightModal = useSelector((state) => state.bookFlight.value)
  const selectedFlight = useSelector((state) => state.bookFlight.selectedFlight)

  //Fixing the search bar
  const [fixSearch, setFixSearch] = useState(false)

  const [fromSelectedAirport, setFromSelectedAirport] = useState("")
  const [toSelectedAirport, setToSelectedAirport] = useState("")
  const [fligtData, setFlightData] = useState()
  const [allFlight, setAllFlight] = useState()
  const [justify, setJustify] = useState(false)
  
    //Is marketplace
    const [isMarketPlace, setIsMarketPlace] = useState(false)


  // Function to handle scroll event
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Smooth scrolling animation
    });
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const screenwidth = window.innerWidth

    // Check if the first div is scrolled up 
    if (scrollPosition > 620 && screenwidth >= 1100) {
      setFixSearch(true)
    }
    else if (scrollPosition > 580 && screenwidth >= 980 && screenwidth < 1100) {
      setFixSearch(true)
    }
    else if (scrollPosition > 500 && screenwidth >= 840 && screenwidth < 980) {
      setFixSearch(true)
    }
    else if (scrollPosition > 450 && screenwidth >= 750 && screenwidth < 840) {
      setFixSearch(true)
    }
    else if (scrollPosition > 400 && screenwidth >= 640 && screenwidth < 750) {
      setFixSearch(true)
    }
    else if (scrollPosition > 500 && screenwidth >= 200 && screenwidth < 640) {
      setFixSearch(true)
    }
    else {
      setFixSearch(false)
    }

    // if ((screenwidth < 365 && screenwidth >= 259) && scrollPosition > 150) {
    //   let searchDiv = document.getElementById("search")
    //   if (searchDiv) {
    //     searchDiv.style.marginTop = "-140px"
    //   }
    // }
    // else if ((screenwidth < 259 && screenwidth >= 227) && scrollPosition > 150) {
    //   let searchDiv = document.getElementById("search")
    //   if (searchDiv) {
    //     searchDiv.style.marginTop = "-150px"
    //   }
    // }
    // else if (screenwidth < 227 && scrollPosition > 150) {
    //   let searchDiv = document.getElementById("search")
    //   if (searchDiv) {
    //     searchDiv.style.marginTop = "-190px"
    //   }
    // }
    // else if (screenwidth < 365 && scrollPosition <= 150) {
    //   let searchDiv = document.getElementById("search")
    //   if (searchDiv) {
    //     searchDiv.style.marginTop = "41px"
    //   }
    // }
    // else {
    //   let searchDiv = document.getElementById("search")
    //   if (searchDiv) {
    //     searchDiv.style.marginTop = ""
    //   }
    // }

  };

  const quickSearch = () => {
    setJustify(true);
    const filteredFlight = allFlight?.filter(
      (flight) => flight.departure_airport.toLowerCase().includes(fromSelectedAirport?.toLowerCase()) && flight.arrival_airport.toLowerCase().includes(toSelectedAirport?.toLowerCase())
    );
    // Update the state based on the filtered data
    setFlightData(filteredFlight);
  };


  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/flight/`).catch((error) => {
        console.log(error)
      })

      if (response.data) {
        const tempData = [];
        await Promise.all(
          response.data.filter(item => item.booking_status === false).map(async (f) => {
            const departureResponse = await axios.get(`${baseUrl}/airport/${f.departure_airport}/`);
            const departureData = departureResponse.data;

            const arrivalResponse = await axios.get(`${baseUrl}/airport/${f.arrival_airport}/`);
            const arrivalData = arrivalResponse.data;

            // const userResponse = await axios.get(`${baseUrl}/users/${f.carrier}/`);
            // const userData = userResponse.data;

            const temp = {
              user_img: alex,
              airport_img: decodeURIComponent(arrivalData.image).slice(8),
              from_city_code: departureData.city_code,
              from_city: departureData.city,
              from_country: departureData.country,
              departure_airport: departureData.name,
              departure_code: departureData.code,
              to_city_code: arrivalData.city_code,
              to_city: arrivalData.city,
              to_country: arrivalData.country,
              arrival_airport: arrivalData.name,
              arrival_code: arrivalData.code,
              departure_date: f.departure_date,
              arrival_date: f.arrival_date,
              depart_time: f.departure_time,
              arrival_time: f.arrival_time,
              minimum_space:f.minimum_space, 
              maximum_space:f.maximum_space,
              price: PriceCalculator(f.maximum_space).senderCost,
              username: f.carrier_name,
              booking_status: f.booking_status,
            };

            tempData.push(temp);
          })
        );
        setAllFlight(tempData)
        setFlightData(tempData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if ((fromSelectedAirport.length === 0 && toSelectedAirport.length > 0) || (fromSelectedAirport.length > 0 && toSelectedAirport.length === 0)) {
      quickSearch()
    }
    if (fromSelectedAirport.length === 0 && toSelectedAirport.length === 0) {
      setFlightData(allFlight)
    }
  }, [fromSelectedAirport, toSelectedAirport])


  useEffect(() => {
    scrollToTop()
    fetchData();
    // const api = "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json";

    // axios.get(api)
    //   .then((response) => {
    //     setAirpottCities(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render


 {/**Auto Refresh */}
  // useEffect(() => {
  //   const handleMouseOver = () => {
  //     fetchData();
  //   };

  //   document.addEventListener('mouseover', handleMouseOver);

  //   return () => {
  //     document.removeEventListener('mouseover', handleMouseOver);
  //   };
  // }, []);

  if (fligtData > 0 || allFlight) {
    return (
      <div className=' h-[64rem] w-full'>
        <HomeNav area="Home" />
        {logInModal ?
          <LogInModal /> : null}
        {signUpModal ? <SignUpModal /> : null}
        {bookFlightModal ? <BookFlightModal flight={selectedFlight} /> : null}
        <div className='  bg-white   z-40  w-full pt-[100px]  sm:pt-[167px] px-[2rem] sm:px-[4.375rem] md:px-[5rem]  '>

          {/**Places Pictures */}
          <div className=' w-full flex items-center justify-center'>
            <img src={placesImage} alt="places" className=' hidden sm:flex' />
            <img src={small_screen_placesImage} alt="small_screen_placesImage" className=' flex sm:hidden' />
          </div>

          <div className=' w-full flex flex-col gap-3 items-center justify-center text-center'>
            <h1 className=' text-[25px] sm:text-[30px] md:text-[3.125rem] font-bold'>Turn flights into deliveries</h1>
            <h1 className='text-[12px] sm:text-[1.125rem]'>Taking logistics to another altitude</h1>
          </div>

          {/**Search bar */}
          <div className='w-full flex flex-col items-center justify-center mt-[31px]'>
            <div id='search' className={` ${fixSearch ? "top-[92px] fixed z-50 px-[2rem] sm:px-0" : ""} sm:pl-[10px] sm:py-[8px] sm:pr-[8px] w-full  sm:w-auto sm:h-[50px] flex flex-col sm:flex-row gap-[9px] sm:gap-0 items-center rounded-[40px] sm:border-solid sm:border-[1px] sm:border-[#C1C1C180] sm:shadow-searchBar`}>
             
              <div className='w-full sm:w-auto shadow-adminLogIn sm:shadow-none border-solid border-[1px] border-[#C1C1C180] rounded-[60px] px-4 sm:px-0 sm:rounded-none sm:border-none'>
                <Selector options={airports} setCity={setFromSelectedAirport} placeholder="From:" />
              </div>

              <hr className=' hidden sm:flex w-[1px] h-[30px] bg-[#C1C1C180]'></hr>

              <div className='w-full sm:w-auto shadow-adminLogIn sm:shadow-none border-solid border-[1px] border-[#C1C1C180] rounded-[60px] px-4 sm:px-0 sm:rounded-none sm:border-none'>
                <Selector options={airports} setCity={setToSelectedAirport} placeholder="To:" />
              </div>

              <button onClick={() => quickSearch()} className=' items-center justify-center hidden sm:flex w-[35px]  h-[35px] rounded-[40px] bg-[#EB0728]'>
                <img src={search} alt="" className=' w-full h-full' />
              </button>

              <button onClick={() => quickSearch()} className=' w-full mt-[12px] flex sm:hidden h-[40px] bg-[#EB0728] text-white text-[16px] items-center justify-center text-center rounded-[50px]'>
                Search Flights
              </button>
            </div>
          </div>
        </div>


        {/**Flights Container */}
        {
          fligtData?.length > 0 ?
            <div className={`${fixSearch?" mt-[210px] sm:mt-[99px]":"mt-[99px]"}  px-[2rem] sm:px-[4.375rem] md:px-[5rem]  `}>
              <h1 className=' text-[1.25rem] font-bold'>Available Flights</h1>

              {/**Flights */}
              <div className={`flex flex-wrap ${justify ? "justify-center" : `justify-center ${fligtData?.length % 3 === 0 ? "lg:justify-between" : "md:justify-start"}`} mt-[1.5rem] gap-[2rem]`}>
                {fligtData?.sort()?.map((flight, index) => {
                  return <Flight key={index} flight={flight} setIsMarketPlace={setIsMarketPlace}/>
                })}
              </div>
            </div> : 

            <div className={` ${fixSearch?" mt-80 sm:mt-28":"mt-28"} flex flex-col text-center justify-center items-center`}>
              <h1 className=' font-bold text-[18px] xs:text-[20px] xm:text-[25px]'>No available flights</h1>
              <h1 className=' mt-[9px] text-[#707070] text-[14px] xs:text-[16px] xm:text-[18px]'>Currently no flights available. Please check back later for updates</h1>
              <img src={no_flight} alt="" className=' mt-[20px] w-auto h-auto max-w-[333px] max-h-[415px]' />
            </div>
        }

        <Footer />
      </div>
    )
  } else {
    return (
      <div className=' w-full h-full'>
        <NoFlight />
      </div>
    )
  }
}

export default Home
