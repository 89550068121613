import React from "react";
import marketPlaceIcon from "./assets/marketplace_icon.svg"
const MarketplaceBadge = () => {
  return (
    <div className="flex items-center align-middle justify-start bg-white  h-[55px] w-[161px] rounded-full border-[1px] border-[#C1C1C180] px-[7px]  relative mt-[-30px] mb-[-20px] mx-auto">
      <img
        src={marketPlaceIcon}
        alt=""
        className=" -mt-[2.5px] w-[42px] h-[42px] rounded-full"
      />
      <span className=" font-bold text-[14px] leading-[16.98px]  text-[#EB0728] ml-3">
        Marketplace
      </span>
    </div>
  );
};

export default MarketplaceBadge