import React from 'react'
import linkedin from './assets/linkedin.svg'
import instagram from './assets/instagram.svg'
import X from './assets/X.svg'

import { useNavigate } from 'react-router-dom'
const Footer = () => {
  const navigate = useNavigate()
  return (
    <div className=' w-full flex flex-col  px-[2rem] sm:px-[4.375rem] md:px-[5rem]  pt-[75px] '>
      <div className=' sm:self-center flex flex-col sm:flex-row mt-3  lg:hidden gap-[1.125rem] text-[12px] sm:text-[1rem]'>
        <h1 onClick={()=>navigate("/about")} className=' cursor-pointer'>About Us</h1>
        <h1 onClick={()=>navigate("/contact")} className=' cursor-pointer'>Contact support</h1>
        <h1 onClick={()=>navigate("/privacy-policy")} className=' cursor-pointer'>Privacy Policy</h1>
        <h1  onClick={()=>navigate("/terms")} className=' cursor-pointer'>Terms of Use</h1>
      </div>
      <div className='sm:items-center mt-3 lg:mt-0 gap-[1.125rem] sm:gap-0 justify-between sm:text-center flex flex-col sm:flex-row'>
        <h1 className=' text-[12px] sm:text-[1.125rem]'> <span className='mr-[0.313rem]'>©</span> 2023 Goodifly. Powered by Modenbo Technologies.</h1>
        <div className=' hidden lg:flex gap-[1.125rem] text-[12px] sm:text-[1rem]'>
          <h1 onClick={()=>navigate("/about")} className=' cursor-pointer'>About Us</h1>
          <h1 onClick={()=>navigate("/contact")} className=' cursor-pointer'>Contact support</h1>
          <h1 onClick={()=>navigate("/privacy-policy")} className=' cursor-pointer'>Privacy Policy</h1>
          <h1 onClick={()=>navigate("/terms")} className=' cursor-pointer'>Terms of Use</h1>
        </div>
        <div className='flex gap-[5px]'>
         <a href="https://www.linkedin.com/company/goodifly" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="" className=' w-[1.5rem] sm:w-[2.5rem] h-[1.5rem] sm:h-[2.5rem]' /></a>
         <a href="https://www.instagram.com/goodifly/?igsh=cnJoemQwdnZudDQ3&utm_source=qr" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="" className=' w-[1.5rem] sm:w-[2.5rem] h-[1.5rem] sm:h-[2.5rem]' /></a>
          <a href="https://twitter.com/goodiflyapp" target="_blank" rel="noopener noreferrer"><img src={X} alt="" className=' w-[1.5rem] sm:w-[2.5rem] h-[1.5rem] sm:h-[2.5rem]' /></a>
        </div>
      </div>
    </div>
  )
}

export default Footer
