import React, { useEffect, useRef, useState } from "react";
import baseUrl from "../../components/baseUrl.js";
import axios from "axios";
import { useDispatch } from "react-redux";
import { sessionTrue } from "../../features/pages/sessionSlice.js";
import amanzon from "./assets/amazon.png";
import MarketDirectorySelector from "./MarketDirectorySelector";
import { FaPlus } from "react-icons/fa6";
import errorIcon from "./assets/error.svg";
import MarketOrderSummary from "./MarketOrderSummary.jsx";

const marketPlaces = [{ logo: amanzon, name: "Amazon", type: "General" }];

const MarketPlaceOrder = ({ flight }) => {
  const dispatch = useDispatch();
  const [marketLinks, setMarketLinks] = useState([
    {
      id: 0,
      productId:"",
      name: "",
      nameError: "",
      link: "",
      linkError: "",
      quantity: 1,
      asin: "",
      price: "",
      product_category: "",
      product_name: "",
      product_type: "",
      weight: "",
      errorMessage: "",
    },
  ]);

  const scrollRef = useRef(null);
  const [newLinkAdded, setNewLinkAdded] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [trackError, setTrackError] = useState("");
  const [userID, setUserID] = useState()
  const [access_token, setAccessToken] = useState();
  const [username, setUsername] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [refresh_token, setRefreshToken] = useState()


  const [totalWeight, setTotalWeight] = useState(0.0)
  const [totalPrice, setTotalPrice] = useState(0.0)

  // MarketOrderSummary
  const [orderSummary, setOrderSummary] = useState(false);

  //disable
  const [disabled, setDisabled] = useState(false);

  // Function to add a new market link
  const addMarketLink = () => {
    setMarketLinks((prevLinks) => [
      ...prevLinks,
      {
        id: prevLinks.length,
        productId:"",
        name: "",
        nameError: "",
        link: "",
        linkError: "",
        quantity: 1,
        asin: "",
        price: "",
        product_category: "",
        product_name: "",
        product_type: "",
        weight: "",
        errorMessage: "",
      },
    ]);
    setNewLinkAdded(true);
  };

  //Scrapper
  const scrapper = async (quantity, link) => {
    const data = JSON.stringify({
      product_quantity:quantity,
      url: link,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/scrape-amazon/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);

      if (response?.data) {
        return { success: true, data: response.data };
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.messages) {
        if (
          error?.response?.data?.messages[0]?.message ===
          "Token is invalid or expired"
        ) {
          dispatch(sessionTrue());
        }
      }

      return { success: false, error: error };
    }
  };

  // creating order
  const handleCreateOrder = async () => {
    let hasError = false;
    setTotalWeight(0.0)
    setTotalPrice(0.0)
    setErrorMessage("")
    let tempWeight = 0.0

    const temp_updatedMarketLinks = await Promise.all(
      marketLinks.map(async (marketLink) => {
        const updatedMarketLink = { ...marketLink };

        if (updatedMarketLink.name === "") {
          updatedMarketLink.nameError = "Marketplace is required";
          hasError = true;
        } else {
          updatedMarketLink.nameError = "";
        }

        if (updatedMarketLink.link === "") {
          updatedMarketLink.linkError = "Product URL is required";
          hasError = true;
        } else {
          updatedMarketLink.linkError = "";
        }

        if (updatedMarketLink.quantity < 1) {
          updatedMarketLink.linkError = "Quantity can't be less than 1"
          hasError = true;
        } else {
          updatedMarketLink.linkError = "";
        }

        return updatedMarketLink;
      })
    );

    setMarketLinks(temp_updatedMarketLinks);

    if (hasError === false) {
      setDisabled(true)
      const updatedMarketLinks = await Promise.all(
        marketLinks.map(async (marketLink) => {
          const updatedMarketLink = { ...marketLink };
          // Call scrapper if both name and link are provided
          const result = await scrapper(updatedMarketLink.quantity, updatedMarketLink.link);
          if (result.success === false) {
            // updatedMarketLink.linkError = result.error.response.data.error;
            updatedMarketLink.nameError = "";
            updatedMarketLink.linkError = "Sorry, we can’t find this product. Try again!"
            hasError = true;
          } else {
            if (result?.data) {
              const item = result?.data;
              const price = parseFloat(item.price.replace(/[$,]/g, ''));
              const weight = parseFloat(item?.weight);
              if (!isNaN(price) && price > 0 || weight > 0) {
                updatedMarketLink.productId = item?.id;
                updatedMarketLink.asin = item?.asin;
                updatedMarketLink.price = price;
                updatedMarketLink.product_category = item?.product_category;
                updatedMarketLink.product_name = item?.product_name;
                updatedMarketLink.product_type = item?.product_type;
                updatedMarketLink.product_quantity = item?.product_quantity;
                updatedMarketLink.weight = item?.weight;
                updatedMarketLink.linkError = "";
                updatedMarketLink.nameError = "";
                // console.log((parseFloat(item?.weight)*parseFloat(updatedMarketLink.quantity)) + parseFloat(totalWeight))
                // console.log("CurrentTotal:", parseFloat(totalWeight),"Weight:", parseFloat(item?.weight), "Qty:" ,parseFloat(updatedMarketLink.quantity))
                tempWeight += parseFloat(item?.weight).toFixed(1) === '0.0' ? 0.1 : parseFloat(item?.weight).toFixed(1) * parseFloat(updatedMarketLink.quantity)

                setTotalWeight((PREV) => PREV + (parseFloat(item?.weight).toFixed(1) === '0.0' ? 0.1 : parseFloat(item?.weight).toFixed(1) * parseFloat(updatedMarketLink.quantity)))
                setTotalPrice((PREV) => PREV + (price * parseFloat(updatedMarketLink.quantity)))
              }
              else {
                updatedMarketLink.nameError = "";
                updatedMarketLink.linkError = "Sorry, we can’t find this product. Try again!"
                hasError = true;
              }
            } else {
            }
          }

          return updatedMarketLink;
        })
      );

      setMarketLinks(updatedMarketLinks);
    }

    if (hasError) {
      setTrackError("There are errors in the form");
      setDisabled(false)
    } else {
      setTrackError("");
      if (parseFloat(tempWeight).toFixed(1) > parseFloat(flight?.maximum_space)) {
        setErrorMessage(`The total weight(${tempWeight}kg) of your order exceeds the maximum space allowed by this carrier. Please edit your order or choose another carrier`)
        setDisabled(false)
      }
      else {
        setErrorMessage("")
        setOrderSummary(true);
        setDisabled(true)
      }
    }
  };

  useEffect(() => {
    if (newLinkAdded) {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
      setNewLinkAdded(false);
    }
  }, [newLinkAdded]);

  //deconde the encodedData
  function decodeData(encodedData) {
    return atob(encodedData); // Base64 decoding
  }

  useEffect(() => {
    const some_data = window.localStorage.getItem(
      "jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy"
    );
    if (some_data) {
      const current_Data = decodeData(some_data);
      const parsedData = JSON.parse(current_Data);
      setUsername(parsedData?.full_name)
      setPhoneNumber(parsedData?.phone_number);
      setAccessToken(parsedData?.access_token)
      setRefreshToken(parsedData?.refresh_token)
      setUserID(parsedData?.id)
    } else {
      window.location.href = "/";
    }
  }, []);

  const goBack = () => {
    setDisabled(false)
    setOrderSummary(false)
  }


  return (
    <>
      {orderSummary ? (
        <MarketOrderSummary
          access_token={access_token}
          flight={flight}
          marketLinks={marketLinks}
          totalWeight={totalWeight}
          totalPrice={totalPrice}
          goBack={goBack}
          userID={userID}
          username={username}
          phoneNumber={phoneNumber}
        />
      ) : (
        <div className=" w-full flex flex-col lg:flex-row gap-[53px] lg:gap-5 justify-between pt-[119px] sm:pt-[176px] px-[2rem] md:px-[5rem]">
          {/**Share product link */}
          <div className=" w-full lg:w-1/2 lg:max-w-[602px] flex flex-col">
            <div className="font-bold flex flex-col gap-[9px] ">
              <h1 className=" text-[25px] sm:text-[35px] leading-[30.33px] sm:leading-[42.46px]">
                Share product link
              </h1>
              <h1 className=" text-[14px] sm:text-[16px] leading-[16.98px] sm:leading-[19.41px]">
                You're shopping in {flight?.from_city}
              </h1>
              <h1 className=" font-normal text-[14px] text-[#AAAAAA] leading-[16.98px]">
                Share your desired products and we handle the rest!
              </h1>
            </div>

            {/**Weight Range */}
            <div className=" w-full flex gap-[7px] mt-[33px]">
              <h1 className="text-[#AAAAAA] text-[16px] leading-[19.41px]">
                Minimum and maximum space required
              </h1>
              <div className="flex font-bold text-[#EB0728] text-[16px] leading-[19.41px]">
                {flight?.minimum_space < 1 ? (
                  <h1>{flight?.maximum_space}kg</h1>
                ) : (
                  <h1>
                    {flight?.minimum_space}kg - {flight.maximum_space}kg
                  </h1>
                )}
              </div>
            </div>

            {/**Marketplace Directory */}
            <div className=" w-full h-[400px] sm:h-[501px] flex flex-col gap-[28px] mt-[28px] sm:mt-[37px] py-[30px] sm:py-[32px] px-[28px] sm:px-[36px] rounded-[30px] border-solid border-[1px] border-[#C1C1C180] overflow-scroll">
              <div className=" w-full flex flex-col gap-[1px]">
                <h1 className=" font-bold text-[16px] sm:text-[18px] leading-[19.41px] sm:leading-[21.83px]">
                  Marketplace Directory
                </h1>
                <p className=" text-[14px] sm:text-[16px] text-[#AAAAAA] leading-[16.98px] sm:leading-[19.41px]">
                  Shops available on our marketplace
                </p>
              </div>

              {/**Market Places */}
              <div className="flex flex-col gap-[16px]">
                {marketPlaces.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className=" items-center flex gap-[10px] sm:gap-[16px]"
                    >
                      <img
                        src={item.logo}
                        alt="logo"
                        className=" w-[65px] h-[65px] sm:w-[100px] sm:h-[100px] rounded-full"
                      />
                      <div className="flex flex-col gap-[2px]">
                        <h1 className=" font-bold text-[16px] leading-[19.41px]">
                          {item.name}
                        </h1>
                        <p className=" text-[#AAAAAA] text-[14px] sm:text-[16px] leading-[16.98px] sm:leading-[19.41px]">
                          {item.type}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/**Scrapping the links */}
          <div className=" w-full lg:w-1/2 lg:max-w-[568px] h-[607px]  sm:h-[634px] max-h-[634px] px-[20px] sm:px-[40px] py-[42px] flex flex-col  rounded-[30px] border-solid border-[1px] border-[#C1C1C180] overflow-scroll">
            {/*First step */}
            <div className="w-full flex items-center justify-center">
              {/**Number 1 */}
              <div className=" w-[25px] h-[25px] flex items-center justify-center text-center bg-[#EB0728] font-bold text-white text-[12px] leading-[14.56px] rounded-full">
                1
              </div>
              {/**Line */}
              <div className=" w-full max-w-[180px] border-dashed border-t-[1px] border-t-[#AAAAAA]" />
              {/**Number 2 */}
              <div className=" w-[25px] h-[25px] flex items-center justify-center text-center bg-[#D9D9D9] font-bold text-[#707070] text-[12px] leading-[14.56px] rounded-full">
                2
              </div>
            </div>

            <div className=" w-full flex flex-col gap-[33px] mt-[55px]">
              {/**Erro Message */}
              {errorMessage !== "" ? (
                <div className=" w-full flex gap-[8px] items-center bg-[#FFDFE4] rounded-[20px] border-solid border-[1px] border-[#EB0728] px-[12px] py-[3px]">
                  <img src={errorIcon} alt="" className=" w-[18px] h-[18px]" />
                  <p className=" font-normal text-[#EB0728] text-[12px] leading-[14.56px]">
                    {errorMessage}
                  </p>
                </div>
              ) : null}

              <div className=" w-full flex flex-col gap-[63px]">
                {marketLinks.map((market, index) => {
                  return (
                    <MarketDirectorySelector
                      key={index}
                      options={marketPlaces}
                      placeholder="Choose marketplace"
                      id={index}
                      setMarketLinks={setMarketLinks}
                      marketLinks={marketLinks}
                      market={market}
                    />
                  );
                })}
              </div>
            </div>

            {/**Adding another link */}
            <button
              type="button"
              onClick={() => addMarketLink()}
              className=" w-fit flex items-center justify-center gap-[7px] mt-[54px] mb-[30px]"
            >
              <div className=" w-[22px] h-[22px] flex items-center justify-center text-center rounded-full border-solid border-[1.5px] border-[#C1C1C1]">
                <FaPlus className=" w-[11.67px] h-[11.67px] text-[#C1C1C1]" />
              </div>
              <p className=" font-normal text-[14px] text-[#AAAAAA] leading-[16.98px]">
                Add another link?
              </p>
            </button>

            {/**Create order button */}
            <button
              onClick={() => handleCreateOrder()}
              disabled={disabled}
              className=" disabled:bg-[#F4C5CC] disabled:cursor-not-allowed mt-auto w-full h-[40px] min-h-[40px] rounded-[80px] bg-[#EB0728] flex items-center justify-center text-center text-[#FFFFFF] text-[16px] leading-[19.41px]"
            >
              Create order
            </button>

            {/**Scroll to the bottom when a link is added */}
            <div ref={scrollRef}></div>
          </div>
        </div>
      )}
    </>
  );
};

export default MarketPlaceOrder;
