import React, { useEffect, useState } from 'react'
import NavBar from '../../components/Nav/NavBar'
import LogInModal from '../../components/LogIn/LogInModal'
import SignUpModal from '../../components/SignUp/SignUpModal'
import { useSelector } from 'react-redux'
import Footer from '../../components/Footer/Footer'
import CustomerNav from '../../components/Nav/CustomerNav'
import privacy from './assets/privacy.svg'

const PrivacyPolicy = () => {
    const [validUser, setValidUser] = useState(false)
    const [userID, setUserID] = useState()
    const [username, setUsername] = useState("")
    const [access_token, setAccessToken] = useState()
    const [refresh_token, setRefreshToken] = useState()
    const [isOpenDropDown, setIsOpenDropDown] = useState(false);
    const [swithStatus, setSwitchStatus] = useState(false)
    const logInModal = useSelector((state) => state.logInModal.value)
    const signUpModal = useSelector((state) => state.signUpModal.value)


    // Decode the data after retrieving it
    function decodeData(encodedData) {
        return atob(encodedData); // Base64 decoding
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Smooth scrolling animation
        });
    };

    useEffect(() => {
        scrollToTop()
        const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
        if (some_data) {
            setValidUser(true)
            const current_Data = decodeData(some_data)
            const parsedData = JSON.parse(current_Data)
            setUsername(parsedData?.first_name)
            setAccessToken(parsedData?.access_token)
            setRefreshToken(parsedData?.refresh_token)
            setUserID(parsedData?.id)
        }
    }, [])
    return (
        <div className='w-full'>
            {validUser ? <CustomerNav component='privacy' userID={userID} refresh_token={refresh_token} access_token={access_token} swithStatus={swithStatus} setSwitchStatus={setSwitchStatus} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} /> : <NavBar />}

            {logInModal ?
                <LogInModal /> : null}
            {signUpModal ? <SignUpModal /> : null}
            <div className="bg-white pt-[6rem] sm:pt-[146px] px-[2rem] sm:px-[4.375rem] md:px-[5rem] ">
                <div className=" max-w-5xl ">
                    <div className='flex gap-4 items-center'>
                        <img src={privacy} alt="" className=' w-[70px] h-[70px] xm:w-[100px] xm:h-[100px] sm:w-[189px] sm:h-[179px]' />
                        <span className=' flex flex-col sm:gap-[9px]'>
                            <h1 className=" text-[18px] xm:text-[25px] sm:text-[50px] font-bold sm:leading-[60.65px]">Privacy Policy</h1>
                            <p className=" text-[12px] xm:text-[14px] sm:text-[16px] leading-[19.41px]">Last updated 11th December, 2023</p>
                        </span>
                    </div>

                    <div className='flex my-8'>
                        <h1 className='text-[12px] xm:text-[14px] sm:text-[16px] leading-[19.41px]'>Goodifly ("we", "us", or "our") is committed to protecting the privacy of our users. This Privacy Policy outlines how we collect,
                            use, store, and disclose personal information and payment details provided by users of our services.</h1>
                    </div>

                    <div className=" flex flex-col gap-[40px] text-[12px] xm:text-[14px] sm:text-[16px] leading-[19.41px] ">
                        <section>
                            <h2 className=" text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Information Collection</h2>
                            <p className="mb-3">a. Personal Information: When you use our services, we may collect personal information such as name, address, email, phone number, and any other details necessary for the provision of our services.</p>
                            <p>b. Payment Information: We collect payment information such as credit card details or other financial information necessary to process transactions for our services.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Use of Information</h2>
                            <p className="mb-3">a. Personal Information: We use personal information to provide and improve our services, communicate with users, process transactions, and personalize user experience.</p>
                            <p>b. Payment Information: Payment details are solely used for processing payments and are securely handled through encrypted systems.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Information Sharing</h2>
                            <p className="mb-3">a. Third Parties: We may share personal information with third-party service providers who assist us in operating our business or providing services to users. However, we do not sell, trade, or rent personal information to others.</p>
                            <p>b. Legal Compliance: We may disclose personal information if required by law or in response to lawful requests by public authorities.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Data Security</h2>
                            <p>We implement appropriate security measures to protect personal information and payment details from unauthorized access, alteration, disclosure, or destruction.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">User Rights</h2>
                            <p>Users have the right to access, update, or delete their personal information. They can do so by contacting us through the provided channels.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Changes to the Privacy Policy</h2>
                            <p>We reserve the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated policy on our website.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Contact Us</h2>
                            <p>If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at contactus@goodify.com.</p>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
