import React, { useEffect, useState } from 'react'
import baseUrl from '../../components/baseUrl.js'
import CarrierNav from '../../components/Nav/CarrierNav'
import Footer from '../../components/Footer/Footer';
import profilePic from './assets/profile.jpg'
import settingIcon from './assets/setting.svg'
import locationIcon from './assets/location.svg'
import { useDispatch, useSelector } from 'react-redux'
import ListFlightModal from '../../components/Flight/ListFlightModal'
import { ListFlightTrue } from '../../features/flight/listFlightSlice'
import EditFlightModal from '../../components/Flight/EditFlightModal'
import { EditFlightTrue } from '../../features/flight/editFlightSlice'
import DeleteFlightModal from '../../components/Flight/DeleteFlightModal'
import { DeleteFlightTrue } from '../../features/flight/deleteFlightSlice'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import editIcon from './assets/edit.svg'
import deleteIcon from './assets/delete.svg'
import SuccessEditFlightModal from '../../components/Flight/SuccessEditFlightModal'
import SuccessDeleteFlightModal from '../../components/Flight/SuccessDeleteFlightModal'
import AcceptedRequest from '../../components/CarrierDashboard/AcceptedRequest'
import ViewAcceptedRequest from '../../components/CarrierDashboard/ViewAcceptedRequest'
import ConfirmItemDeliveredModal from '../../components/CarrierDashboard/ConfirmItemDelivered/ConfirmItemDeliveredModal'
import NewRequest from '../../components/CarrierDashboard/NewRequest/NewRequest'
import CongratulationsModal from '../../components/CarrierDashboard/NewRequest/CongratulationsModal'
import ConfirmDeclineRequestModal from '../../components/CarrierDashboard/NewRequest/ConfirmDeclineRequest/ConfirmDeclineRequestModal.jsx'
import FlightListSuccessModal from '../../components/Flight/CreationSuccess/FlightListSuccessModal.jsx'
import PageLoading from './PageLoading';
import History from '../../components/CarrierDashboard/History.jsx';
import { sessionTrue } from '../../features/pages/sessionSlice';
import ListFlightAfterDeclineModal from '../../components/CarrierDashboard/NewRequest/ListFlightAfterDecline/ListFlightAfterDeclineModal.jsx';
import ViewHistoryModal from '../../components/CarrierDashboard/ViewHistory/ViewHistoryModal.jsx';



const history_request = [
  { departure_city: "Lagos", arrival_city: "Accra", sender_name: "John Jilima", departure_time: "9:30am", arrival_time: "11:20am", departure_date: "01/01/24", arrival_date: "01/01/24", status: "Upcoming", item_desc: "Apple MacBook", item_weight: "23", item_type: "Electronics", earning: "$200", payment_status: "Unpaid" },
  { departure_city: "Port Harcourt ", arrival_city: "Accra", sender_name: "Joseph Kwentsir", departure_time: "11:00am", arrival_time: "12:00pm", departure_date: "21/01/24", arrival_date: "21/01/24", status: "Active", item_desc: "Casio Watch", item_weight: "4", item_type: "Electronics", earning: "$200", payment_status: "Paid" },

]

const CarrierDashboard = ({ airports }) => {
  const flight_data = {}
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [switchStatus, setSwitchStatus] = useState(0)
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  //listFlightModal
  const listFlightModal = useSelector((state) => state.listFlight.value)

  //flightListSuccessModal
  const flightListSuccessModal = useSelector((state) => state.flightListSuccess.value)

  // editFlightModal
  const editFlightModal = useSelector((state) => state.editFlight.value)
  // successEditFlightModal
  const successEditFlightModal = useSelector((state) => state.successEditFlight.value)

  // deleteFlightModal
  const deleteFlightModal = useSelector((state) => state.deleteFlight.value)
  // successDeleteFlightModal
  const successDeleteFlightModal = useSelector((state) => state.successDeleteFlight.value)

  const [carrierID, setCarrierID] = useState("")
  const [username, setUsername] = useState("")
  const [userCity, setUserCity] = useState("")
  const [userCountry, setUserCountry] = useState("")
  const [flights, setFlights] = useState()
  const [access_token, setAccessToken] = useState()
  const [refresh_token, setRefreshToken] = useState()
  //new request
  const [newRequest, setNewRequest] = useState([]);
  //accepted request
  const [acceptedRequest, setAcceptedRequest] = useState([])
  //History
  const [history, setHistory] = useState([])
  const [viewHistory, setViewHistory] = useState(null)

  const [flightToEdit, setFlightToEdit] = useState()
  const [flightToDelete, setFlightToDelete] = useState()
  const [viewAcceptedRequest, setViewAcceptedRequest] = useState(null)
  const [confirmItemDelivered, setConfirmItemDelivered] = useState(null)
  const [congratulations, setCongratulations] = useState(null)
  const [confirmDeclineRequest, setConfirmDeclineRequest] = useState(null)
  const [listFlightAfterDecline, setListFlightAfterDecline] = useState(null)

  const viewDetails = (request) => {
    setViewAcceptedRequest(request)
  }
  // Encode the data before storing it
  // function encodeData(data) {
  //   return btoa(data); // Base64 encoding
  // }
  // Decode the data after retrieving it
  function decodeData(encodedData) {
    return atob(encodedData); // Base64 decoding
  }

  const convertTo12HourFormat = (input) => {
    const inputTime = input;
    const [hours, minutes, seconds] = inputTime.split(':');
    let period = 'AM';
    let twelveHourFormatHours = parseInt(hours, 10);

    if (twelveHourFormatHours > 12) {
      twelveHourFormatHours -= 12;
      period = 'PM';
    } else if (twelveHourFormatHours === 0) {
      twelveHourFormatHours = 12;
    }

    const convertedTime = `${twelveHourFormatHours}:${minutes} ${period}`;
    return convertedTime
  };

  const getDayOfWeek = (input) => {
    const inputDate = new Date(input);
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    return dayOfWeek
  };

  const getDayOfMonth = (dateString) => {
    const dateObject = new Date(dateString);
    const dayOfMonth = dateObject.getDate();
    return dayOfMonth;
  };
  const getMonthOfTheYear = (dateString) => {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Parse the input date string into a Date object
    const dateObject = new Date(dateString);

    // Check if the date is valid
    if (!isNaN(dateObject.getTime())) {
      // Get the month (0-indexed) and use it as an index to get the month name
      const monthIndex = dateObject.getMonth();
      const monthName = monthNames[monthIndex];
      return monthName;
    } else {
      // Handle invalid date strings
      return 'Invalid Date';
    }
  };

  const getYearOfTheDate = (dateString) => {
    // Parse the input date string into a Date object
    const dateObject = new Date(dateString);

    // Check if the date is valid
    if (!isNaN(dateObject.getTime())) {
      // Get the year from the date object
      const year = dateObject.getFullYear();
      return year;
    } else {
      // Handle invalid date strings
      return 'Invalid Date';
    }
  };


  const EditAFlight = (flightToEdit) => {
    setFlightToEdit(flightToEdit)
    dispatch(EditFlightTrue())

  }


  const DeleteAFlight = (flightToDelete) => {
    setFlightToDelete(flightToDelete)
    dispatch(DeleteFlightTrue())

  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Smooth scrolling animation
    });
  };



  //Fetching Bookings
  const fetchBookings = async (token, id) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/booking/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: {}
    };

    await axios.request(config).then((response) => {
      const flightData = JSON.parse(window.localStorage.getItem('jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuykjfduybfuebyryeu'))
      const theFlight = flightData?.filter((flight) => flight.carrier === id && flight?.booking_status === true)

      if (theFlight?.length > 0) {
        const payment_successful = response?.data?.filter(item=> item?.payment_status==="Success")
        const res = payment_successful
          ?.filter(d => theFlight.some(flight => d.flight_book === flight.id))
          .map(d => {
            // Find the matching flight
            const matchingFlight = theFlight.find(flight => d.flight_book === flight.id);
            // Extract the 'id' from the matchingFlight and rename it, then spread the rest of its properties
            const { id: FlightId, ...restOfMatchingFlight } = matchingFlight;
            // Return a new object that merges 'd' with the rest of the matching flight
            // and includes the renamed 'FlightId'
            return {
              ...d, // Keep the 'id' from 'd'
              FlightId, // Include the 'id' from the matching flight, but renamed
              ...restOfMatchingFlight // Spread the rest of the properties of the matching flight
            };
          });
        setNewRequest(res?.filter(item => item.carrier_approval === false && item.sender_acceptance_status === 1 && item.status === "Pending"))
        setAcceptedRequest(res?.filter(item => item.sender_approval === false && (item.status === "Active" || item.status === "Upcoming")))
        // setHistory(res?.filter(item => item.carrier_approval === true || item.sender_approval === true))
        setHistory(res?.filter(item => item.sender_approval === true))
      }

    }).catch((error) => {
      if (error?.response?.data?.messages) {
        if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
          dispatch(sessionTrue())

        }
      }
      else {
        console.log(error)
        
      }
    })

  }

  useEffect(() => {
    scrollToTop()
    const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
    if (some_data) {
      let flight_config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/flight/`,
        headers: {
          'Content-Type': 'application/json',

        },

      };

      axios.request(flight_config).then((response) => {
        window.localStorage.setItem('jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuykjfduybfuebyryeu', JSON.stringify(response?.data))
        setFlights(response?.data)
      }).catch((error) => console.log("FLIGHT_ERROR: ", error))


      const current_Data = decodeData(some_data)
      const parsedData = JSON.parse(current_Data)
      setCarrierID(parsedData?.id)
      setUsername(parsedData?.full_name)
      setUserCity(parsedData?.city)
      setUserCountry(parsedData?.country)
      setAccessToken(parsedData?.access_token)
      setRefreshToken(parsedData?.refresh_token)
      fetchBookings(parsedData?.access_token, parsedData?.id)
    }
    else {
      window.location.href = '/'
    }

    if (localStorage.getItem('reloadForSwitchStatus') === 'true') {
      setSwitchStatus(1);
      localStorage.removeItem('reloadForSwitchStatus'); // Clean up
    }
  }, []);


  {/**Auto Refresh */ }
  useEffect(() => {

    const handleMouseOver = () => {
      const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
      if (some_data) {
        let i = 1
        while( i <= 3){
        let flight_config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/flight/`,
          headers: {
            'Content-Type': 'application/json',

          },

        };

        axios.request(flight_config).then((response) => {
          window.localStorage.setItem('jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuykjfduybfuebyryeu', JSON.stringify(response?.data))
          setFlights(response?.data)
        }).catch((error) => console.log("FLIGHT_ERROR: ", error))


        const current_Data = decodeData(some_data)
        const parsedData = JSON.parse(current_Data)
        setCarrierID(parsedData?.id)
        setUsername(parsedData?.full_name)
        setUserCity(parsedData?.city)
        setUserCountry(parsedData?.country)
        setAccessToken(parsedData?.access_token)
        setRefreshToken(parsedData?.refresh_token)
        fetchBookings(parsedData?.access_token, parsedData?.id)
        i+=1
      }
      }
    };

    document.addEventListener('mouseover', handleMouseOver);

    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
    };
  }, [flights, newRequest, acceptedRequest, history]);


  flights?.filter(flight => flight.booking_status === false)?.map((flight) => {

    const departure_airport = airports?.filter(airport => airport.id === flight.departure_airport);
    const arrival_airport = airports?.filter(airport => airport.id === flight.arrival_airport);

    let temp = {
      id: flight.id,
      carrier: flight.carrier,
      departureAirport: departure_airport?.map(c => c.name),
      arrivalAirport: arrival_airport?.map(c => c.name),
      departureAirportID: departure_airport?.map(c => c.id),
      arrivalAirportID: arrival_airport?.map(c => c.id),
      departure_date: flight.departure_date,
      arrival_date: flight.arrival_date,
      departure_time: flight.departure_time,
      arrival_time: flight.arrival_time,
      terminal: flight.terminal,
      minimum_space: flight.minimum_space,
      maximum_space: flight.maximum_space,
      fromCity: departure_airport?.map(c => c.city),
      toCity: arrival_airport?.map(c => c.city),
      fromTime: convertTo12HourFormat(flight.departure_time),
      toTime: convertTo12HourFormat(flight.arrival_time),
      space: flight.maximum_space, dayOfWeek: getDayOfWeek(flight.departure_date),
      dayOfMonth: getDayOfMonth(flight.departure_date)

    }
    let month = getMonthOfTheYear(flight.departure_date)
    let year = getYearOfTheDate(flight.departure_date)
    // if (flight.carrier === carrierID) {
    //   if (flight_data.hasOwnProperty(month)) {
    //     flight_data[month + ", " + year].push(temp)
    //   }
    //   else {
    //     flight_data[month + ", " + year] = [temp]
    //   }
    // }
    if (flight.carrier === carrierID) {
      const key = month + ", " + year;

      if (flight_data.hasOwnProperty(key)) {
        flight_data[key].push(temp);
      } else {
        flight_data[key] = [temp];
      }
    }
  })


  if (flights) {
    return (
      <div className="w-full">
        <CarrierNav
          access_token={access_token}
          isOpenDropDown={isOpenDropDown}
          setIsOpenDropDown={setIsOpenDropDown}
        />
        {listFlightModal ? (
          <ListFlightModal
            access_token={access_token}
            carrierID={carrierID}
            airports={airports}
          />
        ) : null}
        {flightListSuccessModal ? <FlightListSuccessModal /> : null}
        {editFlightModal && flightToEdit ? (
          <EditFlightModal
            access_token={access_token}
            flight={flightToEdit}
            airports={airports}
          />
        ) : null}
        {successEditFlightModal ? <SuccessEditFlightModal /> : null}
        {deleteFlightModal && flightToDelete ? (
          <DeleteFlightModal
            access_token={access_token}
            flight={flightToDelete}
          />
        ) : null}
        {successDeleteFlightModal ? <SuccessDeleteFlightModal /> : null}
        {viewHistory ? (
          <ViewHistoryModal
            viewHistory={viewHistory}
            setViewHistory={setViewHistory}
          />
        ) : null}
        <div className=" flex flex-col w-full pt-[6rem] sm:pt-[146px] px-[2rem] md:px-[5rem] ">
          {/**Profile */}
          <div className="flex items-center">
            {/** image */}
            <div className=" flex flex-col w-95.09px">
              <img
                src={profilePic}
                alt=""
                className=" w-[95.09px] h-[95.09px] rounded-[95.09px]"
              />
              <div className=" w-full flex flex-row-reverse">
                <img
                  src={settingIcon}
                  alt="logo"
                  className=" -mt-8 mr-1 w-[38.67px] h-[38.67px]"
                />
              </div>
            </div>
            {/**Name */}
            <div className=" ml-[17px]">
              <h1 className=" text-[20px] font-bold">{username}</h1>
              <div className="flex mt-[5px]">
                <img src={locationIcon} alt="" />
                <h1 className=" text-[16px] text-[#707070] ml-[3px]">
                  {userCity}, {userCountry}
                </h1>
              </div>
            </div>
          </div>

          {/**Flights */}
          <div className="flex flex-col-reverse xl:flex-row w-full gap-[34px]">
            {/**Listed Flights */}
            <div className=" w-full  xl:flex-1 flex flex-col  mt-[46.87px]">
              {/**Title */}
              <div className=" lg:mt-0 flex justify-start w-full items-end">
                <h1 className=" text-[18px] sm:text-[25px] font-bold">
                  Listed Flights
                </h1>
              </div>
              <div className=" flex flex-col mt-[18px] w-full h-[563px] overflow-y-auto bg-[#FFFFFF] border-solid border-[1px] border-[#C1C1C180] shadow-switchBar rounded-[30px] px-4 xm:px-8 py-[26px] gap-[98px]">
                {Object.keys(flight_data)?.length > 0 ? (
                  Object.keys(flight_data)?.map((key) => (
                    <div key={key}>
                      <h1 className="text-[18px] sm:text-[25px] font-bold">
                        {key}
                      </h1>
                      <div className="flex flex-col mt-[25px] gap-[30px]">
                        {/**flights info */}
                        {flight_data[key]?.map((flight) => {
                          return (
                            <div
                              key={flight.id}
                              className="flex gap-2 xm:gap-3 items-center justify-between"
                            >
                              {/**details */}
                              <div className=" flex gap-3 xm:gap-[23px] xl:gap-[33px] items-center">
                                {/**date */}
                                <div className="flex flex-col text-center w-[58px] max-w-[58px]">
                                  <h1 className=" text-[25px] sm:text-[30px] font-bold mb-[-10px]">
                                    {flight.dayOfMonth}
                                  </h1>
                                  <h1 className=" text-[12px] sm:text-[14px] text-[#707070]">
                                    {flight.dayOfWeek}
                                  </h1>
                                </div>

                                <div className="flex flex-col mb-[-6px]">
                                  <h1 className=" text-[16px] sm:text-[18px] font-bold">
                                    {flight.fromCity} - {flight.toCity}
                                  </h1>
                                  <div className="flex flex-wrap gap-2 xl:gap-[18px]">
                                    {/**time */}
                                    <h1 className="text-[#707070] text-[12px] sm:text-[14px]">
                                      {flight.fromTime} - {flight.toTime}
                                    </h1>
                                    {/**space */}
                                    <h1 className=" text-[#707070] text-[12px] sm:text-[14px]">
                                      {flight.space}kg
                                    </h1>
                                  </div>
                                </div>
                              </div>

                              {/**Buttons */}
                              <div className="flex gap-3 xm:gap-[19px]">
                                <img
                                  onClick={() => EditAFlight(flight)}
                                  className=" w-[18px] h-[18px] sm:w-[22.5px]sm:h-[22.5px] cursor-pointer"
                                  src={editIcon}
                                  alt=""
                                />
                                <img
                                  onClick={() => DeleteAFlight(flight)}
                                  className=" w-[16px] h-[18px] sm:w-[20px] sm:h-[22.5px] cursor-pointer"
                                  src={deleteIcon}
                                  alt=""
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className=" w-full h-full flex flex-col text-center items-center justify-center gap-4">
                    <h1 className=" font-bold text-[25px]">
                      No listed flights
                    </h1>
                    <h1 className=" text-[#707070] text-[18px]">
                      There are no listed flights. Take the first step by
                      clicking the button below to list a flight{" "}
                    </h1>

                    <button
                      onClick={() => dispatch(ListFlightTrue())}
                      className=" text-white flex text-center items-center justify-center w-[171px] h-[45px] bg-[#EB0728] rounded-[30px]"
                    >
                      List Flight
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/**New */}
            <div className=" w-full  xl:flex-[1.5] flex flex-col mt-[13.87px]">
              {/** switch */}
              <div className="  whitespace-nowrap flex cursor-pointer w-full  items-center justify-center xl:justify-start xl:items-start">
                <div
                  className={`flex-wrap xs:flex-nowrap justify-center  ${
                    switchStatus !== 2 ? " pr-5 xm:pr-[34px] xl:pr-[34px]" : ""
                  } ${
                    switchStatus !== 0 ? " pl-5 xm:pl-[43px] xl:pl-[43px]" : ""
                  } bg-[#FFFFFF] border-solid border-[1px] border-[#C1C1C1] shadow-switchBar w-full xm:w-fit xm:h-[65px] rounded-[100px] p-[10px] flex xs:justify-between xm:justify-start gap-2 xm:gap-4 xl:gap-[37px] items-center`}
                >
                  <div
                    onClick={() => setSwitchStatus(0)}
                    className={` ${
                      switchStatus === 0
                        ? "bg-[#EB0728] px-[10px] xs:px-[20px] xm:px-0  xl:px-0 xm:w-[180px] xl:w-[180px] h-[30px]  xm:h-[45px] rounded-[30px] text-white flex items-center justify-center text-center"
                        : ""
                    } text-[10px] xm:text-[14px] sm:text-[18px] font-bold  xl:font-bold`}
                  >
                    NEW ( {newRequest.length} )
                  </div>
                  <div
                    onClick={() => setSwitchStatus(1)}
                    className={` ${
                      switchStatus === 1
                        ? "bg-[#EB0728] px-[10px] xs:px-[20px] xm:px-0  xl:px-0 xm:w-[180px] xl:w-[180px] h-[30px]  xm:h-[45px] rounded-[30px] text-white flex items-center justify-center text-center"
                        : " "
                    } text-[10px] xm:text-[14px] sm:text-[18px] font-bold  xl:font-bold`}
                  >
                    ACCEPTED ( {acceptedRequest.length} )
                  </div>
                  <div
                    onClick={() => setSwitchStatus(2)}
                    className={` ${
                      switchStatus === 2
                        ? "bg-[#EB0728] px-[10px] xs:px-[20px] xm:px-0  xl:px-0 xm:w-[180px] xl:w-[180px] h-[30px]  xm:h-[45px] rounded-[30px] text-white flex items-center justify-center text-center"
                        : " "
                    } text-[10px] xm:text-[14px] sm:text-[18px] font-bold  xl:font-bold`}
                  >
                    HISTORY ( {history.length} )
                  </div>
                </div>
              </div>
              {switchStatus === 0 ? (
                newRequest.length > 0 ? (
                  <>
                    <NewRequest
                      access_token={access_token}
                      airports={airports}
                      newRequest={newRequest}
                      setCongratulations={setCongratulations}
                      setConfirmDeclineRequest={setConfirmDeclineRequest}
                    />
                    {congratulations ? (
                      <CongratulationsModal
                        newRequest={newRequest}
                        setCongratulations={setCongratulations}
                        congratulations={congratulations}
                        setSwitchStatus={setSwitchStatus}
                      />
                    ) : null}
                    {confirmDeclineRequest ? (
                      <ConfirmDeclineRequestModal
                        newRequest={newRequest}
                        confirmDeclineRequest={confirmDeclineRequest}
                        setConfirmDeclineRequest={setConfirmDeclineRequest}
                        setListFlightAfterDecline={setListFlightAfterDecline}
                      />
                    ) : null}
                    {listFlightAfterDecline ? (
                      <ListFlightAfterDeclineModal
                        setListFlightAfterDecline={setListFlightAfterDecline}
                        listFlightAfterDecline={listFlightAfterDecline}
                        access_token={access_token}
                      />
                    ) : null}
                  </>
                ) : (
                  <div className=" flex flex-col text-center items-center justify-center mt-4 w-full h-[563px] bg-[#FFFFFF] border-solid border-[1px] border-[#C1C1C180] shadow-switchBar rounded-[30px] py-[30px] px-[40px]">
                    <h1 className=" text-[25px] font-bold">No new request</h1>
                    <h1 className=" mt-[11px] text-[18px] text-[#707070]">
                      No new booking request have been received. Sit tight,
                      requests will appear when made{" "}
                    </h1>
                  </div>
                )
              ) : switchStatus === 1 ? (
                <div className=" overflow-scroll gap-[29px] flex flex-col items-center text-center mt-4 w-full h-[563px] bg-[#FFFFFF] border-solid border-[1px] border-[#C1C1C180] shadow-switchBar rounded-[30px] py-4 xm:py-[35px] px-4 xm:px-[35px] lg:px-[20px] xl:px-[35px]">
                  {acceptedRequest?.length > 0 ? (
                    viewAcceptedRequest ? (
                      <ViewAcceptedRequest
                        viewAcceptedRequest={viewAcceptedRequest}
                        setViewAcceptedRequest={setViewAcceptedRequest}
                        setConfirmItemDelivered={setConfirmItemDelivered}
                      />
                    ) : (
                      acceptedRequest.map((request) => {
                        return (
                          <AcceptedRequest
                            airports={airports}
                            request={request}
                            viewDetails={viewDetails}
                          />
                        );
                      })
                    )
                  ) : (
                    <div className=" w-full h-full flex flex-col items-center justify-center text-center">
                      <h1 className=" font-bold text-[25px]">
                        No Accepted Request
                      </h1>
                      <h1 className=" text-[18px] text-[#707070] mt-[11px]">
                        There are currently no accepted bookings. Check back
                        later to view your accepted bookings
                      </h1>
                    </div>
                  )}
                  {confirmItemDelivered ? (
                    <ConfirmItemDeliveredModal
                      access_token={access_token}
                      item={confirmItemDelivered}
                      setConfirmItemDelivered={setConfirmItemDelivered}
                      setViewAcceptedRequest={setViewAcceptedRequest}
                    />
                  ) : null}
                </div>
              ) : (
                <div className="overflow-scroll mt-4 w-full h-[563px]  flex flex-col gap-[20px] bg-[#FFFFFF] border-solid border-[1px] border-[#C1C1C180] shadow-switchBar rounded-[20px] px-4 xm:px-[35px] py-[30px]">
                  {history?.length > 0 ? (
                    history.map((request) => {
                      return (
                        <History
                          airports={airports}
                          request={request}
                          setViewHistory={setViewHistory}
                        />
                      );
                    })
                  ) : (
                    <div className=" w-full h-full flex flex-col items-center justify-center text-center">
                      <h1 className=" font-bold text-[25px]">
                        No Completed Request
                      </h1>
                      <h1 className=" text-[18px] text-[#707070] mt-[11px]">
                        Your history page is empty. As you complete your
                        bookings, they will appear here.
                      </h1>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {/**Footer */}
        <Footer />
      </div>
    );
  }
  else {
    return (
      <div className=' w-full h-full'>
        <PageLoading dashboard="carrier" access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} />
      </div>
    )
  }
}

export default CarrierDashboard


