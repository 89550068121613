import React, { useState, useEffect, useRef } from 'react';
import baseUrl from '../baseUrl'
import axios from 'axios'
import { useDispatch } from 'react-redux';
import { sessionTrue } from '../../features/pages/sessionSlice';
import fly from './assets/fly.svg'
import { convertTo12HourFormat, formatDate } from '../ConvertDateAndTime'
import PriceCalculator from '../priceCalculator'


const RequestedFlight = ({access_token, flightData, flight, setConfirmRequestCancel }) => {
    const dispatch = useDispatch()
    const intervalRef = useRef();


    const theFlight = flightData?.filter((f) => f.id === flight.flight_book)
    //disable
    const [disabled, setDisabled] = useState(false);

      // Parse the payment date string into a JavaScript Date object
      const paymentDate = new Date(flight.payment_date);
      
      // Add 5 minutes to the payment date
      const fiveMinutesLater = new Date(paymentDate.getTime() + 5 * 60 * 1000);

    const fiveMinuteCountDown = () => {
        // Check if current time is past 5 minutes from the payment date
        const isDisabled = Date.now() > fiveMinutesLater.getTime();
      
        // Update the disabled state based on the check
        setDisabled(isDisabled);
      }
      
      const handleDecline = (flight) =>{
        if(disabled===false){
        setConfirmRequestCancel(flight)
        }
      }


      const handleCountDownDecline = (item) => {
        const data = JSON.stringify({
            flight_book: item.flight_book,
            item_description: item.item_description,
            item_fee: item.item_fee,
            item_weight: item.item_weight,
            sender: item.sender,
            carrier_acceptance_status: 2,
        })
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${baseUrl}/booking/${item.id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                if (response?.data?.id) {
                    // setCongratulations(item)
                }
            }).catch((error) => {
                if (error?.response?.data?.messages) {
                    if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                        dispatch(sessionTrue())

                    }
                }
                console.log(error)
            })
    }

      // Call the function on component mount (or when the payment date changes)

      useEffect(() => {
        fiveMinuteCountDown();
        const updateRemainingTime = () => {
            const timeLeft = calculateTimeRemaining(flight?.payment_date);

            if (timeLeft === "00:00:00") {
                handleCountDownDecline(flight);
            }
        };

        intervalRef.current = setInterval(updateRemainingTime, 1000);

        // Initial call to set the time without delay
        updateRemainingTime();

        return () => clearInterval(intervalRef.current);
    }, [flight?.payment_date, flight, fiveMinutesLater, handleCountDownDecline]);

    const calculateTimeRemaining = () => {
        const createdDate = new Date(flight?.payment_date);
        const currentDate = new Date();
        const oneHourLater = new Date(createdDate.getTime() + 3600 * 1000);

        if (currentDate >= oneHourLater) {
            return "00:00:00";
        }

        const secondsRemaining = Math.floor((oneHourLater - currentDate) / 1000);
        const hours = Math.floor(secondsRemaining / 3600);
        const minutes = Math.floor((secondsRemaining % 3600) / 60);
        const seconds = secondsRemaining % 60;

        const paddedHours = String(hours).padStart(2, '0');
        const paddedMinutes = String(minutes).padStart(2, '0');
        const paddedSeconds = String(seconds).padStart(2, '0');

        return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    };


    if (theFlight?.length > 0) {
        return (
            <div className='  flex flex-col w-full max-w-[350px] rounded-[30px] border-solid border-[1px] border-[#C1C1C180] shadow-adminLogIn bg-[#FFFFFF]'>
                <div className=' p-[16px] sm:p-[20px] lg:p-[16px] flex flex-col w-full items-center'>
                    <div className=' w-full flex justify-between'>
                        <div className=' w-full text-left items-start justify-start flex flex-col gap-[1px]'>
                            <h1 className='font-bold text-[14px] leading-[16.98px]'>{theFlight[0]?.username}</h1>
                            <h1 className=' flex gap-[9px] text-[10px] leading-[12.13px] text-[#AAAAAA]'>
                                {flight.is_electronic === true ? <span>Electronics</span> : null}
                                {flight.is_clothing === true ? <span>Clothing</span> : null}
                                <span>{flight.item_weight}kg</span>
                                <span>${PriceCalculator(flight.item_weight).finalPrice}</span>
                            </h1>
                        </div>

                        <div className={` ${flight.status === "Pending" ? "bg-[#E5E5E5] border-[#70707080] text-[#70707080]" : "bg-[#FFDFE4] border-[#EB0728] text-[#EB0728]"} w-[80px] h-[25px]  rounded-[50px] border-[1px] flex items-center justify-center text-center text-[12px] leading-[14.56px] font-bold`}>{flight.status}</div>
                    </div>

                    <div className={` ${flight.status === "Pending" ? "mt-4" : "mt-[22px] mb-[14px]"}  w-full flex gap-[10px] items-center justify-between`}>
                        {/**Departure Info */}
                        <div className=' text-[10px] leading-[12.13px] flex flex-col gap-[2px] items-center'>
                            <h1 className='  text-[#AAAAAA]'>{theFlight[0]?.from_city}</h1>
                            <h1 className=' font-bold text-[16px] leading-[19.41px]'>{theFlight[0]?.from_city_code}</h1>
                            <h1 className='  text-[#AAAAAA]'>{formatDate(flight?.departure_date)}</h1>
                            <h1 className='  text-[#AAAAAA]'>{convertTo12HourFormat(flight?.departure_time)}</h1>
                        </div>

                        <img src={fly} alt="fly" className=' w-[100px] lg:w-[80px]' />

                        {/**Arrival Info */}
                        <div className=' text-[10px] leading-[12.13px] flex flex-col gap-[2px] items-center'>
                            <h1 className=' text-[#AAAAAA]'>{theFlight[0]?.to_city}</h1>
                            <h1 className=' font-bold text-[16px] leading-[19.41px]'>{theFlight[0]?.to_city_code}</h1>
                            <h1 className=' text-[#AAAAAA]'>{formatDate(flight?.arrival_date)}</h1>
                            <h1 className=' text-[#AAAAAA]'>{convertTo12HourFormat(flight?.arrival_time)}</h1>
                        </div>
                    </div>

                    {/**Delete Button */}
                    {flight.status === "Pending" ? <button disabled={disabled} 
                        onClick={() => handleDecline(flight)} className={` disabled:opacity-30 mt-[22px] w-full h-[40px] flex items-center justify-center font-bold text-[16px] text-[#EB0728] leading-[19.41px] rounded-[15px] border-solid border-[1px] border-[#C1C1C180] `}>Cancel</button> : null}
                </div>

                {/**Item description */}
                {flight?.status !== "Pending" ?
                    <div className=' w-full flex flex-col gap-[9px] mb-[14px]'>
                        <hr className=' w-full h-[1px] bg-[#C1C1C180]' />
                        <div className='p-[16px] sm:p-[20px] lg:p-[16px] w-full flex flex-col gap-[3px]'>
                            <p className=' text-[10px] text-[#707070] leading-[12.13px]'>Item description</p>
                            <h1 className='font-bold text-[12px] leading-[14.56px]'>{flight?.item_description}</h1>
                        </div>
                    </div> : null
                }
            </div>
        )
    }
}

export default RequestedFlight
