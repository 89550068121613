import React, { useEffect, useState } from 'react'
import NavBar from '../../components/Nav/NavBar'
import LogInModal from '../../components/LogIn/LogInModal'
import SignUpModal from '../../components/SignUp/SignUpModal'
import { useSelector } from 'react-redux'
import Footer from '../../components/Footer/Footer'
import CustomerNav from '../../components/Nav/CustomerNav'
import headphone from './assets/headphone.svg'

const Terms = () => {
    const [validUser, setValidUser] = useState(false)
    const [userID, setUserID] = useState()
    const [username, setUsername] = useState("")
    const [access_token, setAccessToken] = useState()
    const [refresh_token, setRefreshToken] = useState()
    const [isOpenDropDown, setIsOpenDropDown] = useState(false);
    const [swithStatus, setSwitchStatus] = useState(false)
    const logInModal = useSelector((state) => state.logInModal.value)
    const signUpModal = useSelector((state) => state.signUpModal.value)


    // Decode the data after retrieving it
    function decodeData(encodedData) {
        return atob(encodedData); // Base64 decoding
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Smooth scrolling animation
        });
    };

    useEffect(() => {
        scrollToTop()
        const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
        if (some_data) {
            setValidUser(true)
            const current_Data = decodeData(some_data)
            const parsedData = JSON.parse(current_Data)
            setUsername(parsedData?.first_name)
            setAccessToken(parsedData?.access_token)
            setRefreshToken(parsedData?.refresh_token)
            setUserID(parsedData?.id)
        }
    }, [])
    return (
        <div className='w-full'>
            {validUser ? <CustomerNav component='privacy' userID={userID} refresh_token={refresh_token} access_token={access_token} swithStatus={swithStatus} setSwitchStatus={setSwitchStatus} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} /> : <NavBar />}

            {logInModal ?
                <LogInModal /> : null}
            {signUpModal ? <SignUpModal /> : null}
            <div className="bg-white pt-[6rem] sm:pt-[146px] px-[2rem] sm:px-[4.375rem] md:px-[5rem] ">
                <div className=" max-w-5xl ">
                    <div className='flex gap-4 items-center'>
                        <img src={headphone} alt="" className=' w-[70px] h-[70px] xm:w-[100px] xm:h-[100px] sm:w-[189px] sm:h-[179px]' />
                        <span className=' flex flex-col sm:gap-[9px]'>
                            <h1 className=" text-[18px] xm:text-[25px] sm:text-[50px] font-bold sm:leading-[60.65px]">Terms and Conditions</h1>
                            <p className=" text-[12px] xm:text-[14px] sm:text-[16px] leading-[19.41px]">Last updated 11th December, 2023</p>
                        </span>
                    </div>

                    <div className='flex my-8'>
                        <h1 className='text-[12px] xm:text-[14px] sm:text-[16px] leading-[19.41px]'>Welcome to Goodifly! These terms and conditions ("Terms") govern the use of our platform connecting senders and carriers for the
                            transportation of items. By utilizing our services, you agree to adhere to these Terms. If any part of these Terms is unacceptable to you,
                            please refrain from using our services.
                        </h1>
                    </div>

                    <div className=" flex flex-col gap-[40px] text-[12px] xm:text-[14px] sm:text-[16px] leading-[19.41px] ">
                        <section>
                            <h2 className=" text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Description of Services</h2>
                            <p>a. Platform Services: Goodifly provides a platform enabling the listing, booking, and transportation of items facilitated by carriers.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">User Responsibilities</h2>
                            <p className="mb-3">a. Accuracy of Information: Users are responsible for providing accurate and comprehensive information, including profiles, listings, and booking details.</p>
                            <p>b. Compliance: Users agree to adhere to all applicable laws, regulations, and the policies of our platform while using our services.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Booking and Payments</h2>
                            <p className="mb-3">a. Booking: Senders can book item transportation through our platform. Carriers accept bookings based on availability and agreed-upon terms.</p>
                            <p>b. Payments: Senders agree to pay the specified fees and charges for bookings. Carriers receive payments in line with our payment policies.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Carrier-Sender Interactions</h2>
                            <p className="mb-3">a. Communication: We encourage users to communicate through our platform for all booking-related communication, ensuring clarity and transparency. </p>
                            <p>b. Responsibilities: Carriers and senders are responsible for their conduct and agree to treat each other respectfully, abiding by our community guidelines.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Cancellations and Refund</h2>
                            <p className="mb-3">a. Cancellation Policy: Carriers may have specific cancellation policies, and users are expected to adhere to these policies.</p>
                            <p>b. Refunds: Refunds, if applicable, are subject to the cancellation policy and our stipulated refund procedures.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Liabilities and Disputes</h2>
                            <p className="mb-3">a. Limitation of Liability: Goodifly holds no liability for damages, losses, or disputes arising between carriers and senders using our platform.</p>
                            <p>b. Dispute Resolution: Users agree to resolve disputes directly with each other. While we may offer support or mediation, it's not an obligation on our part.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Intellectual Property</h2>
                            <p>a. Content Ownership: Users maintain ownership of their content (listings, reviews, etc.) uploaded to our platform. However,
                                users grant us a license to use, display, and distribute this content within our platform.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Termination</h2>
                            <p>a. Termination of Services: We retain the right to suspend or terminate accounts violating these Terms or engaging in
                                activities compromising our platform's integrity.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Amendments</h2>
                            <p>a. Changes to Terms: We may update or modify these Terms. Users will be informed of changes, and continued use of
                                our platform after such changes implies acceptance of the revised Terms.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Privacy</h2>
                            <p>a. Privacy Policy: Utilising our platform also subjects users to our Privacy Policy, outlining our collection, use, and
                                protection of user information.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Governing Law</h2>
                            <p>a. Jurisdiction: These Terms are governed by the laws of Ghana. Any legal disputes will be resolved in the
                                courts of Ghana.</p>
                        </section>

                        <section>
                            <h2 className="text-[16px] sm:text-[18px] leading-[21.83px] font-bold mb-3">Contact Us</h2>
                            <p>For inquiries or concerns about these Terms, please contact us at contactus@goodifly.com.</p>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Terms;

