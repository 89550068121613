import React from 'react'
import errorIcon from "./assets/error.svg";
import infoGray from "./assets/infoGray.svg";


const BookingFields = ({ packageDirection, flight, errorMessage, itemDescription, setItemDescription, setValue, deliveryName, setDeliveryName, deliveryNumber, setDeliveryNumber, toggleClothing, isClothing, toggleElectronics, isElectronics, value, maxValue }) => {
  const minimum = flight?.minimum_space < 1 ? maxValue : flight?.minimum_space
  const handleIncrement = () => {
    if (!value) {
      setValue(minimum)
    }
    if (parseInt(value) + 1 <= maxValue) {
      setValue(parseInt(value) + 1);
    }
    else {
      setValue(maxValue)
    }

  };

  const handleDecrement = () => {
    if (parseInt(value) > minimum) {
      setValue(parseInt(value) - 1);
    }
    else {
      setValue(minimum)
    }
  };

  const handleValueChange = (e) => {
    const newValue = parseFloat(e.target.value);
    if (!isNaN(newValue)) {
      if (newValue < minimum) {
        setValue(minimum)
      }
      else if (newValue > maxValue) {
        setValue(maxValue)
      }
      else {
        setValue(newValue);
      }
    } else if (e.target.value === "") {
      // Clear the input field
      setValue(minimum);
    }
  };

  return (
    <div className="w-full xl:max-w-[723px] rounded-[30px] border-solid border-[1px] border-[#C1C1C180] pt-[34px] sm:pt-[45px] pb-[46px] sm:pb-[91px] px-[20px] sm:px-[42px]">
      <div className='flex flex-wrap gap-[30px] xl:gap-4 justify-between'>
        {/**Item Type */}
        <div className='flex flex-col'>
          <h1 className=" text-[16px] sm:text-[18px] font-bold">Indicate item type</h1>
          <div className="w-full flex flex-col sm:flex-row items-baseline sm:gap-11">
            {/**Electronics */}
            <div className="w-full flex flex-col">
              <div className="items-center flex gap-[5px] mt-[14px]">
                <label className="cursor-not-allowed flex checkbox-container items-center justify-center text-center">
                  <input
                    type="checkbox"
                    name="agreement"
                    id="agreement"
                    onChange={toggleElectronics}
                    checked={isElectronics}
                    disabled={true}
                    className="opacity-10"
                  />
                  <span className={`checkmark1`}></span>
                </label>
                <h1 className=" text-[14px] sm:text-[16px] font-normal ml-[6px] mt-[-3px] cursor-not-allowed text-[#707070] opacity-50">
                  Electronics
                </h1>
              </div>
            </div>

            {/**Clothing */}
            <div className="items-center flex gap-[5px] mt-[14px]">
              <label className="cursor-pointer flex checkbox-container items-center justify-center text-center">
                <input
                  type="checkbox"
                  name="agreement"
                  id="agreement"
                  onChange={toggleClothing}
                  checked={isClothing}
                />
                <span
                  className={`checkmark ${errorMessage === "Item Type is Required!"
                    ? "border-[1px] border-[#EB0728]"
                    : ""
                    }`}
                ></span>
              </label>
              <h1
                onClick={toggleClothing}
                className=" text-[14px] sm:text-[16px] font-normal ml-[6px] mt-[-3px] cursor-pointer text-[#707070]"
              >
                Clothing
              </h1>
            </div>
          </div>

          {errorMessage === "Item Type is Required!" && (
            <h1 className="pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left">
              {errorMessage}
            </h1>
          )}

          {/**Not Available */}
          <div className=' mt-[21px] flex gap-1'>
            <img src={infoGray} alt="infoGray" className=' w-[14px] h-[14px]' />
            <p className=' text-[#AAAAAA] text-[12px] leading-[14.56px]'>Electronics are not available at the moment</p>
          </div>
        </div>

        {/**Indicate Item */}
        <div className='flex flex-col gap-[15px]'>
          <div className='flex flex-col gap-1'>
            <h1 className=" text-[16px] sm:text-[18px] font-bold">Indicate weight</h1>
            {minimum === maxValue ? <p className=' text-[12px] text-[#707070] leading-[14.56px]'>Maximum space available: <span className=' font-bold text-[#EB0728]'>{maxValue}kg</span></p> : <p className=' text-[12px] text-[#707070] leading-[14.56px]'>Minimum and maximum space required: <span className=' font-bold text-[#EB0728]'>{minimum}kg - {maxValue}kg</span></p>}
          </div>
          <div className=" flex flex-col">
            <div
              className={` ${!value || (parseFloat(value) > parseFloat(maxValue)) || (parseFloat(value) < parseFloat(minimum))
                ? " border-[1px] border-[#EB0728]"
                : ""
                } text-left flex items-center justify-center py-[9px] px-[10px] w-[107px] h-[40px] rounded-[30px] bg-[#E5E5E5] gap-[6px]`}
            >
              <button
                onClick={handleDecrement}
                type="button"
                disabled={parseFloat(value) <= parseFloat(minimum) ? true : false}
                className={` ${parseFloat(value) <= parseFloat(minimum)
                  ? " cursor-not-allowed text-gray-300 opacity-90"
                  : "text-black"
                  } text-[25px] leading-[30.33px]`}
              >
                -
              </button>
              <input
                type="number"
                name="weight"
                id="weight"
                value={value}
                onChange={handleValueChange}
                className=" text-center rounded-[5px] h-[30px] px-[7px] min-w-[35px] py-1 focus:outline-none bg-white text-[18px]"
                style={{ width: `${value?.toString().length + 7}ch` }}
              />
              <button
                onClick={handleIncrement}
                type="button"
                disabled={parseFloat(value) >= parseFloat(maxValue) ? true : false}
                className={` ${parseFloat(value) >= parseFloat(maxValue)
                  ? " cursor-not-allowed text-gray-300 opacity-90"
                  : "text-black"
                  } text-[25px] leading-[30.33px]`}
              >
                +
              </button>
            </div>
            {errorMessage === "Invalid Weight!!" ? (
              <h1 className=" pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left">
                {errorMessage}
              </h1>
            ) : null}
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col mt-[34px]">
        <h1 className="text-[16px] font-bold">Item description</h1>

        {/**Item Description */}
        <input
          placeholder="e.g. T-shirts, Ladies dresses, etc..."
          type="text"
          value={itemDescription}
          onChange={(e) => setItemDescription(e.target.value)}
          className={`${errorMessage === "Item Description is Required!" || errorMessage === "Must be at least 2 words!"
            ? "border-[1px] border-[#EB0728]"
            : ""
            } bg-[#E5E5E5] w-full max-w-[634px] h-[45px] rounded-[30px] mt-[12px] px-3 sm:px-[18px] flex items-center placeholder-[#AAAAAA] text-[14px]`}
        />
        {errorMessage === "Item Description is Required!" || errorMessage === "Must be at least 2 words!" ?
          <h1 className="pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left">
            {errorMessage}
          </h1> : null}
      </div>

      <div className="w-full flex flex-col mt-[31px]">
        <h1 className="text-[16px] font-bold">{packageDirection === "send" ? "Recipient's details" : "Sender's details"}</h1>

        {/**Delivery Name */}
        <input
          placeholder={packageDirection === "send" ? "Recipient name (optional)" : "Sender's name (optional)"}
          type="text"
          value={deliveryName}
          onChange={(e) => setDeliveryName(e.target.value)}
          className={`bg-[#E5E5E5] w-full max-w-[634px] h-[45px] rounded-[30px] mt-[12px] px-3 sm:px-[18px] flex items-center placeholder-[#AAAAAA] text-[14px]`}
        />

        {/**Delivery Number */}
        <input
          placeholder={packageDirection === "send" ? "Recipient phone number" : "Sender's phone number"}
          type="number"
          value={deliveryNumber}
          onChange={(e) => setDeliveryNumber(e.target.value)}
          className={`${errorMessage === "Phone Number is Required!" || errorMessage === "Invalid Phone Number!"
            ? "border-[1px] border-[#EB0728]"
            : ""
            } bg-[#E5E5E5] w-full max-w-[634px] h-[45px] rounded-[30px] mt-[22px] px-3 sm:px-[18px] flex items-center placeholder-[#AAAAAA] text-[14px]`}
        />
        {errorMessage === "Phone Number is Required!" || errorMessage === "Invalid Phone Number!" ?
          <h1 className="pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left">
            {errorMessage}
          </h1> : null}
      </div>


    </div>
  )
}

export default BookingFields
