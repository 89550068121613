import React, { useEffect, useState } from 'react'
import baseUrl from '../../components/baseUrl';
import PriceCalculator from '../../components/priceCalculator'
import CustomerNav from '../../components/Nav/CustomerNav'
import Selector from '../../components/Selector/Selector';
import search from './assets/search.svg'
import Flight from '../../components/Flight/Flight';
import alex from '../../components/Flight/assets/alex.jpg'
import Footer from '../../components/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import BookFlightModal from '../../components/Flight/BookFlightModal';
import BookCompleteModal from '../../components/Flight/BookCompleteModal';
import FlightTrackModal from '../../components/Flight/FlightTrackModal'
import { FlightTrackTrue } from '../../features/flight/flightTrackSlice'
import BookedFlight from '../../components/CustomerDashboard/BookedFlight';
import FlightHistory from '../../components/CustomerDashboard/FlightHistory';
import RequestedFlight from '../../components/CustomerDashboard/RequestedFlight';
import ConfirmRequestCancelModal from '../../components/CustomerDashboard/ConfirmRequestCancel/ConfirmRequestCancelModal';
import no_flight from './assets/no_flight.svg'
import PageLoading from './PageLoading';
import ConfirmItemReceivedModal from '../../components/CustomerDashboard/ConfirmItemReceived/ConfirmItemReceivedModal';
import UnableToBookYourFlightModal from '../../components/Flight/UnableToBookYourFlight/UnableToBookYourFlightModal'
import { useLocation } from 'react-router-dom';
import { sessionTrue } from '../../features/pages/sessionSlice';
import ViewFlightHistoryModal from '../../components/CustomerDashboard/ViewFlightHistory/ViewFlightHistoryModal';


const CustomerDashboard = ({ airports, packageDirection, setPackageDirection}) => {
  const location = useLocation();
  const dispatch = useDispatch()
  const [fromSelectedAirport, setFromSelectedAirport] = useState("")
  const [toSelectedAirport, setToSelectedAirport] = useState("")

  //Fixing the search bar
  const [fixSearch, setFixSearch] = useState(false)

  const [justify, setJustify] = useState(false)

  const [userID, setUserID] = useState()
  const [username, setUsername] = useState("")
  const [access_token, setAccessToken] = useState()
  const [refresh_token, setRefreshToken] = useState()


  const switchStatus = useSelector((state) => state.switchStatus.value)


  const bookFlightModal = useSelector((state) => state.bookFlight.value)
  const unableToBookYourFlightModal = useSelector((state) => state.unableFlight.value)
  const selectedFlight = useSelector((state) => state.bookFlight.selectedFlight)
  const flightTrack = useSelector((state) => state.flightTrack.value)
  const [confirmItemReceived, setConfirmItemReceived] = useState(null)

  const [bookingComplete, setBookingComplete] = useState(false)
  const [flightData, setFlightData] = useState()
  const [bookedFlight, setBookedFlight] = useState()
  const [switchNav, setSwitchNav] = useState(0)
  const [flightToTrack, setFlightToTrack] = useState()
  const [confirmRequestCancel, setConfirmRequestCancel] = useState(null)
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [allFlight, setAllFlight] = useState()
  const [trackBooking, SetTrackBooking] = useState(null)

  const [request, setRequest] = useState();
  const [book, setBook] = useState([])
  const [history, setHistory] = useState([])
  // View History
  const [viewFlightHistory, setViewFlightHistory] = useState(null)

  /**Flight Switch */
  const [flightSwitch, setFlightSwitch] = useState(0)

  //Is marketplace
  const [isMarketPlace, setIsMarketPlace] = useState(false)


  // view track flight
  const viewFlightTrack = (flight) => {
    dispatch(FlightTrackTrue())
    setFlightToTrack(flight)
  }


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Smooth scrolling animation
    });
  };

  // Function to handle scroll event
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const screenwidth = window.innerWidth

    // Check if the first div is scrolled up 
    if (scrollPosition > 150 && screenwidth >= 840) {
      setFixSearch(true)
    }
    else if (scrollPosition > 130 && screenwidth >= 750 && screenwidth < 840) {
      setFixSearch(true)
    }
    else if (scrollPosition > 130 && screenwidth >= 640 && screenwidth < 750) {
      setFixSearch(true)
    }
    else if (scrollPosition > 90 && screenwidth >= 200 && screenwidth < 640) {
      setFixSearch(true)
    }
    else {
      setFixSearch(false)
    }
  };
  const quickSearch = () => {
    setJustify(true);
    const filteredFlight = allFlight?.filter(
      (flight) => flight.departure_airport.toLowerCase().includes(fromSelectedAirport?.toLowerCase()) && flight.arrival_airport.toLowerCase().includes(toSelectedAirport?.toLowerCase())
    );
    // Update the state based on the filtered data
    setFlightData(filteredFlight);
  };
  // Encode the data before storing it
  // function encodeData(data) {
  //   return btoa(data); // Base64 encoding
  // }

  // Decode the data after retrieving it
  function decodeData(encodedData) {
    return atob(encodedData); // Base64 decoding
  }



  // const fetchFlightData = async () => {
  //   try {
  //     const response = await axios.get(`${baseUrl}/flight/`).catch((error) => {
  //       console.log(error)
  //     })

  //     if (response?.data) {
  //       const tempData = [];
  //       await Promise.all(
  //         response.data.filter(item => item.booking_status === false).map(async (f) => {
  //           const departureResponse = await axios.get(`${baseUrl}/airport/${f.departure_airport}/`);
  //           const departureData = departureResponse.data;

  //           const arrivalResponse = await axios.get(`${baseUrl}/airport/${f.arrival_airport}/`);
  //           const arrivalData = arrivalResponse.data;

  //           // const userResponse = await axios.get(`${baseUrl}/users/${f.carrier}/`);
  //           // const userData = userResponse.data;

  //           const temp = {
  //             id: f.id,
  //             user_img: alex,
  //             airport_img: decodeURIComponent(departureData.image).slice(8),
  //             from_city_code: departureData.city_code,
  //             from_country: departureData.country,
  //             departure_airport: departureData.name,
  //             departure_code: departureData.code,
  //             to_city_code: arrivalData.city_code,
  //             to_country: arrivalData.country,
  //             arrival_airport: arrivalData.name,
  //             arrival_code: arrivalData.code,
  //             departure_date: f.departure_date,
  //             arrival_date: f.arrival_date,
  //             depart_time: f.departure_time,
  //             arrival_time: f.arrival_time,
  //             maximum_space: f.space_available,
  //             price: PriceCalculator(f.space_available).senderCost,
  //             username: f.carrier_name,
  //             carrier_id: f.carrier,
  //             booking_status: f.booking_status,
  //             terminal: f.terminal,
  //           };

  //           tempData.push(temp);
  //         })
  //       );
  //       window.localStorage.setItem('jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuykjfduybfuebyryeu', JSON.stringify(response.data))
  //       setAllFlight(tempData)
  //       setFlightData(tempData);

  //       const tempData2 = [];
  //       await Promise.all(
  //         response.data.map(async (f) => {
  //           const departureResponse = await axios.get(`${baseUrl}/airport/${f.departure_airport}/`).catch(error => console.log(error))
  //           const departureData = departureResponse.data;

  //           const arrivalResponse = await axios.get(`${baseUrl}/airport/${f.arrival_airport}/`).catch(error => console.log(error))
  //           const arrivalData = arrivalResponse.data;

  //           // const userResponse = await axios.get(`${baseUrl}/users/${f.carrier}/`);
  //           // const userData = userResponse.data;

  //           const temp2 = {
  //             id: f.id,
  //             user_img: alex,
  //             airport_img: decodeURIComponent(departureData.image).slice(8),
  //             from_city_code: departureData.city_code,
  //             from_country: departureData.country,
  //             departure_airport: departureData.name,
  //             departure_code: departureData.code,
  //             to_city_code: arrivalData.city_code,
  //             to_country: arrivalData.country,
  //             arrival_airport: arrivalData.name,
  //             arrival_code: arrivalData.code,
  //             departure_date: formatDate(f.departure_date),
  //             arrival_date: formatDate(f.arrival_date),
  //             depart_time: f.departure_time,
  //             arrival_time: f.arrival_time,
  //             maximum_space: f.space_available,
  //             price: PriceCalculator(f.space_available).senderCost,
  //             username: f.carrier_name,
  //             carrier_id: f.carrier,
  //             booking_status: f.booking_status,
  //           };

  //           tempData2.push(temp2);
  //         })
  //       );
  //       setBookedFlight(tempData2)
  //       return
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const fetchFlightData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/flight/`).catch((error) => {
        console.error(error);
        return null;
      });

      if (response?.data) {
        const airportCache = {};
        const tempAllFlights = [];
        const tempBookedFlights = [];

        for (const f of response.data) {
          const departureKey = f.departure_airport;
          const arrivalKey = f.arrival_airport;

          // Fetch and cache departure airport data if not already cached
          if (!airportCache[departureKey]) {
            const departureResponse = await axios.get(`${baseUrl}/airport/${departureKey}/`).catch(console.error);
            airportCache[departureKey] = departureResponse?.data || {};
          }
          // Fetch and cache arrival airport data if not already cached
          if (!airportCache[arrivalKey]) {
            const arrivalResponse = await axios.get(`${baseUrl}/airport/${arrivalKey}/`).catch(console.error);
            airportCache[arrivalKey] = arrivalResponse?.data || {};
          }

          // Generate flight data
          const temp = {
            id: f.id,
            user_img: alex, // Assuming 'alex' is a variable defined elsewhere
            airport_img: decodeURIComponent(airportCache[arrivalKey].image).slice(8),
            from_city: airportCache[departureKey].city,
            from_city_code: airportCache[departureKey].city_code,
            from_country: airportCache[departureKey].country,
            departure_airport: airportCache[departureKey].name,
            departure_code: airportCache[departureKey].code,
            to_city: airportCache[arrivalKey].city,
            to_city_code: airportCache[arrivalKey].city_code,
            to_country: airportCache[arrivalKey].country,
            arrival_airport: airportCache[arrivalKey].name,
            arrival_code: airportCache[arrivalKey].code,
            departure_date: f.departure_date,
            arrival_date: f.arrival_date,
            depart_time: f.departure_time,
            arrival_time: f.arrival_time,
            minimum_space: f.minimum_space,
            maximum_space: f.maximum_space,
            price: PriceCalculator(f.maximum_space).senderCost,
            username: f.carrier_name,
            carrier_id: f.carrier,
            carrier_city: f.carrier_city,
            carrier_country: f.carrier_country,
            booking_status: f.booking_status,
            terminal: f.terminal,
          };

          tempBookedFlights.push(temp);
          if (!f.booking_status) {
            tempAllFlights.push(temp);
          }
        }
        window.localStorage.setItem('jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuykjfduybfuebyryeu', JSON.stringify(response.data))
        if (tempAllFlights) {
          setAllFlight(tempAllFlights.sort());
          setFlightData(tempAllFlights.sort())
        }
        if (tempBookedFlights) {
          setBookedFlight(tempBookedFlights.sort());
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchBookingData = async (token, id) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/booking/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: {}
    };

    await axios.request(config).then((response) => {
      const theFlight = JSON.parse(window.localStorage.getItem('jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuykjfduybfuebyryeu'))
      SetTrackBooking(response?.data)
      if (theFlight) {
        const payment_successful = response?.data?.filter(item=> item?.payment_status==="Success")
        const res = payment_successful
          ?.filter(d => theFlight.some(flight => d.flight_book === flight.id && flight?.booking_status === true)).sort()
          .map(d => {
            // Find the matching flight
            const matchingFlight = theFlight.find(flight => d.flight_book === flight.id);
            // Extract the 'id' from the matchingFlight and rename it, then spread the rest of its properties
            const { id: FlightId, ...restOfMatchingFlight } = matchingFlight;
            // Return a new object that merges 'd' with the rest of the matching flight
            // and includes the renamed 'FlightId'
            return {
              ...d, // Keep the 'id' from 'd'
              FlightId, // Include the 'id' from the matching flight, but renamed
              ...restOfMatchingFlight // Spread the rest of the properties of the matching flight
            };
          });
        setRequest(res.filter(flight => flight.sender === id && flight.carrier_approval === false && flight.sender_acceptance_status === 1 && (flight.status === "Pending" || flight.status === "Declined")))
        setBook(res.filter(flight => flight.sender === id && flight.sender_approval === false && (flight.status === "Upcoming" || flight.status === "Active")))
        setHistory(res.filter(flight => flight.sender === id && (flight.sender_approval === true || flight.sender_acceptance_status == 2)))

      }
    }).catch((error) => {
      if (error?.response?.data?.detail === 'Given token not valid for any token type') {
        dispatch(sessionTrue())

      }
      console.log(error)
      // console.log(refresh_token)
    })

  }


  {/**Searching tracking */ }
  useEffect(() => {
    if ((fromSelectedAirport.length === 0 && toSelectedAirport.length > 0) || (fromSelectedAirport.length > 0 && toSelectedAirport.length === 0)) {
      quickSearch()
    }
    if (fromSelectedAirport.length === 0 && toSelectedAirport.length === 0) {
      setFlightData(allFlight)
    }
  }, [fromSelectedAirport, toSelectedAirport])



  // Attach the scroll event listener
  useEffect(() => {
    if (switchStatus) {
      scrollToTop()
    }
    else {
      scrollToTop()
    }

    fetchFlightData();

    const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
    if (some_data) {
      const current_Data = decodeData(some_data)
      const parsedData = JSON.parse(current_Data)
      setUsername(parsedData?.first_name)
      setAccessToken(parsedData?.access_token)
      setRefreshToken(parsedData?.refresh_token)
      setUserID(parsedData?.id)
      fetchBookingData(parsedData?.access_token, parsedData?.id)
    }
    else {
      window.location.href = '/'
    }
    // const api = "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"

    // axios.get(api).then((response) => {
    //   setCities(response.data)
    // }).catch(error => {
    //   console.log(error)
    // })
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [switchStatus]);


  // Booking complete tracking
  useEffect(() => {
    // Parse the query string
    const searchParams = new URLSearchParams(location.search);
    const paymentStatus = searchParams.get('payment');

    // Check if 'payment=success' is present in the URL
    if (paymentStatus === 'success') {
      setBookingComplete(true)
      // Handle the success case
    } else {
      // Handle other cases
    }
  }, [location]);


  {/**Auto Refresh */}
  useEffect(() => {
    const handleMouseOver = () => {
        let i = 1
        while( i <= 1){
        fetchFlightData();
        const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy");
        if (some_data) {
          const current_Data = decodeData(some_data);
          const parsedData = JSON.parse(current_Data);
          setUsername(parsedData?.first_name);
          setAccessToken(parsedData?.access_token);
          setRefreshToken(parsedData?.refresh_token);
          setUserID(parsedData?.id);
          fetchBookingData(parsedData?.access_token, parsedData?.id);
        }
        i+=1
      }
    };
  
    document.addEventListener('mouseover', handleMouseOver);
  
    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
    };
  }, [allFlight, bookedFlight, trackBooking]);
  



  //Reset Market Place
  useEffect(()=>{
    setIsMarketPlace(false)
  }, [])
  // if (validUser) {
  if (flightData || allFlight) {
    if (!switchStatus) {
      return (
        <div className='w-full'>
          <CustomerNav component='dashboard' userID={userID} refresh_token={refresh_token} access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} />
          {bookFlightModal ? <BookFlightModal airports={airports} username={username} userID={userID} refresh_token={refresh_token} access_token={access_token} setAccessToken={setAccessToken} flight={selectedFlight} setBookingComplete={setBookingComplete} setPackageDirection={setPackageDirection} isMarketPlace={isMarketPlace} setIsMarketPlace={setIsMarketPlace} /> : null}
          {bookingComplete ? <BookCompleteModal setBookingComplete={setBookingComplete}/> : null}
          {unableToBookYourFlightModal ? <UnableToBookYourFlightModal /> : null}
          {confirmRequestCancel ? <ConfirmRequestCancelModal access_token={access_token} request={confirmRequestCancel} setConfirmRequestCancel={setConfirmRequestCancel} /> : null}
          <div className='    md:bg-cover md:bg-left-top z-40  w-full pt-[6rem] sm:pt-[146px] px-[2rem] lg:px-[5rem] '>
            <h1 className=' text-[25px] sm:text-[30px] md:text-[3.125rem] font-bold'>Hello, {username}</h1>

            {/**Search bar */}
            <div className='w-full flex flex-col items-center justify-center mt-[31px]'>
              <div id='search' className={` ${fixSearch ? "top-[92px] fixed z-50 px-[2rem] sm:px-0" : ""} sm:pl-[10px] sm:py-[8px] sm:pr-[8px] w-full  sm:w-auto sm:h-[50px] flex flex-col sm:flex-row gap-[9px] sm:gap-0 items-center rounded-[40px] sm:border-solid sm:border-[1px] sm:border-[#C1C1C180] sm:shadow-searchBar`}>

                <div className='w-full sm:w-auto shadow-adminLogIn sm:shadow-none border-solid border-[1px] border-[#C1C1C180] rounded-[60px] px-4 sm:px-0 sm:rounded-none sm:border-none'>
                  <Selector options={airports} setCity={setFromSelectedAirport} placeholder="From:" />
                </div>

                <hr className=' hidden sm:flex w-[1px] h-[30px] bg-[#C1C1C180]'></hr>

                <div className='w-full sm:w-auto shadow-adminLogIn sm:shadow-none border-solid border-[1px] border-[#C1C1C180] rounded-[60px] px-4 sm:px-0 sm:rounded-none sm:border-none'>
                  <Selector options={airports} setCity={setToSelectedAirport} placeholder="To:" />
                </div>

                <button onClick={() => quickSearch()} className=' items-center justify-center hidden sm:flex w-[35px]  h-[35px] rounded-[40px] bg-[#EB0728]'>
                  <img src={search} alt="" className=' w-full h-full' />
                </button>

                <button onClick={() => quickSearch()} className=' w-full mt-[12px] flex sm:hidden h-[40px] bg-[#EB0728] text-white text-[16px] items-center justify-center text-center rounded-[50px]'>
                  Search Flights
                </button>
              </div>
            </div>

          </div>

          <div className={`${fixSearch ? " mt-[210px] sm:mt-[99px]" : "mt-[38px]"} w-full h-[1330px] overflow-scroll flex flex-col lg:flex-row  lg:gap-[2rem] px-[2rem] lg:px-[5rem]`}>
            {/**Flight switch */}
            <div className=' flex lg:hidden whitespace-nowrap lg:px-[5rem]  w-full justify-center  items-start'>
              <div className={` ${flightSwitch !== 1 ? "pr-[36px]" : "pl-[25px]"} w-fit h-[40px] flex gap-[34px] items-center rounded-[50px] border-solid border-[1px] border-[#C1C1C180] shadow-searchBar py-[5px] px-[6px] text-[12px] leading-[14.56px] sm:text-[16px] sm:leading-[19.41px]`}>
                <div onClick={() => setFlightSwitch(0)} className={` ${flightSwitch === 0 ? "bg-[#EB0728] text-white px-4 sm:px-0 py-[8px] sm:py-0 w-full sm:w-[180px] h-[30px] rounded-[30px] flex items-center justify-center text-center" : " sm:ml-[43px]"} font-semibold sm:font-bold cursor-pointer`}>Available flights</div>
                <div onClick={() => setFlightSwitch(1)} className={` ${flightSwitch === 1 ? "bg-[#EB0728] text-white px-4 sm:px-0 py-[8px] sm:py-0 w-full sm:w-[180px] h-[30px] rounded-[30px] flex items-center justify-center text-center" : " sm:ml-[43px]"} font-semibold sm:font-bold cursor-pointer`}>Requested flights</div>
              </div>
            </div>

            {/**Large Devices */}

            {/**Flights Container */}
            <div className='   hidden lg:flex flex-col  lg:h-full overflow-scroll lg:overflow-auto w-full lg:flex-1 xl:flex-[2]'>
              {flightData?.length > 0 ?
                <div className=' pb-10'>
                  <h1 className=' hidden lg:flex text-[16px] leading-[19.41px] font-bold'>Available Flights</h1>

                  {/**Flights */}
                  <div className={`flex flex-wrap ${justify ? "justify-center" : `justify-center ${flightData?.length % 3 === 0 ? "lg:justify-start" : "lg:justify-start"}`} mt-[16px] border-[#C1C1C180] gap-[2rem]`}>
                    {flightData?.sort()?.map(flight => {
                      return <Flight key={flight?.id} flight={flight} setIsMarketPlace={setIsMarketPlace} />
                    })
                    }
                  </div>
                </div>
                :
                <div className=' flex flex-col text-center justify-center items-center align-middle'>
                  <h1 className=' font-bold text-[18px] xs:text-[20px] xm:text-[25px]'>No available flights</h1>
                  <h1 className=' mt-[9px] text-[#707070] text-[14px] xs:text-[16px] xm:text-[18px]'>Currently no flights available. Please check back later for updates</h1>
                  <img src={no_flight} alt="" className=' mt-[20px] w-auto h-auto max-w-[333px] max-h-[415px]' />
                </div>
              }
            </div>

            {/**Request*/}
            <div className=' hidden lg:flex flex-col   lg:h-full overflow-scroll lg:overflow-auto w-full lg:flex-[1]'>
              <h1 className=' text-[16px] leading-[19.41px] font-bold'>Requested flights</h1>
              <div className='mt-[16px] py-[27px] px-[14px] w-full h-full max-h-[1287px] rounded-[50px] lg:border-solid lg:border-[1px] border-[#C1C1C180] overflow-scroll'>
                {
                  request?.length > 0 ?
                    <div className=' flex flex-col items-center justify-center gap-[31px]'>
                      {request?.map((req) => {
                        return (
                          <RequestedFlight key={req?.id} access_token={access_token} flightData={bookedFlight} flight={req} setConfirmRequestCancel={setConfirmRequestCancel} />
                        )
                      })}
                    </div> :

                    <div className=' w-full h-full text-center items-center justify-center'>
                      <h1 className=' text-[16px] leading-[19.41px] font-bold'>No Requests</h1>
                      <h1 className=' text-[14px] text-[#707070] mt-4'>There are no new requests yet! <br></br> All your requests will appear here once they are made</h1>
                    </div>
                }
              </div>
            </div>


            {/**Small and Medium Devices */}


            {flightSwitch === 0 ?
              <div className='mt-[21px] w-full  flex flex-wrap  overflow-scroll lg:hidden  lg:h-full lg:overflow-auto lg:flex-1 xl:flex-[2]'>
                {flightData?.length > 0 ?
                  <div className=' pb-10'>
                    {/**Flights */}
                    <div className={`flex flex-wrap ${justify ? "justify-center" : `justify-center ${flightData?.length % 3 === 0 ? "lg:justify-start" : "lg:justify-start"}`} border-[#C1C1C180] gap-[2rem]`}>
                      {flightData?.sort()?.map(flight => {
                        return <Flight key={flight?.id} flight={flight} setIsMarketPlace={setIsMarketPlace} />
                      })
                      }
                    </div>
                  </div>
                  :
                  <div className=' flex flex-col text-center justify-center items-center align-middle'>
                    <h1 className=' font-bold text-[18px] sm:text-[25px]'>No available flights</h1>
                    <h1 className=' mt-[9px] text-[#707070] text-[16px] sm:text-[18px]'>Currently no flights available. Please check back later for updates</h1>
                    <img src={no_flight} alt="" className=' mt-[20px] w-auto h-auto max-w-[333px] max-h-[415px]' />
                  </div>
                }
              </div> :

              <div className=' flex lg:hidden flex-col   lg:h-full overflow-scroll lg:overflow-auto w-full lg:flex-[1]'>
                <div className='mt-[21px] xm:px-[14px] w-full h-full max-h-[1287px] rounded-[50px] lg:border-solid lg:border-[1px] border-[#C1C1C180] overflow-scroll'>
                  {
                    request?.length > 0 ?
                      <div className=' flex flex-wrap items-center justify-center gap-[31px]'>
                        {request?.map((req) => {
                          return (
                            <RequestedFlight key={req?.id} access_token={access_token} flightData={bookedFlight} flight={req} setConfirmRequestCancel={setConfirmRequestCancel} />
                          )
                        })}
                      </div> :

                      <div className=' w-full h-full text-center items-center justify-center'>
                        <h1 className=' text-[16px] leading-[19.41px] font-bold'>No Requests</h1>
                        <h1 className=' text-[14px] text-[#707070] mt-4'>There are no new requests yet! <br></br> All your requests will appear here once they are made</h1>
                      </div>
                  }
                </div>
              </div>
            }
          </div>



          {/**Footer */}
          <Footer />
        </div>
      )
    }
    else {
      return (
        <>
          <div className='min-h-screen w-full'>
            <CustomerNav component='booking' userID={userID} refresh_token={refresh_token} access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} />
            {flightTrack ? <FlightTrackModal flight={flightToTrack} setConfirmItemReceived={setConfirmItemReceived} /> : null}
            {confirmItemReceived ? <ConfirmItemReceivedModal access_token={access_token} item={confirmItemReceived} setConfirmItemReceived={setConfirmItemReceived} /> : null}
            {viewFlightHistory? <ViewFlightHistoryModal viewFlightHistory={viewFlightHistory} setViewFlightHistory={setViewFlightHistory}/>:null}

            <div className=' w-full h-full pt-[159px] sm:pt-[199px]'>
              <div className=' w-full flex flex-col justify-center'>
                {/** switch */}
                <div className=' whitespace-nowrap lg:px-[5rem]  flex w-full justify-center  items-start'>
                  <div className={` ${switchNav !== 1 ? "sm:pr-[34px]" : ""} ${switchNav !== 0 ? " sm:pl-[32px]" : ""} bg-[#FFFFFF] border-solid border-[1px] border-[#C1C1C1] shadow-switchBar w-fit h-[45px] sm:h-[65px] rounded-[100px] p-[10px] flex gap-3 sm:gap-[37px] items-center`}>
                    {/* <div onClick={() => setSwitchNav(0)} className={` ${switchNav === 0 ? "bg-[#EB0728] px-4 sm:px-0 py-[8px] sm:py-0 w-full sm:w-[180px]  sm:h-[45px] rounded-[30px] text-white flex items-center justify-center text-center" : ""} text-xs xm:text-[14px] sm:text-[18px] font-semibold sm:font-bold cursor-pointer`}>REQUEST ( {request?.length} )</div> */}
                    <div onClick={() => setSwitchNav(0)} className={` ${switchNav === 0 ? "bg-[#EB0728] px-4 sm:px-0 py-[8px] sm:py-0 w-full sm:w-[180px]  sm:h-[45px] rounded-[30px] text-white flex items-center justify-center text-center" : " sm:ml-[43px]"} text-xs xm:text-[14px] sm:text-[18px] font-semibold sm:font-bold cursor-pointer`}>BOOKED ( {book?.length} )</div>
                    <div onClick={() => setSwitchNav(1)} className={` ${switchNav === 1 ? "bg-[#EB0728] px-4 sm:px-0 py-[8px] sm:py-0 w-full sm:w-[180px]  sm:h-[45px] rounded-[30px] text-white flex items-center justify-center text-center" : " sm:ml-[43px]"} text-xs xm:text-[14px] sm:text-[18px] font-semibold sm:font-bold cursor-pointer`}>HISTORY ( {history?.length} )</div>
                  </div>
                </div>

                {
                  //book
                  switchNav === 0 ?
                    book?.length > 0 ?
                      <div className=' pb-[74px] pt-[29px] flex flex-wrap gap-[33px] justify-center px-3'>
                        {book?.map((b) => {
                          return (
                            <BookedFlight airports={airports} flight={b} viewFlightTrack={viewFlightTrack} />
                          )
                        })}

                      </div> :
                      <div className=' w-full h-full text-center items-center justify-center pt-[158px]'>
                        <h1 className=' text-[25px] font-bold'>No Accepted Requests</h1>
                        <h1 className=' text-[18px] text-[#707070] mt-4'>There are currently no accepted requests. Check back later to view your accepted requests</h1>
                      </div>
                    :
                    //history
                    history?.length > 0 ?
                      <div className=' pb-[74px] w-full h-full flex flex-wrap items-center justify-center  pt-[48px] gap-[33px] px-3'>
                        {history?.map((h) => {
                          return (
                            <FlightHistory airports={airports} flight={h} setViewFlightHistory={setViewFlightHistory} />
                          )
                        })}
                      </div> :
                      <div className=' w-full h-full text-center items-center justify-center pt-[158px]'>
                        <h1 className=' text-[25px] font-bold'>No Completed Requests</h1>
                        <h1 className=' text-[18px] text-[#707070] mt-4'>Your history page is empty. Your completed requests will appear here</h1>
                      </div>
                }
              </div>
            </div>
          </div>
          {/**Footer */}
          <Footer />
        </>
      )
    }
  }
  else {
    return (
      <div className=' w-full h-full'>
        <PageLoading dashboard="customer" userID={userID} refresh_token={refresh_token} access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} />
      </div>
    )
  }
}

export default CustomerDashboard
